import axios from 'axios';
import {ApiResponse} from "../../interface/api-response";
import {IUserMetrics} from "./model/IUserMetrics";

export class UserMetricsApi {

    static async getMetrics(): Promise<ApiResponse<IUserMetrics>> {
        const response = await axios.get<ApiResponse<IUserMetrics>>('/metrics');

        return response.data;
    }

}
