import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import inboxClientService from "../../../service/inbox/inbox-client.service";
import { IWebMessage } from "../../../service/websocket/model/web-message.interface";
import SessionService from "../../../service/session/session.service";
import { WebsocketService } from "../../../service/websocket/websocket.service";

interface HasMessagesContextType {
    hasNewMessages: boolean;
    fetchHasNewMessages: () => void;
}

const HasMessagesContext = createContext<HasMessagesContextType | undefined>(undefined);

export const HasMessagesContextProvider = ({ children }: { children: ReactNode }) => {
    const [hasNewMessages, setHasNewMessages] = useState<boolean>(false);

    const fetchHasNewMessages = useCallback(async () => {
        if (!SessionService.isAuthenticated())
            return;

            try {
                console.log('yeet')
            const result = await inboxClientService.hasNewMessages();
            setHasNewMessages(result);
        } catch (error) {
            console.error('Error fetching new messages:', error);
        }
    }, []);

    const onMessageCallback = useCallback((webMessage: IWebMessage) => {
        setHasNewMessages(true);
    }, []);

    useEffect(() => {
        fetchHasNewMessages();
    }, []);

    useEffect(() => {
        if (SessionService.isAuthorized()) {
            WebsocketService.subscribe(onMessageCallback);
        }

        return () => WebsocketService.unsubscribe(onMessageCallback);
    }, [onMessageCallback]);

    return (
        <HasMessagesContext.Provider value={{ hasNewMessages, fetchHasNewMessages }}>
            {children}
        </HasMessagesContext.Provider>
    );
};

export default HasMessagesContext;
