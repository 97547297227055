import React, { useState } from 'react'
import InputSlider from '../../../../theme/component/slider/input-slider.component'


export default function DevInputSlider() {

    const [value, setValue] = useState<number>(0)

    return (
        <section id="placeholder" className="mt2">
            <InputSlider min={0} max={100} initialValue={value} onChange={setValue}  label='Test Slider'/>
            <div>{value}</div>
        </section>
    )
}