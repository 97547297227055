import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SessionService from '../../../../service/session/session.service'
import RequireAuthentication from '../require-authentication/require-authentication.component'
import VerificationError from '../error/verification/verification-error.component'

type RequireAuthProps = {
    children: any
}

export default function RequireAuthorization({ children }: RequireAuthProps) {
    const navigate = useNavigate()

    const [authorized, setAuthorized] = useState<boolean>(false)

    useEffect(() => {
        function requiresAuthorization() {
            if (SessionService.isAuthorized()) {
                setAuthorized(true)
            }
        }

        requiresAuthorization()
    }, [navigate])

    if (authorized) {
        return children
    } else {
        return (
            <RequireAuthentication>
                <VerificationError />
            </RequireAuthentication>
        )
    }
}