// index.tsx
import React, { useState } from 'react';
import styles from './index.module.scss';
import Panel from '../../../theme/component/panel/panel.component';
import { useTranslation } from 'react-i18next';

const Guide: React.FC = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const sections = [
        { title: t('payments.header'), content: t('payments.content') },
        {
            title: t('profilePictureBanner.header'),
            content: t('profilePictureBanner.content')
        },
        {
            title: t('settings.header'),
            content: (
                <ul>
                    <li>{t('settings.changePassword')}</li>
                    <li>{t('settings.toggleEmailNotifications')}</li>
                    <li>{t('settings.customizeContact')}</li>
                    <li>{t('settings.manageAccess')}</li>
                    <li>{t('settings.closeAccount')}</li>
                </ul>
            )
        },
        {
            title: t('keywords.header'),
            content: t('keywords.content')
        },
        {
            title: t('notifications.header'),
            content: t('notifications.content')
        },
        {
            title: t('marketplace.header'),
            content: (
                <>
                    <p>{t('marketplace.paragraph')}</p>
                    <ul>
                        <li>{t('marketplace.specialKeywords')}</li>
                        <li>{t('marketplace.location')}</li>
                        <li>{t('marketplace.collaborationType')}</li>
                        <li>{t('marketplace.offers')}</li>
                    </ul>
                </>
            )
        },
        {
            title: t('explorePage.header'),
            content: t('explorePage.content')
        },
        {
            title: t('profileType.header'),
            content: t('profileType.content')
        },
        {
            title: t('boosts.header'),
            content: (
                <>
                    <p>{t('boosts.paragraph')}</p>
                    <ul>
                        <li>{t('boosts.profileVisibility')}</li>
                        <li>{t('boosts.offerVisibility')}</li>
                        <li>{t('boosts.marketplaceVisibility')}</li>
                        <li>{t('boosts.messagePrioritization')}</li>
                    </ul>
                </>
            )
        },
        {
            title: t('changePassword.header'),
            content: t('changePassword.content')
        },
        {
            title: t('contactUs.header'),
            content: t('contactUs.content')
        },
        {
            title: t('influspacePremium.header'),
            content: (
                <>
                    <p>{t('influspacePremium.paragraph')}</p>
                    <ul>
                        <li>{t('influspacePremium.seeOffersEarlier')}</li>
                        <li>{t('influspacePremium.viewOffers')}</li>
                        <li>{t('influspacePremium.profileBadge')}</li>
                        <li>{t('influspacePremium.profileRanking')}</li>
                        <li>{t('influspacePremium.ownAdvertisements')}</li>
                        <li>{t('influspacePremium.messagePrioritization')}</li>
                    </ul>
                </>
            )
        },
        {
            title: t('collaborationTypes.header'),
            content: (
                <>
                    <h3>{t('collaborationTypes.freePlacement.header')}</h3>
                    <p>{t('collaborationTypes.freePlacement.content')}</p>
                    <br />
                    <h3>{t('collaborationTypes.paidPromotions.header')}</h3>
                    <p>{t('collaborationTypes.paidPromotions.content')}</p>
                    <br />
                    <h3>{t('collaborationTypes.influencerCollaborations.header')}</h3>
                    <p>{t('collaborationTypes.influencerCollaborations.content')}</p>
                </>
            )
        },
        {
            title: t('location.header'),
            content: t('location.content')
        },
        {
            title: t('campaignOfferQuickGuide.header'),
            content: (
                <>
                    <p>{t('campaignOfferQuickGuide.paragraph')}</p>
                    <br />
                    <h3>{t('campaignOfferQuickGuide.instagram.header')}</h3>
                    <ul>
                        <li>{t('campaignOfferQuickGuide.instagram.fotoPost')}</li>
                        <li>{t('campaignOfferQuickGuide.instagram.fotoCollection')}</li>
                        <li>{t('campaignOfferQuickGuide.instagram.reel')}</li>
                        <li>{t('campaignOfferQuickGuide.instagram.storyPost')}</li>
                    </ul>
                    <br />
                    <h3>{t('campaignOfferQuickGuide.tikTok.header')}</h3>
                    <ul>
                        <li>{t('campaignOfferQuickGuide.tikTok.video')}</li>
                        <li>{t('campaignOfferQuickGuide.tikTok.fotoCollection')}</li>
                        <li>{t('campaignOfferQuickGuide.tikTok.story')}</li>
                    </ul>
                    <br />
                    <h3>{t('campaignOfferQuickGuide.snapchat.header')}</h3>
                    <ul>
                        <li>{t('campaignOfferQuickGuide.snapchat.storys')}</li>
                        <li>{t('campaignOfferQuickGuide.snapchat.spotlights')}</li>
                    </ul>
                    <br />
                    <h3>{t('campaignOfferQuickGuide.twitch.header')}</h3>
                    <ul>
                        <li>{t('campaignOfferQuickGuide.twitch.background')}</li>
                        <li>{t('campaignOfferQuickGuide.twitch.permanentBanner')}</li>
                        <li>{t('campaignOfferQuickGuide.twitch.activePlacement')}</li>
                        <li>{t('campaignOfferQuickGuide.twitch.eventSponsors')}</li>
                        <li>{t('campaignOfferQuickGuide.twitch.preAfterStreamAds')}</li>
                    </ul>
                    <br />
                    <h3>{t('campaignOfferQuickGuide.youtube.header')}</h3>
                    <ul>
                        <li>{t('campaignOfferQuickGuide.youtube.video')}</li>
                        <li>{t('campaignOfferQuickGuide.youtube.reelStory')}</li>
                    </ul>
                    <br />
                    <h3>{t('campaignOfferQuickGuide.x.header')}</h3>
                    <ul>
                        <li>{t('campaignOfferQuickGuide.x.mainlyPosts')}</li>
                    </ul>
                    <p>{t('campaignOfferQuickGuide.additionalInformation')}</p>
                </>
            )
        },
    ];

    return (
        <section>
            <Panel overridePadding="px5 py3">
                <h1>{t('influspaceFAQ')}</h1>
                <br />
                {sections.map((section, index) => (
                    <div key={index} className={styles.section}>
                        <h2
                            className={styles.header}
                            onClick={() => toggleAccordion(index)}
                        >
                            {section.title}
                        </h2>
                        <div className={activeIndex === index ? styles.contentOpen : styles.contentClosed}>
                            <div className={styles.contentInner}>
                                {typeof section.content === 'string' ? <p>{section.content}</p> : section.content}
                            </div>
                        </div>
                        <br />
                    </div>
                ))}
            </Panel>
        </section>
    );
};

export default Guide;
