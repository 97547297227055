import React, {useEffect} from 'react'
import ChatDetails from '../../../../../../service/inbox/model/ChatDetails'
import Avatar from './component/avatar'

import style from './index.module.scss'
import {PremiumBadge} from "../../../../../../theme/component/premium-badge";

type ConversationProps = {
    chat: ChatDetails
    selected: boolean
    onSelect: (chat: ChatDetails) => void
    disabled?: boolean
}

export default function Conversation({ chat, selected, onSelect, disabled }: ConversationProps) {
    const selectedStyle = selected ? style.selected : ''
    const disabledStyle = disabled ? style.disabled : ''

    useEffect(() => {
        console.log('ja updated')
        console.log(chat)
    }, [chat]);

    function onSelectHandler(chat: ChatDetails) {
        if (disabled)
            return

        onSelect(chat)
    }

    return (
        <div className={`${style.panel} ${selectedStyle} ${disabledStyle}`} onClick={() => onSelectHandler(chat)}>
            <Avatar avatarUrl={chat.chatter.avatar} username={chat.chat} unread={chat.unread} />
            <span className={style.details}>
                <div className={style.displayname} >{chat.chatter.displayname}<PremiumBadge premium={chat.chatter.premium}/> </div>
                <div className={style.latest_message}>{chat.latestMessage?.content}</div>
            </span>
        </div>
    )
}