import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import useQuery from '../../theme/hooks/use-query.component'
import TechnicalError from "../../components/app/component/error/technical/technical-error.component";
import Loading from "../../theme/layout/loading/loading.component";
import {ThirdPartyAuthorizationService} from "../../service/third-party-authorization/third-party-authorization.service";
import sessionService from "../../service/session/session.service";
import {AccountService} from "../../service/account/account.service";
import {sign} from "crypto";

type LoginProps = {
    onLogin: (loggedIn: boolean) => void
}

export default function AccountVerification({onLogin}: LoginProps) {
    const {token} = useParams();

    const abortController = new AbortController()
    const signal = abortController.signal

    console.log(token)

    const navigate = useNavigate()

    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        async function redirect() {

            const accountVerified = await AccountService.verify(token, signal)


            if (accountVerified === false) {
                setError(true)
                return
            }

            await sessionService.validateSession()

            if (sessionService.getSessionDetails().authenticated) {
                onLogin(true)
                navigate(`/explore`)
                return
            }

            navigate(`/login`)
        }

        redirect()
    }, [])


    if (error)
        return <TechnicalError/>
    else
        return <Loading/>
}
