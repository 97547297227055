import React, { useState } from 'react'
import Platform from '../../../../../../service/network/model/Platform'
import networkService from '../../../../../../service/network/network.service'
import InputButton from '../../../../../../theme/component/input-button'
import InputDropdown from '../../../../../../theme/component/input-dropdown/input-dropdown.component'
import OptionModel from '../../../../../../theme/component/input-dropdown/models/dropdown-option.model'

import style from './follower-count.module.scss'
import {InstagramConnectTutorial} from "../instagram-tutorial";
import Network from "../../models/network.model";

type FollowerCountProps = {
    totalFollower: string
    editingMode: boolean
    networks: Network[]
}

export default function FollowerCount({ editingMode, totalFollower, networks }: FollowerCountProps) {
    const [selectedNetwork, setSelectedNetwork] = useState<OptionModel>()
    const [disableConnect, setDisableConnect] = useState<boolean>(false)
    const [openInstagramTutorial, setOpenInstagramTutorial] = useState<boolean>(false)

    const options: OptionModel[] = [
        { label: 'Facebook', value: 'facebook', icon: 'facebook' },
        { label: 'Instagram', value: 'instagram', icon: 'instagram' },
        { label: 'Tiktok', value: 'tiktok', icon: 'tiktok' },
        { label: 'Twitch', value: 'twitch', icon: 'twitch' },
        { label: 'X (Twitter)', value: 'twitter', icon: 'twitter' },
        { label: 'YouTube', value: 'youtube', icon: 'youtube' }
    ]

    async function handleConnect() {
        if (selectedNetwork === undefined) {
            setDisableConnect(true)
            return
        }
        if (!openInstagramTutorial && networks.findIndex(network => network.platform === Platform.Instagram) === -1 && selectedNetwork.value === Platform.Instagram) {
            setOpenInstagramTutorial(true)
            return
        } else if (openInstagramTutorial) {
            setOpenInstagramTutorial(false)
        }

        try {
            window.location.href = await networkService.connect(selectedNetwork.value as Platform)
        } catch (error) {
            console.log(error)
        }
    }

    function handleSelect(selectedNetwork: OptionModel) {
        if (disableConnect)
            setDisableConnect(false)

        setSelectedNetwork(selectedNetwork)
    }

    if (editingMode)
        return (
            <span className={`${style.connectSelector} mt2`}>
                <InstagramConnectTutorial open={openInstagramTutorial} onContinue={handleConnect} onClose={() => setOpenInstagramTutorial(false)}/>
                <span className={style.networkDropdown}>
                    <InputDropdown placeholder='Select Network' options={options} onChange={handleSelect}  />
                </span>
                <span className={`${style.connectButton} ${style[selectedNetwork?.value]}`}>
                    <InputButton label='Connect' fill={true} onClick={handleConnect} disabled={disableConnect} />
                </span>
            </span>
        )

    return <span className={style.total}>{totalFollower} People</span>
}
