import React, { useState } from 'react'
import { INetwork } from '../../../../../../service/network/model/INetworkDetails'
import networkService from '../../../../../../service/network/network.service'
import numberFormatService from '../../../../../../service/number-format.service'
import InputButton from '../../../../../../theme/component/input-button'
import { ButtonColor } from '../../../../../../theme/component/input-button/models/button-color.model'
import Overlay from '../../../../../../theme/component/overlay/overlay.component'

import style from './network-item.module.scss'

type NetworkItemProps = {
    network: INetwork
    editingMode: boolean
    onDisconnect?: (ref: string) => void
}

export default function NetworkItem({ network, editingMode, onDisconnect }: NetworkItemProps) {
    const [open, setOpen] = useState<boolean>(false)
    const follower = numberFormatService.renderNumber(network?.follower)

    async function disconnect(network: INetwork) {
        setOpen(false)
        const disconnected = await networkService.disconnect(network.ref)

        if (disconnected === false)
            return

        if (onDisconnect !== undefined)
            onDisconnect(network.ref)
    }

    if (editingMode)
        return (
            <>
                <span className={style.item} onClick={() => setOpen(true)}>
                    <span className={`${style.network} ${style[network?.platform]}`}>{follower}</span>
                    <span className={style.link}>Disconnect</span>
                </span> 
                <Overlay title='Disconnect' open={open} onClose={() => setOpen(false)}>
                    <div className='mt2 mb2'>
                        <span className={`${style.account} ml0`}>
                            <NetworkItem network={network} editingMode={false} />
                        </span>
                    </div>
                    <p className='mt1 mb2'>You are about to disconnect your&thinsp;
                        <span className={style.highlighted}>{network.platform}</span>
                        &thinsp;account&thinsp;
                        <span className={style.highlighted}>{network.username}</span>
                        &thinsp;with&thinsp;
                        <span className={style.highlighted}>{follower}</span>
                        &thinsp;follower from your Influspace profile.
                    </p>
                    <span className='mt2'><InputButton label='Cancel' onClick={() => setOpen(false)} /></span>
                    <span className='mt2 float-right'><InputButton color={ButtonColor.Failure} label='Disconnect' onClick={() => disconnect(network)}/></span>
                </Overlay>
            </>
        )

    return (
        <a target='_blank' href={network?.url} className={style.item}>
            <span className={`${style.network} ${style[network?.platform]}`}>{numberFormatService.renderNumber(network?.follower)}</span>
            <span className={`${style.link} ml2`}>Link</span>
        </a>
    )
}
