import React from 'react';
import NavigationButton from './component/navigation-button';
import PageNumbers from './component/page-numbers';
import style from './paging.module.scss';


interface PagingProps {
    currentPage: number;
    onPageChange: (page: number) => void;
    totalPages: number;
}

const Paging: React.FC<PagingProps> = ({ currentPage, totalPages, onPageChange }) => {
    if(totalPages == 0) {
        return <></>;
    }
    return (
        <nav aria-label="Seiten-Navigation" className={style.pagingContainer}>
            <NavigationButton label="First" onClick={() => onPageChange(1)} disabled={currentPage === 1} />
            <NavigationButton label="Previous" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            <PageNumbers currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
            <NavigationButton label="Next" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <NavigationButton label="Last" onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
        </nav>
    );
};

export default Paging;
