import React, { useState } from 'react'
import InputButton from '../../../../theme/component/input-button'
import { ButtonColor } from '../../../../theme/component/input-button/models/button-color.model'

import style from './dev-input-button.module.scss'

export default function DevInputButton() {
    const [color, setColor] = useState<ButtonColor>(ButtonColor.Primary)

    return (
        <section id="placeholder" className="mt2">
            <div className={style.selector}>
                <span className={style.primary} onClick={() => setColor(ButtonColor.Primary)} />
                <span className={style.success} onClick={() => setColor(ButtonColor.Success)} />
                <span className={style.warning} onClick={() => setColor(ButtonColor.Warning)} />
                <span className={style.failure} onClick={() => setColor(ButtonColor.Failure)} />
                <span className={style.neutral} onClick={() => setColor(ButtonColor.Neutral)} />
            </div>
            <h1 className='protected'>Test input-button component</h1>
            <br />
            <InputButton label="Connect" onClick={() => alert('Clicked')} color={color} />
            <br />
            <br />
            <h3 className='protected'>Button disabled</h3>
            <br />
            <InputButton label="Connect" disabled={true} color={color} />
            <br />
            <br />
            <h3 className='protected'>Button outlined</h3>
            <br />
            <InputButton label="Connect" outline={true} color={color} />            
            <br />
            <br />
            <h3 className='protected'>Button outlined disabled</h3>
            <br />
            <InputButton label="Connect" outline={true} disabled={true} color={color} />
        </section>
    )
}