import React, {useEffect, useRef, useState} from "react"
import {IKeyword} from "../model/profile-keyword.model"
import style from "./keyword-textarea.module.scss"
import KeywordHints from "./keyword-hints.component"
import ProfileService from '../../../../../../../service/profile/profile.service'
import MarketplaceService from "../../../../../../../service/marketplace/marketplace.service";

type KeywordTextArea = {
    children: JSX.Element[]
    onChange: (keyword: IKeyword) => void
    max: number
    maxKeywordLength?: number
    error?: string
    hintService: typeof MarketplaceService | typeof ProfileService
}

let hintClicked = false

export function KeywordTextArea({children, onChange, max, maxKeywordLength = 20, error, hintService}: KeywordTextArea) {


    const [width, setWidth] = useState<string>('10ch');
    const [content, setContent] = useState<string>('');

    const [errorMessage, setErrorMessage] = useState<string>(error)

    const [keywordHints, setKeywordHints] = useState<string[]>([])
    const [openKeywordHints, setOpenKeywordHints] = useState<boolean>(false)
    const [selectedHintIndex, setSelectedHintIndex] = useState<number>(-1);



    const inputRef = useRef<HTMLInputElement>()

    useEffect(() => {
        setWidth(`${content.length + 2}ch`)

    }, [content]);

    useEffect(() => {
        setErrorMessage(error)
    }, [error]);

    async function onChangeHandler(event: any) {
        const input = event.target.value;

        if (!validateInput(input))
            return

        if (input[input.length - 1] === ',') {
            if(content?.length < 3) {
                setErrorMessage('Keyword must be at least 3 characters long')
            } else {
                setErrorMessage(undefined)
                createKeyword()
            }
        } else {
            setContent(input)
        }

        setKeywordHints(input.length > 0 ? await hintService.getKeywordHints(input) : [])

    }

    function createKeyword() {
        if (content?.length < 3)
            return

        setKeywordHints([])
        onChange({
            word: content
        } as IKeyword)
        setContent('')
    }

    function validateInput(input: string) {
        if (!/^[a-zA-Z0-9,]+$/.test(input) && input !== '') {
            setErrorMessage('keywords must be alphanumeric')
            return false
        }

        if (input.startsWith(' ') || input.startsWith(','))
            return false
        if (input[input.length - 1] !== ',' && children?.length == max)
            return false
        return input.length <= maxKeywordLength;

    }


    function onKeyDownHandler(event: any) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedHintIndex((prevIndex) =>
                prevIndex < keywordHints.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedHintIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        } else if (event.key === 'Enter') {
            setKeywordHints([])
            const word = selectedHintIndex !== -1 ? keywordHints[selectedHintIndex] : content

            if (content.length > 1 && validateInput(word)) {
                createHintKeyword(word)
            }
        }
    }

    function onBlurHandler(event: any) {
        setTimeout(() => {
            if (!hintClicked) {
                createKeyword()
            }
            hintClicked = false
        }, 200)
    }

    function onClickHandler(event: any) {
        if (event.target.tagName === 'SPAN') {
            const input = inputRef.current as HTMLInputElement
            input?.focus()
        }
    }

    function createHintKeyword(hint: string) {
        hintClicked = true
        onChange({word: hint} as IKeyword);
        setContent('');
        setErrorMessage(undefined);
        setKeywordHints([]);
        setOpenKeywordHints(false); // Schließt die Hinweise, sobald einer ausgewählt wurde
    }


    function ErrorMessage({errorMessage}: { errorMessage?: string }) {
        if (errorMessage === undefined || errorMessage === '')
            return <></>

        return <span className={style.errorMessage}>{errorMessage}</span>
    }


    return (
        <>
            <span className={`${style.input_box} l-12 m-12`} onClick={onClickHandler}>
                {children}
                <span>
                    <input className={style.keyword_input} onBlur={onBlurHandler}
                           onFocus={() => setOpenKeywordHints(true)} type="text" style={{width}}
                           onChange={onChangeHandler} onKeyDown={onKeyDownHandler} value={content} maxLength={40}
                           ref={inputRef}/>
                    <KeywordHints selectedIndex={selectedHintIndex} open={openKeywordHints} hints={keywordHints}
                                  onClick={createHintKeyword}/>
                </span>
                
            </span>
            <ErrorMessage errorMessage={errorMessage}/>
            <div className={style.info}>Put a comma after each tag</div>
            <div className={style.info}>Maximum 10 keywords</div>

        </>

    )
}