import React from 'react'
import ChatDetails from '../../../../service/inbox/model/ChatDetails'
import { ChatStatus } from '../../../../influspace/chat/model/chat-status.model'
import Conversation from '../../../../influspace/inbox/component/conversation/conversation.component'

export default function DevConversation() {

    const chat: ChatDetails = {

        chatter: {
            avatar: "/api/image?ref=21d81d2e-b34e-4b66-be54-66648df7fdd0",
            displayname: "Kevin Peniskopf",
            banner: "string",
            username: "string",
            premium: false
        },
        ref: 'ref123',
        chat: "string",

        status: ChatStatus.Accepted,

        messages: 5,
        unread: 1,

        latestMessage: {
            content: 'yeet',
            date: new Date()
        },

        blocked: false,
        deleted: false,
        archived: false,
        rejected: false
    }

    return (
        <section>
            <h1>Chatbox Demo</h1>
            <Conversation chat={chat} onArchive={() => {}} onBlock={() => {}} onDelete={() => {}}/>
        </section>
    )
}
