import React, { useEffect, useState } from 'react'
import InputCheckbox from '../../../../../theme/component/input-checkbox/input-checkbox.component'

import style from './collaboration-option.module.scss'

type CollaborationOptionProps = {
    collaborationOption: string
    editMode: boolean
    label: string
    isEnabled: boolean
    onChange: (enabled: boolean) => void
}

export default function CollaborationOption({ collaborationOption, editMode, label, isEnabled, onChange }: CollaborationOptionProps) {
    const [enabled, setEnabled] = useState<boolean>()

    useEffect(() => {
        setEnabled(isEnabled)
    }, [label])

    function changeHandler(enabled: boolean) {
        setEnabled(enabled)
        onChange(enabled)
    }

    if (editMode)
        return <div className={style.setting}><InputCheckbox label={label} onChange={changeHandler} checked={enabled} /></div>
    
    if (enabled)
        return <div className={`${style.label} ${style[collaborationOption]}`}>{label}</div>
    else
        return <></>
}
