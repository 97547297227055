import React, {useState} from "react";
import SwitchFilterOption from "./model/switch-filter-options";
import style from "./index.module.scss"


type InputSwitchProps = {
    option1: SwitchFilterOption,
    option2: SwitchFilterOption
    onSwitch: (option: SwitchFilterOption) => void
}
export function InputSwitch({option1, option2, onSwitch}: InputSwitchProps) {
    const [selectedOption, setSelectedOption] = useState<SwitchFilterOption>(option1)

    function onClick() {
        const newOption = option1.value === selectedOption.value ? option2 : option1
        setSelectedOption(newOption)
        onSwitch(newOption)
    }

    function getStyle(option: SwitchFilterOption) {
        const isSelected = selectedOption.value === option.value;
        return {
            background: isSelected ? option.background : '',
            color: isSelected ? '#fff' : '' // Textfarbe weiß, wenn ausgewählt
        };
    }

    return <span className={style.container} onClick={onClick}>
        <span className={style.option} style={getStyle(option1)}>{option1.label}</span>
        <span className={style.option} style={getStyle(option2)}>{option2.label}</span>
    </span>
}