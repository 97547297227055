import React from 'react'
import Panel from '../../../theme/component/panel/panel.component'
import style from './legal.module.scss'

export default function Disclaimer() {
    return (
        <section>
            <Panel className={` ${style.panel}`}>
                <h1>Disclaimer</h1>
                <br />
                <h2>Introduction</h2>
                <h2>Legal stuff..</h2>
            </Panel>
        </section>
    )
}
