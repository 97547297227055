import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {AccountService} from '../../service/account/account.service'

export default function Signout() {
    const navigate = useNavigate()

    useEffect(() => {
        async function signout() {
            await AccountService.signOut()
            navigate('/')
        }

        signout()
    })

    return (<></>)
}