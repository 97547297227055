import React, {useEffect, useState} from 'react'

import style from './input-text-area.module.scss'
import {Resize} from './model/resize.model'

type InputTextAreaProps = {
    placeholder?: string
    value?: string // deprecated
    onChange?: (value?: string) => void
    onFocus?: () => void
    onBlur?: () => void
    onEnter?: () => void
    disabled?: boolean
    error?: string
    label?: string
    pattern?: Pattern
    validInput?: (valid: boolean) => void
    info?: string
    resize?: Resize
    maxLength?: number
    initialValue?: string,
    rows?: number,
    className?: string
    chat?: boolean
}

export default function InputTextArea({
                                          placeholder,
                                          onFocus,
                                          onChange,
                                          onBlur,
                                          onEnter,
                                          disabled,
                                          error,
                                          label,
                                          pattern,
                                          validInput,
                                          info,
                                          resize = Resize.None,
                                          maxLength,
                                          initialValue = '',
                                          rows = 4,
                                          className = '',
                                          chat = false
                                      }: InputTextAreaProps) {

    const [value, setValue] = useState<string>(initialValue)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [minHeight, setMinHeight] = useState<string>('auto')
    const [height, setHeight] = useState<string>('auto')

    const errorStyle = errorMessage ? style.error : ''

    function onChangeHandler(event: any) {
        const input = event?.target?.value

        // resizeOnInput(event) // deprecated

        if (pattern !== undefined &&
            pattern?.allowedCharacters !== undefined &&
            pattern.allowedCharacters.test(input) === false) {
            setValue(value)
            return
        }

        setValue(input)

        if (onChange !== undefined)
            onChange(input)
    }

    function resizeOnInput(event: any) {
        console.log(event)
        const scrollHeight = event?.target?.scrollHeight

        if (minHeight === 'auto')
            setMinHeight(scrollHeight)

        setHeight(scrollHeight)
    }

    function onKeyDownHandler(event: any, onEnter?: Function) {
        if (event?.key === 'Escape')
            event?.target?.blur()

        if (event?.key === 'Enter' && !event?.shiftKey && onEnter !== undefined) {
            event?.preventDefault()
            validation()
            onEnter()
        }
    }

    function validation() {
        const validationResult = validateInput()

        if (validInput !== undefined)
            validInput(validationResult)
    }

    function validateInput(): boolean {
        if (pattern !== undefined &&
            pattern.match !== undefined &&
            pattern.match.test(value) === false) {
            setErrorMessage(pattern.error)
            return false
        }

        setErrorMessage('')
        return true
    }

    function onBlurHandler() {
        validation()

        if (onBlur !== undefined)
            onBlur()
    }

    useEffect(() => {
        setErrorMessage(error)
        setValue(initialValue)
    }, [initialValue, error])

    const defaultStyle = chat ? '' : style.default

    return (
        <span className={`${style.container} ${errorStyle} ${className}`}>
          <Label label={label}/>
          <span className={style.inputContainer}>
            <textarea className={`${style.input} ${defaultStyle}`} style={{resize, height, minHeight}}
                      rows={rows}
                      value={value}
                      placeholder={placeholder}
                      onChange={onChangeHandler}
                      onFocus={onFocus}
                      onBlur={onBlurHandler}
                      onKeyDown={event => onKeyDownHandler(event, onEnter)}
                      maxLength={maxLength}
                      disabled={disabled}/>
            <Info info={info}/>
          </span>
          <ErrorMessage errorMessage={errorMessage}/>
        </span>
    )
}

function Label({label}: { label: string }) {
    if (label === undefined || label === '')
        return <></>

    return <span className={style.label}>{label}</span>
}

function ErrorMessage({errorMessage}: { errorMessage?: string }) {
    if (errorMessage === undefined || errorMessage === '')
        return <></>

    return <span className={style.errorMessage}>{errorMessage}</span>
}

function Info({info}: { info?: string }) {
    const [showInfo, setShowInfo] = useState<boolean>(false)

    if (info)
        return (
            <>
                <span className={style.infoIcon} onClick={() => setShowInfo(!showInfo)}/>
                <InfoMessage showInfo={showInfo} info={info}/>
            </>
        )

    return <></>
}

function InfoMessage({showInfo, info}: { showInfo: boolean, info?: string }) {
    if (info === undefined || info === '')
        return <></>

    return <span className={style.infoMessage}>{showInfo ? info : ''}</span>
}