import React from "react";
import { AccountChoice } from "../../../../../../service/network/model/AccountChoice";
import style from "./index.module.scss";

type InstagramAccountComponentProps = {
    instagramAccount: AccountChoice
    onClick: (instagramAccount: AccountChoice) => void
}

export function InstagramAccountComponent({ instagramAccount, onClick }: InstagramAccountComponentProps) {
    return <div className={style.container} onClick={() => onClick(instagramAccount)}>
        <div className={style.left}>
            <img
                src={instagramAccount.pictureUrl}
                alt={`${instagramAccount.name}'s profile`}
                className={style.profilePicture}
            />
            <span>{instagramAccount.name}</span>
        </div>
        <span className={style.followerCount}>{instagramAccount.followerCount}</span>
    </div>
}