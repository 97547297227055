import React from 'react'
import Panel from '../../../theme/component/panel/panel.component'
import style from './legal.module.scss'

export default function Terms() {
    return (
        <section>
            <Panel className={` ${style.panel}`}>
                <h1>Terms of Service</h1>
                <h2>Allgemeine Geschäftsbedingungen für Influspace</h2>
                <p>Stand: 01.01.2024</p>
                <br/><br/>
                <h2>1. Geltungsbereich</h2>
                <p>Diese AGB gelten für alle Dienste und Funktionen, die auf der Plattform Influspace angeboten
                    werden.

                    <br/><br/></p>
                <h2>2. Dienstleistungen</h2>
                <p>Die Plattform ermöglicht es Nutzern, sich zu vernetzen, Angebote und Anfragen auf einem Marktplatz
                    zu posten und auf diese zu reagieren. Die Abrechnung erfolgt nicht über die Plattform, sondern wird
                    privat zwischen den Nutzern vereinbart.

                    <br/><br/></p>
                <h2>3. Registrierung und Nutzerprofil</h2>
                <p>Bei der Registrierung müssen Nutzer persönliche Daten wie E-Mail, Vorund Nachname, Anrede,
                    Interessen, Alter und Publikum angeben. Diese Informationen werden in ihrem Nutzerprofil
                    gespeichert.

                    <br/><br/></p>
                <h2>4. Nutzungsbedingungen</h2>
                <p>Das Hochladen von Bildern und Videos sowie das Einstellen eines Profilbilds und Banners ist
                    gestattet, sofern diese Inhalte keine Rechte Dritter verletzen.
                    Jeder Nutzer ist für die Inhalte, die er auf der Plattform teilt, selbst verantwortlich.

                    <br/><br/></p>
                <h2>5. Premium-Abonnement</h2>
                <p>Nutzer können ein kostenpflichtiges monatliches Premium-Abonnement abschließen, das zusätzliche
                    Funktionen und Vorteile bietet.
                    Die Kündigung des Abonnements muss in Übereinstimmung mit den auf der Plattform festgelegten
                    Bedingungen erfolgen.

                    <br/><br/></p>
                <h2>6. Datenschutz</h2>
                <p>Die Plattform verpflichtet sich, die Privatsphäre der Nutzer zu schützen und personenbezogene
                    Daten nicht ohne Zustimmung weiterzugeben, es sei denn, es liegt eine gesetzliche Verpflichtung vor.

                    <br/><br/></p>
                <h2>7. Haftungsausschluss</h2>
                <p>Die Plattform übernimmt keine Haftung für Transaktionen oder Interaktionen, die zwischen
                    Nutzern stattfinden. Die Nutzung der Plattform erfolgt auf eigenes Risiko.

                    <br/><br/></p>
                <h2>8. Änderungen der AGB</h2>
                <p>Der Betreiber behält sich das Recht vor, diese AGB zu ändern. Nutzer werden über
                    Änderungen informiert und ihre Zustimmung wird eingeholt.

                    <br/><br/></p>
                <h2>9. Schlussbestimmungen</h2>
                <p>Sollten Teile dieser AGB ungültig sein, bleibt die Gültigkeit der übrigen Bestimmungen
                    unberührt.
                    Es gilt das Recht des Landes, in dem der Betreiber seinen Sitz hat.
                    <br/><br/>
                    <br/><br/></p>

                <h1>Bedingungen für das Premium-Abonnement</h1>
                <h2>1. Abonnement-Beschreibung</h2>
                <p>Das Premium-Abonnement bietet zusätzliche Funktionen und Vorteile auf der
                    Plattform, wie z.B. früheren Zugriff auf Angebote, exklusive Marktplatzangebote, ein
                    Profil-Badge, erweiterte Profil-Einstellungen, höhere Sichtbarkeit von Anzeigen,
                    Anzeigen-Boosting, Priorisierung von Direktnachrichten und weitere Vorteile.

                    <br/><br/></p>
                <h2>2. Laufzeit und Kündigung</h2>
                <p>Das Abonnement ist monatlich und wird automatisch verlängert, es sei denn, es
                    wird vom Nutzer gekündigt.
                    Die Kündigung muss bis spätestens 24 Stunden vor Ablauf des aktuellen
                    Abonnementmonats erfolgen, um eine automatische Verlängerung zu vermeiden.
                    Die Kündigung kann über die Nutzerkontoeinstellungen auf der Plattform oder
                    durch direkte Kontaktaufnahme mit dem Kundenservice vorgenommen werden.

                    <br/><br/></p>
                <h2>3. Zahlungsbedingungen</h2>
                <p>Die Gebühr für das Premium-Abonnement wird monatlich im Voraus berechnet.
                    Die Zahlung erfolgt über die von der Plattform akzeptierten Zahlungsmethoden
                    Paypal, Klarna, Keditkarte.
                    Bei Zahlungsverzug behält sich der Anbieter das Recht vor, den Zugang zu
                    Premium-Funktionen zu sperren, bis die Zahlung eingegangen ist.

                    <br/><br/></p>
                <h2>4. Änderungen der Konditionen</h2>
                <p>Der Anbieter behält sich das Recht vor, die Konditionen des
                    Premium-Abonnements, einschließlich der Preise, zu ändern. Nutzer werden über
                    solche Änderungen informiert und haben die Möglichkeit, ihr Abonnement zu
                    kündigen, falls sie den neuen Bedingungen nicht zustimmen.

                    <br/><br/></p>
                <h2>5. Rückerstattungen</h2>
                <p>Rückerstattungen für das Premium-Abonnement werden normalerweise nicht
                    angeboten. Ausnahmen können in besonderen Fällen gemacht werden, liegen aber im
                    Ermessen des Anbieters.

                    <br/><br/></p>
                <h2>6. Nutzungsrechte</h2>
                <p>Die durch das Premium-Abonnement erworbenen Vorteile sind ausschließlich für
                    den angemeldeten Nutzer und können nicht übertragen oder weiterverkauft werden.

                    <br/><br/></p>
                <h2>7. Haftungsausschluss</h2>
                <p>Der Anbieter haftet nicht für Unterbrechungen oder Ausfälle der
                    Premium-Dienste, die durch Umstände außerhalb seiner Kontrolle verursacht
                    werden.</p>
            </Panel>
        </section>)
}
