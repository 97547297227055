import React from 'react'
import ProfileLink from '../../model/profile-link.model'

import style from './link-item.module.scss'

type LinkItemProps = {
    link: ProfileLink
    editingMode: boolean
    onEdit: (link: ProfileLink) => void
}

export default function LinkItem({ link, editingMode, onEdit }: LinkItemProps) {

    if (editingMode) {
        return (
            <span className={style.item} onClick={() => onEdit(link)}>
                <span className={`${style.name} ${style.icon_pencil}`}>{link?.name}</span>
                <span className={`${style.link} ml2`}>Edit</span>
            </span>
        )
    }

    return (
        <a target='_blank' href={link?.url} className={style.item}>
            <span className={`${style.name} ${style.icon_link}`}>{link?.name}</span>
            <span className={`${style.link} ml2`}>Link</span>
        </a>
    )
}
