import React, { useEffect, useRef, useState } from 'react'
import Menu from './component/settings-menu/settings-menu.component'
import { CSSTransition } from 'react-transition-group'

import style from './settings.module.scss'
import menuAnimation from './settings-animation-menu.module.scss'
import detailsAnimation from './settings-animation-details.module.scss'
import debounce from '../../utility/debounce.utility'
import PasswordDetails from './component/settings-details/component/password-details/password-details.component'
import { DetailView } from './model/detail-view.model'
import AccountCloseDetails from './component/settings-details/component/account-details/account-details.component'
import NotificationDetails from './component/settings-details/component/notification-details/notification-details.component'
import TechnicalError from '../../components/app/component/error/technical/technical-error.component'
import LoadingContent from '../../theme/layout/loading-content/loading-content.component'
import settingsService from '../../service/settings/settings.service'
import ISettings from '../../service/settings/model/settings.model'
import BillingDetails from './component/settings-details/component/billing-details/billing-details.component'
import MailDetails from './component/settings-details/component/mail-details/mail-details.component'
import {useTranslation} from "react-i18next";
import NetworkDetails from "./component/settings-details/component/network-details";
import BlockedDetails from "./component/settings-details/component/blocked-details";

function getDetailComponent(detailView: DetailView) {
  switch (detailView) {
    case DetailView.Account: return AccountCloseDetails;
    case DetailView.Notification: return NotificationDetails;
    case DetailView.Password: return PasswordDetails;
    case DetailView.Billing: return BillingDetails;
    case DetailView.Mail: return MailDetails;
    case DetailView.Networks: return NetworkDetails;
    case DetailView.Blocked: return BlockedDetails;

    default: return AccountCloseDetails;
  }
}


export default function Settings() {
  const [detailView, setDetailView] = useState<DetailView>(DetailView.Notification)
  const [getOpen, setOpen] = useState<boolean>(false)
  const [settings, setSettings] = useState<ISettings>()

  const [notFound, setNotFound] = useState<boolean>(false)
  const [serversideError, setServersideError] = useState<boolean>(false)

    const {t} = useTranslation()


  useEffect(() => {

    const debouncedResizeHandler = debounce(handleResize, 200)

    window.addEventListener('resize', debouncedResizeHandler)

    fetchSettings()
    return () => {
      window.removeEventListener('resize', debouncedResizeHandler)
    }
  }, [])

  async function fetchSettings() {
    try {
      const settings = await settingsService.getSettings()
      setSettings(settings)
    } catch (error) {
      const response = error.toJSON()
      if (response.status === 404) setNotFound(true)
      if (response.status >= 500) setServersideError(true)
    }
  }

  function handleResize() {
    if (window.innerWidth > 735) {
      setDetailView(DetailView.Account)
    } else {
      setOpen(false)
    }
  }

  const handleClick = (details: DetailView) => {
    setDetailView(details)
    setOpen(!getOpen)
  }
  const menuRef = useRef(null)
  const detailRef = useRef(null)

  if (serversideError) return <TechnicalError />
  if (settings === undefined) return <LoadingContent />

  const DetailComponent = getDetailComponent(detailView);

  return (
    <section>
      <div className={style.title_container}>
        <h2 className='text-left ml1'>{t('settings.title')}</h2>
        <div className={style.container}>
          <CSSTransition nodeRef={menuRef}
            in={!getOpen || (window.innerWidth > 735)}
            timeout={500}
            unmountOnExit
            classNames={{ ...menuAnimation }}>
            <span className={style.menu_container} ref={menuRef}>
              <Menu open={getOpen} handleClick={handleClick} />
            </span>
          </CSSTransition>
          <CSSTransition nodeRef={detailRef}
            in={getOpen || (window.innerWidth > 735)}
            timeout={500}
            unmountOnExit
            classNames={{ ...detailsAnimation }}>
            <span className={style.details_container} ref={detailRef}>
              <div className={`${style.backButton}`} onClick={() => setOpen(!getOpen)}></div>
              <DetailComponent settings={settings} onSave={setSettings} />
            </span>
          </CSSTransition>
        </div>
      </div>
    </section>
  )
}
