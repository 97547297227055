import React, { MutableRefObject, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import style from "./keyword-tag.module.scss"
import { IKeyword } from "../../../influspace/profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model"

type KeywordTagProps = {
    keyword: IKeyword
    ref?: MutableRefObject<undefined>
}

export default function KeywordTag({keyword, ref}: KeywordTagProps) {

    const navigate = useNavigate()
    const [edit, setEdit] = useState<boolean>(false)
    const [width, setWidth] = useState<string>('');
    const [content, setContent] = useState<string>(keyword.word);

    useEffect(() => {
        setWidth(`${content.length+1}ch`)
    }, [content]);


    function onClickHandler() {
        navigate(`/search?query=${keyword.word}`)
    }
    
    return (
        <span className={style.tag_view} onClick={onClickHandler}>
            {keyword.word}
        </span>
    )

}