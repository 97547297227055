import React from "react";
import OptionModel from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import style from './type-filter.module.scss'
import FilterDropdown from "../filter-dropdown/filter-dropdown.component";

const options: OptionModel[] = [
    { label: 'All Profiles', value: undefined},
    { label: 'Brand', value: 'brand'},
    { label: 'Influencer', value: 'influencer'}
]

type TypeFilterProps = {
    onChange: (value: string) => void
}
export default function TypeFilter({onChange}: TypeFilterProps) {
    return (
         <span>
            <span className={style.dropdown}><FilterDropdown options={options} onChange={value => onChange(value.value)}/></span>
        </span>
    )
}