import React, {forwardRef, useEffect, useState} from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputDropdown from '../../../../theme/component/input-dropdown/input-dropdown.component'
import DropdownOption from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import InputLabel from '../../../../theme/component/input-label/input-label.component'
import InputText from '../../../../theme/component/input-text/input-text.component'
import {AccountService} from '../../../../service/account/account.service'
import sessionService from '../../../../service/session/session.service'
import DatePicker from "../../../../theme/component/date-picker/date-picker.component";
import {Criteria} from "../../../../theme/component/criteria";
import style from "../../join.module.scss";
import moment from "moment";
import useDebounce from "../../../../theme/hooks/use-debounce.component";
import useQuery from "../../../../theme/hooks/use-query.component";
import Checkbox from '../../../../components/common/checkbox'
import ErrorMessage from "../../../../components/common/error-message";
import {useTranslation} from "react-i18next";


const usernamePattern = {
    match: /^$|^[a-zA-Z0-9_\-.]+$/
} as Pattern

type PanelAccountProps = {
    onCompletion: () => void
    onLogin: (loggedIn: boolean) => void
    mail: string
    password: string
}

function PanelAccount({ onCompletion, onLogin, mail, password }: PanelAccountProps, ref: any) {
    const {t} = useTranslation()

    const joinToken = useQuery('token')

    const [accountType, setAccountType] = useState<DropdownOption>(undefined)
    const [accountTypeError, setAccountTypeError] = useState<string>(undefined)

    const [username, setUsername] = useState<string>('')
    const [usernameError, setUsernameError] = useState<string>(undefined)

    const usernameDebounced = useDebounce<string>(username, 500)
    const [usernameValidAndAvailable, setUsernameValidAndAvailable] = useState(false)

    const [birthday, setBirthday] = useState<string>()
    const [birthdayError, setBirthdayError] = useState<string>(undefined)
    const [birthdayValid, setBirthdayValid] = useState(false)

    const [accountCreationInProgress, setAccountCreationInProgress] = useState(false)

    const [acceptedTermsOfServcie, setAcceptedTermsOfService] = useState(false)
    const [acceptedTermsOfServiceError, setAcceptedTermsOfServiceError] = useState<string>(undefined)

    const accountTypes: DropdownOption[] = [
        { label: t('join.account_type_influencer'),  value: 'influencer' },
        { label: t('join.account_type_brand'), value: 'brand' }
    ]

    function formValid(): boolean {

        if (accountType?.value === undefined) {
            setAccountTypeError(t('join.select_account_type'))
            return false
        }

        if (usernameValidAndAvailable === false || birthdayValid === false) {
            console.log(`form invalid username validation ${usernameValidAndAvailable} birthday validation ${birthdayValid}`)
            return false
        }

        if (acceptedTermsOfServcie === false) {
            setAcceptedTermsOfServiceError(t('join.accept_terms_of_service'))
            return false
        }

        return true
    }

    async function submitForm() {

        if (accountCreationInProgress) {
            return
        }

        if (formValid() === false) {
            return
        }

        setAccountCreationInProgress(true)

        console.log(joinToken !== undefined, 'joinToken')
        if (joinToken !== undefined) {
            await thirdPartyJoin()
        } else if (mail !== '' && password !== '') {
            await regularJoin()
        }

        onCompletion()
    }

    async function thirdPartyJoin() {
        try {
            const sessionDetails = await AccountService.thirdPartyJoin(joinToken, username, birthday, accountType.value)

            sessionService.setSessionDetails(sessionDetails)
            onLogin(true)
        } catch (error) {
            console.log(error)
        }
    }

    async function regularJoin() {
        try {
            const sessionDetails = await AccountService.join({
                mail,
                password,
                username,
                type: accountType.value,
                birthday
            })

            sessionService.setSessionDetails(sessionDetails)
            onLogin(true)
        } catch (error) {
            console.log(error)
        }
    }

    function accountTypeValidation(accountType: DropdownOption) {
        setAccountType(accountType)
        setAccountTypeError(undefined)
    }

    function validateBirthday(birthday: string) {
        setBirthday(birthday)

        const birthdayDate = moment(birthday, 'DD.MM.YYYY').toDate()
        const today = moment().toDate()

        const isOldEnough = moment(today).diff(birthdayDate, 'years') >= 18

        setBirthdayValid(isOldEnough)

        if (isOldEnough === false) {
            setBirthdayError(t('join.at_least_18'))
            return
        }

        setBirthdayError(undefined)
    }

    function acceptTermsOfServiceChanged(checked: boolean) {
        setAcceptedTermsOfService(checked)
        setAcceptedTermsOfServiceError(undefined)
    }

    useEffect(() => {
        // check username available
        // or username invalid

        async function validateUsername() {
            if (username === '') {
                return
            }

            try {
                const usernameAvailable = await AccountService.usernameAvailable(usernameDebounced)

                setUsernameError('')
                setUsernameValidAndAvailable(usernameAvailable)
            } catch (error) {
                setUsernameError(error?.description)
                setUsernameValidAndAvailable(false)
            }
        }

        validateUsername()
    }, [usernameDebounced]);

    const usernameCriteria = username.length > 0 ? (
        <div className={style.criteria_container}>
            <Criteria fulfilled={usernameValidAndAvailable}>{t('join.username_available')}</Criteria>
        </div>
    ) : undefined

    return (
        <div ref={ref}>
            <InputLabel label={t('join.account')} />
            <div className='mb2'>
                <InputDropdown placeholder={t('join.account_type')} options={accountTypes} onChange={accountTypeValidation} error={accountTypeError} />
                <p className={style.info}>{t('join.select_role')}</p>
            </div>
            <div className='mb2'>
                <InputText initialValue={username} pattern={usernamePattern} onChange={setUsername} placeholder={t('join.username')} error={usernameError} />
                <p className={style.info}>{t('join.unique_username')}</p>
                {usernameCriteria}
            </div>
            <div className='mb3'>
                <DatePicker onChange={validateBirthday} error={birthdayError} />
                <p className={style.info}>{t('join.birthday_info')}</p>
            </div>
            <div className='mb3'>
                <Checkbox onChange={acceptTermsOfServiceChanged}>
                    <p className={style.accept_terms_of_service}>
                        {t('join.agree_terms')}
                        <a href='/terms' target='_blank'>{t('join.terms_of_service')}</a> {t('join.and')}&nbsp;
                        <a href='/privacy' target='_blank'>{t('join.privacy_policy')}</a>.
                    </p>
                </Checkbox>
                <ErrorMessage message={acceptedTermsOfServiceError} />
            </div>
            <div className='mb2'>
                <InputButton label={t('join.create_account')} fill={true} onClick={submitForm} />
            </div>
        </div>
    )
}

export default forwardRef(PanelAccount)
