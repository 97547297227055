import React from "react";
import {ISupportTicketResponse} from "../../../../../../../../service/support/model/SupportTicketResponse";
import style from "./index.module.scss"
import moment from "moment";

type TicketOverviewProps = {
    ticket: ISupportTicketResponse,
    onSelect: (ticket: ISupportTicketResponse) => void
    selected: boolean
}

export function TicketOverview({ticket, onSelect, selected}: TicketOverviewProps) {
    return <div className={`${style.container} ${selected ? style.selected : ''}`} onClick={() => onSelect(ticket)}>
        <span className={style.reason}>{ticket.reason.toUpperCase()}:</span>
        <span className={style.topic}>{ticket.topic}</span>
        <span className={style.date}>{moment(ticket.created).format('DD.MM.YY')}</span>
    </div>
}