import React from 'react';

type SvgProps = {
    color?: 'gradient' | 'blue' | 'premium' | string;
    backgroundColor?: string;
    size?: number;
};

const colorMap = {
    gradient: 'url(#_Linear1)',
    blue: 'rgb(0,70,235)',
    premium: 'rgb(217,173,93)'
};

const VerifiedIcon: React.FC<SvgProps> = ({ color = 'gradient', backgroundColor = 'transparent', size = 24 }) => {
    const fillColor = colorMap[color as keyof typeof colorMap] || color;
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
        >
            <defs>
                <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3.67394e-15,60,-60,3.67394e-15,31.8167,2)">
                    <stop offset="0" style={{ stopColor: 'rgb(0,192,252)', stopOpacity: 1 }} />
                    <stop offset="1" style={{ stopColor: 'rgb(0,242,52)', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <rect id="icon" x="0" y="0" width="64" height="64" style={{ fill: 'none' }} />
            <rect x="12" y="12" width="39" height="39" style={{ fill: backgroundColor }} />
            <path
                id="certified"
                d="M25.75,4.992C27.197,3.106 29.439,2 31.817,2C34.194,2 36.436,3.106 37.883,4.992L40.436,8.32L44.53,7.411C46.851,6.896 49.279,7.491 51.1,9.019C52.921,10.547 53.928,12.835 53.824,15.21L53.641,19.4L57.361,21.336C59.47,22.433 60.948,24.449 61.361,26.791C61.774,29.132 61.074,31.532 59.468,33.284L56.634,36.376L58.239,40.25C59.149,42.446 58.986,44.941 57.797,47C56.609,49.059 54.53,50.448 52.173,50.758L48.015,51.304L46.754,55.304C46.04,57.572 44.311,59.378 42.077,60.191C39.843,61.004 37.358,60.732 35.353,59.454L31.817,57.2L28.28,59.454C26.275,60.732 23.79,61.004 21.556,60.191C19.322,59.378 17.593,57.572 16.879,55.304L15.618,51.304L11.46,50.758C9.103,50.448 7.025,49.059 5.836,47C4.647,44.941 4.484,42.446 5.394,40.25L7,36.376L4.166,33.284C2.559,31.532 1.86,29.132 2.272,26.791C2.685,24.449 4.164,22.433 6.273,21.336L9.993,19.4L9.809,15.21C9.705,12.835 10.712,10.547 12.533,9.019C14.354,7.491 16.783,6.896 19.104,7.411L23.198,8.32L25.75,4.992ZM25.814,45.82L13.275,33.281C12.522,32.528 12.522,31.307 13.275,30.553L16.003,27.825C16.756,27.072 17.978,27.072 18.731,27.825L27.178,36.272L45.269,18.18C46.022,17.427 47.244,17.427 47.997,18.18L50.725,20.908C51.478,21.662 51.478,22.883 50.725,23.636L28.542,45.82C27.788,46.573 26.567,46.573 25.814,45.82Z"
                style={{ fill: fillColor }}
            />
        </svg>
    );
};

export default VerifiedIcon;
