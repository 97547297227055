import axios from "axios";
import {ITrackingPayload} from "./ITrackingPayload";

export class AnalyticsService {
    private static root = "/analytics";
    private static client = axios;

    static async track(trackingPayload: ITrackingPayload) {
        await this.client.post(`${this.root}/track`, trackingPayload);
    }

}
