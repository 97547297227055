import React from 'react'
import { Link } from 'react-router-dom'
import Panel from '../../../../../theme/component/panel/panel.component'

import style from '../error.module.scss'
import {useTranslation} from "react-i18next";
import SessionService from "../../../../../service/session/session.service";

export default function VerificationError() {
    const { t } = useTranslation()
    const username = SessionService.getUsername()

    return (
        <Panel className={style.panel}>
            <span className={style.icon_verified} />
            <div className={style.title}>{t('join.verify_mail')}</div>
            <span className={style.description}>
                {t('join.check_mails')}<br />
                {t('join.verify_account')}
            </span>
            <Link to={`/profile/${username}`} className={`${style.button} ${style.buttonFilled}`}>{t('join.visit_profile')}</Link>
        </Panel>
    )
}
