import moment from 'moment'
import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import ChatDetails from '../../../../service/inbox/model/ChatDetails'
import NumberFormatService from '../../../../service/number-format.service'
import Avatar from './component/avatar/avatar.component'
import Menu from './component/menu'

import style from './conversation.module.scss'
import {ReportMenu} from "./component/menu/component/report-menu";
import {PremiumBadge} from "../../../../theme/component/premium-badge";

type ConversationProps = {
    chat: ChatDetails
    onArchive: (chat: ChatDetails) => void
    onBlock: (chat: ChatDetails) => void
    onDelete: (chat: ChatDetails) => void
}

export default function Conversation({ chat, onArchive, onBlock, onDelete }: ConversationProps) {
    const navigate = useNavigate()
    const [openReportMenu, setOpenReportMenu] = useState<boolean>(false)


    return (
        <div className={`${style.panel} l-12`} onClick={onClickHandler}>
            <span className={style.avatar}>
                <Avatar username={chat.chat} avatarUrl={chat.chatter.avatar} unread={chat?.unread} />
            </span>
            <span className={style.details}>
                <DisplayName displayname={chat.chatter.displayname} premium={chat.chatter.premium}></DisplayName>
                <InfoBar follower={chat.chatter.networks.totalFollower} premium={chat.chatter.premium} date={chat?.latestMessage?.date} />
                <LatestMessage message={chat?.latestMessage?.content} />
                <Menu className={style.hidden_menu} chat={chat} onArchive={onArchive} onBlock={onBlock} onDelete={onDelete} onReport={onReportHandler}/>
                <ReportMenu chat={chat} onClose={() => setOpenReportMenu(!openReportMenu)} open={openReportMenu}/>
            </span>
            <span className={style.chevron} />
        </div>
    )

    function onReportHandler() {
        setOpenReportMenu(true)
    }

    function onClickHandler(event: any) {
        const target = event.target
        console.log(target)

        // Check if the target id contains 'delete' else the chat will be opened
        if (target.className.includes('overlay')) {
            return;
        }
        if (target.className.includes('input-button') || target.className.includes('input-text')) {
            return;
        }

        if (target.tagName !== 'IMG' && target.tagName !== 'LI' && target.tagName !== 'DIV' && target.id !== 'menu_toggle' && target.id !== 'hidden_menu') {
            navigate('/chat/' + chat.chat)
        }
    }
}

function DisplayName({ displayname, premium }: { displayname: string, premium: boolean }) {
    return (
        <span className={style.displayname}>
            <span className={premium ? style.displaynameTextPremium : style.displaynameText}>
                {displayname}

            </span>
            <span className={style.premiumBadgeContainer}>
                <PremiumBadge premium={premium}/>
            </span>
        </span>
    )
}

function LatestMessage({message}: { message: string }) {
    return <p className={style.latest_message}>{message}</p>
}

function InfoBar({ follower, date, premium }: { follower: number, date: Date, premium: boolean }) {
    const totalFollower = NumberFormatService.renderNumber(follower);
    return (
        <span className={`${style.info_bar} ${premium ? style.info_bar_premium : ''}`}>
            <span className={`pre-icon ${style.icon_rank}`}>{totalFollower}</span>
            <span className={`pre-icon ${style.icon_dot}`}>{moment(date).format('HH:mm')}</span>
        </span>
    );
}

function onBlockHandler(event: any) {

}

function onDeleteHandler(event: any) {

}




