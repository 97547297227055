import React, { useEffect, useState } from 'react'
import { INetwork } from '../../../../service/network/model/INetworkDetails'
import networkService from '../../../../service/network/network.service'
import numberFormatService from '../../../../service/number-format.service'
import ProfileView from '../../../../service/profile/model/profile-view.model'
import Panel from '../../../../theme/component/panel/panel.component'
import FollowerCount from './component/follower-count/follower-count.component'
import NetworkItem from './component/network-item/network-item.component'
import INetworkDetails from './models/network-details.model'

import style from './profile-network.module.scss'
import {useTranslation} from "react-i18next";
import {ConnectCTA} from "./component/connect_cta/index.component";
import SessionService from "../../../../service/session/session.service";

type ProfileNetworkProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileNetwork({ profile, editingMode }: ProfileNetworkProps) {
    const [networkDetails, setNetworkDetails] = useState<INetworkDetails>()
    const { t } = useTranslation()

    useEffect(() => {
        const networkDetails = profile?.networks as INetworkDetails
        setNetworkDetails(networkDetails)

    }, [profile])

    function hasNetworks() {
        return networkDetails?.networks?.length > 0
    }


    async function refreshNetworks() {
        const networkDetails = await networkService.getNetworks()
        setNetworkDetails(networkDetails)
    }


    function Divider() {
        return <hr className='divider' />
        // return networkDetails.networks.length > 0 ? (<hr className='divider' />) : (<></>)
    }

    if (hasNetworks() || editingMode) {
        const sortedNetworks = networkDetails?.networks.sort((a, b) => b.follower - a.follower);
        const listProfiles = sortedNetworks?.map((network) => (
            <NetworkItem key={network.ref} network={network as INetwork} editingMode={editingMode} onDisconnect={refreshNetworks} />
        ));
        const totalFollower = numberFormatService.renderNumber(networkDetails?.totalFollower)
        return (
            <Panel overridePadding={style.panel}>
                <h1 className={style.title}>{t('profile_networks.title')}</h1>
                {hasNetworks() === false && <ConnectCTA />}
                {listProfiles}
                <Divider />
                <FollowerCount networks={sortedNetworks} totalFollower={totalFollower} editingMode={editingMode} />
            </Panel>
        )
    }

    if (profile.username !== SessionService.getUsername())
        return <></>
    else
        return <Panel overridePadding={style.panel}>
            <h1 className={style.title}>{t('profile_networks.title')}</h1>
            <ConnectCTA />
            <FollowerCount networks={[]} totalFollower={'0'} editingMode={true} />
        </Panel>
}
