import React, { useEffect, useState } from 'react'
import config from '../../../../service/config/config'
import sessionService from '../../../../service/session/session.service'
import ApiClientService from '../../../../service/api-client.service';
import ChatService from '../../../../service/chat/chat.service';

interface IData { }

enum MessageType {
    Authentication = 'authentication',
    ChatMessage = 'text',
}

type Message = {
    type: MessageType
    content: IData
}

class IAuthentication implements IData {
    token: string
}

class IChatMessage implements IData {
    chat: string
    message: string
}

export default function DevWebSocket() {

    const [connection, setConnection] = useState(null)
    const [message, setMessage] = useState('')
    const [receiver, setReceiver] = useState('')
    const [receivedMessages, setReceivedMessages] = useState([])

    useEffect(() => {
        // Create a WebSocket connection

        const ws = new WebSocket(`ws://localhost:25566/api/chat/subscribe`) // config.wsRoot

        // Set up event handlers
        ws.onopen = () => {
            const { username, token } = sessionService.getSessionDetails()

            ws.send(JSON.stringify({ type: MessageType.Authentication, content: { token } }))

            console.log('WebSocket connection opened')
        }

        ws.onmessage = (event) => {
            console.log('Received message:', event.data)
            // Update the list of received messages
            setReceivedMessages((prevMessages) => [...prevMessages, event.data])
        }

        ws.onclose = () => {
            console.log('WebSocket connection closed')
        }

        // Save the WebSocket connection instance
        setConnection(ws)

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            ws.close()
        }
    }, [])

    const handleInputChange = (event: any) => {
        setMessage(event.target.value)
    }

    const handleSendClick = async () => {
        await ChatService.sendMessage(receiver, message)

        // if (connection && connection.readyState === WebSocket.OPEN) {
        //     // Send the message to the server
        //     const encodedMessage: Message = {
        //         type: MessageType.ChatMessage,
        //         content: {
        //             chat: 'kevin',
        //             message
        //         }
        //     }
        //
        //     connection.send(encodedMessage)
        //     setMessage('')
        // }
    }

    return (
        <div>
            <h1>WebSocket Client</h1>
            <div>
                <input type="text" value={receiver} onChange={event => setReceiver(event.target.value)} placeholder='Receiver'/>
                <input type="text" value={message} onChange={event => setMessage(event.target.value)} placeholder='Message'/>
                <button onClick={handleSendClick}>Send</button>
            </div>
            <div>
                <h2>Received Messages:</h2>
                <ul>
                    {receivedMessages.map((msg, index) => (
                        <li key={index}>{msg}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}