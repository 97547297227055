import React, { useEffect, useState } from 'react'
import InputButton from '../../../../theme/component/input-button'
import ProfileGrid from '../../../../theme/component/profile-grid/profile-grid.component'
import { IProfileTile } from '../../../../service/search/model/profile-tile.model'

export default function DevProfileTileGrid() {
    const [profiles, setProfiles] = useState<IProfileTile[]>([])
    const [showReject, setShowReject] = useState<boolean>(false)

    const platforms = ['youtube', 'twitter', 'twitch', 'tiktok', 'instagram']

    function generateProfile(username: string) {
        return {
            username: username,
            displayname: 'Max Eichen',
        
            avatarUrl: '/api/image?ref=d129fe16-47ef-4c5d-a197-1caddf94ec7e',
            bannerUrl: '/api/image?ref=e48450da-7cca-47d2-a3d5-0a67d24a341c',
            
            description: 'Ja was gehtn!',
            
            mainPlatform: platforms[Math.floor(Math.random() * platforms.length)],
            follower: 56735,
            networks: [],
            collaborations: {
                freeProductPlacement: true,
                influencerCollaboration: true,
                productPlacement: true
            },
            keywords: [],
            premium: true
        } as IProfileTile
    }

    useEffect(() => {
        let profiles: IProfileTile[] = []

        for (let i = 0; i < 7; i++) {
            profiles.push(generateProfile(`Grifting${i}`))
        }
        
        setProfiles(profiles)
    }, [])

    return (
        <>
            <section className='my2'>
                <InputButton label='Toggle Reject Button' onClick={() => setShowReject(!showReject)} />
            </section>
            <ProfileGrid profiles={profiles} showReject={showReject} />
        </>
    )
}
