import React from 'react'
import Panel from '../../../theme/component/panel/panel.component'

export default function Upcoming() {
    return (
        <section>
            <Panel overridePadding='px5 py3'>
                <h2>Latest Patches and Improvements</h2>
                <p>+ 29.05.2024 - Fixed campaign creation broken</p>
                <br/>
                <h2>Roadmap & Development</h2>
                <p>+ User experience enhancement</p>
                <p>+ Mobile optimization</p>
                <p>+ Bug fixes</p>
                <p>+ Marketplace 2.0</p>
            </Panel>
        </section>
    )
}
