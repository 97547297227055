import React from "react";
import InputButton from "../input-button";
import Overlay from "../overlay/overlay.component";
import {ButtonColor} from "../input-button/models/button-color.model";

type ConfirmationDialogType = {
    title: string,
    payload: any,
    dialog: string,
    open: boolean,
    onConfirm: (ref: any) => void,
    onClose:() => void,
    actionButtonText: string
}

export function ConfirmationDialog({ payload, title, dialog, actionButtonText, open, onClose, onConfirm}: ConfirmationDialogType) {
    return <Overlay open={open} title={title} onClose={onClose}>
        <div className='mb3 mt2'>{dialog}</div>
        <span className='float-right'><InputButton label={actionButtonText} onClick={() => onConfirm(payload)}/></span>
        <span className='float-left'><InputButton label={'Cancel'} onClick={onClose} color={ButtonColor.Failure}/></span>
    </Overlay>
}