import { useLocation } from 'react-router-dom'

export default function useQuery(targetParameter: string, defaultParameter: string = undefined) {
    const { search } = useLocation()

    if (search === '') {
        return defaultParameter
    }

    const match = new URLSearchParams(search)?.get(targetParameter)

    return match === null ? defaultParameter : match
}