import axios from 'axios';
import {ApiResponse, User} from "../interface/api-response";
import {IMailSendable} from "../interface/mail-sendable.interface";

export class MailApi {

    static async getAllTemplates(): Promise<ApiResponse<string[]>> {
        const response = await axios.get<ApiResponse<string[]>>('/mail/templates');

        return response.data;
    }

    static async renderTemplate(template: string): Promise<ApiResponse<IMailSendable>> {
        const response = await axios.get<ApiResponse<IMailSendable>>(`/mail/template/${template}`);

        return response.data;
    }

    static async sendTemplateMail(template: string): Promise<ApiResponse<IMailSendable>> {
        const response = await axios.post<ApiResponse<IMailSendable>>(`/mail/template/${template}`);

        return response.data;
    }
}
