import React, { useEffect, useState } from 'react'
import ProfileView from '../../../../service/profile/model/profile-view.model'
import profileService from '../../../../service/profile/profile.service'
import Panel from '../../../../theme/component/panel/panel.component'
import ProfileAge from './component/profile-age/profile-age.component'
import ProfileGender from './component/profile-gender/profile-gender.component'
import { IKeyword } from './component/profile-keywords/model/profile-keyword.model'
import ProfileKeywords from './component/profile-keywords/profile-keywords.component'
import { IAudience, TargetAge, TargetGender } from './model/demography.model'

import style from './profile-audience.module.scss'
import {useTranslation} from "react-i18next";

type ProfileAudienceProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileAudience({ profile, editingMode }: ProfileAudienceProps) {
    const [selectedGender, setSelectedGender] = useState<TargetGender>()
    const [selectedAge, setSelectedAge] = useState<TargetAge>()
    const [keywords, setKeywords] = useState<IKeyword[]>()
    const [updatesMade, setUpdatesMade] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (editingMode === false && updatesMade === true) {
            updateDemography()
            setUpdatesMade(false)
        }
    }, [editingMode])

    useEffect(() => {
        setKeywords(profile?.keywords)
        setSelectedGender(profile?.demographics?.targetGender)
        setSelectedAge(profile?.demographics?.targetAge)     
    }, [profile])

    async function updateDemography() {
        try {
            const request = {
                targetAge: selectedAge,
                targetGender: selectedGender
            } as IAudience

            const response = await profileService.setDemography(request)

            setSelectedGender(response.targetGender)
            setSelectedAge(response.targetAge)
        } catch (error) {
            console.log(error)
        }
    }
    function onUpdateGender(gender: TargetGender) {
        setSelectedGender(gender)
        if (profile.demographics.targetGender !== gender)
        setUpdatesMade(true)
    }

    function onUpdateAge(age: TargetAge) {
        setSelectedAge(age)
        if (profile.demographics.targetAge !== age)
        setUpdatesMade(true)
    }

    async function onUpdateKeywords(newKeyword: IKeyword) {
        if (keywords.length > 9) 
            return
        if (keywords.find((keyword) => keyword.word === newKeyword.word) !== undefined)
            return

        try {
            const existingKewordIndex = keywords.findIndex((keyword) => keyword.ref === newKeyword.ref)
            if (existingKewordIndex !== -1) {
                const response = await profileService.editKeyword(newKeyword)
                keywords[existingKewordIndex] = response
                setKeywords([...keywords])
            } else {
                const response = await profileService.addKeyword(newKeyword)
                keywords.push(response)
                setKeywords([...keywords])
            }
            setUpdatesMade(true)
        } catch (error) {
            console.log(error)
        }
    }

    async function onDeleteKeyword(keyword: IKeyword) {
        try {
            const response = await profileService.deleteKeyword(keyword)
            setKeywords(keywords.filter((keyword) => keyword.ref !== response.ref))
            setUpdatesMade(true)
        } catch (error) {
            console.log(error)
        }
    }

    if (editingMode === true ||(keywords?.length > 0 ||
         selectedAge !== TargetAge.NotSpecified ||
         selectedGender !== TargetGender.NotSpecified)) {
        return (
            <Panel overridePadding={style.panel}>
                    <h1 className={style.title}>{t('profile_audience.title')}</h1>
                    <ProfileKeywords onDelete={onDeleteKeyword} onChange={onUpdateKeywords} editingMode={editingMode} keywords={keywords} />
                    <ProfileAge onChange={onUpdateAge} editingMode={editingMode} targetAge={selectedAge}/>
                    <ProfileGender onChange={onUpdateGender} editingMode={editingMode} targetGender={selectedGender}/>
            </Panel>
        )
    } else {
        return (<></>)
    }
    
}
