import React, { useEffect } from "react";
import style from "./index.module.scss"

type CriteriaProps = {
    fulfilled: boolean
    children: React.ReactNode
}
export function Criteria({ fulfilled, children }: CriteriaProps) {

    const checkmark = fulfilled === true ? <span className={style.checked}/> : <span className={style.unchecked}/>

    return (
        <div className={style.container}>
            {checkmark}
            <span className={style.label}>{children}</span>
        </div>
    );
}