import moment from 'moment';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import ChatMessage from '../../../../service/chat/model/ChatMessage';
import ChatDetails from '../../../../service/inbox/model/ChatDetails';
import LoadingContent from '../../../../theme/layout/loading-content/loading-content.component';
import MessageInfo from '../message-info';
import Message from './component/message';
import { Receipient } from './component/message/model/receipient.model';
import LoadingMoreContent from "../../../../theme/layout/loading-spinner/loading-spinner.component";

import style from './index.module.scss';

export default function MessageGrid({ messages, chatDetails, smaller, loadMoreMessages, loadingMore }: { messages: ChatMessage[], chatDetails: ChatDetails, smaller: boolean, loadMoreMessages: () => void, loadingMore: boolean }) {
    const [unreadMessage, setUnreadMessage] = useState<ChatMessage>(null);
    const chatRef = useRef(null);

    useEffect(() => {
        const chat = chatRef.current;
        if (chat) {
            chat.scrollTop = chat.scrollHeight; // Start at the bottom when the component is first loaded
        }
    }, [chatDetails]);

    const handleScroll = useCallback(() => {
        const chat = chatRef.current;
        if (chat.scrollTop === 0) {
            loadMoreMessages();
        }
    }, [loadMoreMessages]);

    useEffect(() => {
        const chat = chatRef.current;
        if (chat) {
            chat.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (chat) {
                chat.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        if (loadingMore) {
            const chat = chatRef.current;
            const previousScrollHeight = chat.scrollHeight;
            const previousScrollTop = chat.scrollTop;

            // Adjust the scroll position after new messages are loaded
            setTimeout(() => {
                chat.scrollTop = previousScrollTop + (chat.scrollHeight - previousScrollHeight);
            }, 0);
        }
    }, [loadingMore, messages]);

    useEffect(() => {
        const chat = chatRef.current;
        if (chat) {
            chat.scrollTop = chat.scrollHeight; // Scroll to the bottom when messages change
        }
    }, [messages]);

    if (messages === null) {
        return (
            <span className={style.chat_history_container}>
                <div className={style.message_loading}>
                    <LoadingContent />
                </div>
            </span>
        );
    }

    const renderedMessages = messages.map((message, index) => {
        const receipient = message.senderUsername === chatDetails.chat ? Receipient.Receiver : Receipient.Sender;
        const showDatestamp = index > 0 && moment(message.date).date() !== moment(messages[index - 1].date).date() || index === 0;
        const showUnreadbarrier = unreadMessage?.ref === message.ref;

        return <Message key={message.ref} message={message} receipient={receipient} showDatestamp={showDatestamp} unreadbarrier={showUnreadbarrier} />;
    });

    const showInfoMessage = chatDetails.messages === 1 && messages[0]?.senderUsername !== chatDetails.chat;
    const messagingInfo = showInfoMessage ? <MessageInfo /> : <></>;

    return (
        <span className={style.chat_history_container}>
            {loadingMore && <LoadingMoreContent />}
            <div className={`${style.chat} ${smaller ? style.chat_smaller : ''}`} ref={chatRef}>
                {renderedMessages}
                {messagingInfo}
            </div>
        </span>
    );
}
