import React from "react";
import style from "./index.module.scss"
import i18n from "i18next";

export function LanguageGlobe() {

    return <span className={style.container} onClick={() => i18n.changeLanguage(i18n.language === 'de' ? 'en' : 'de')}>
        <span className={style.globe} />
        <span  className={style.language}>{i18n.language !== 'de' ? 'German' : 'Englisch'}</span>
    </span>

}