import { spawn } from 'child_process'
import React, { useState } from 'react'

import style from './image-content.module.scss'

type ImageContentProps = {
    src?: string
    alt: string
    className?: string
}

export default function ImageContent({ src, alt, className }: ImageContentProps) {
    const [loaded, setLoaded] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const extraStyles = className ? className : ''
    console.log('ImageContent', src, alt, className)
    return (
        <span className={`${extraStyles} ${style.frame} img`}>
            {!loaded && !error && <span className={`${style.placeholder} skeleton`}></span>}
            {!loaded && error && <span className={style.error} />}
            <img className={style.image} src={src} alt={alt} onLoad={() => setLoaded(true)} onError={() => setError(true)} />
        </span>
    )
}
