import React, { useEffect, useState } from 'react'
import profileService from '../../../../../../service/profile/profile.service'
import InputTextArea from '../../../../../../theme/component/input-text-area/input-text-area.component'
import style from "../../../../../join/component/panel-description/panel-description.module.scss";
import {useTranslation} from "react-i18next";

type ProfileDescriptionProps = {
    details: string
    editingMode: boolean
    onUpdate: (description: string) => void
}

export default function ProfileDescription({details, editingMode, onUpdate}: ProfileDescriptionProps) {

    const [originalDescription, setOriginalDescription] = useState<string>(details)
    const [description, setDescription] = useState<string>(details)
    const [madeChanges, setMadeChanges] = useState<boolean>(false)
    const [charactersLeft, setCharactersLeft] = useState<number>(!details ? 300 : 300 - details.length)

    const {t} = useTranslation()

    useEffect(() => {
        if (details !== originalDescription) {
            setOriginalDescription(details)
            setDescription(details)
            setMadeChanges(false)
        }

        if (editingMode === false && madeChanges) {
            if (description !== originalDescription) {
                updateDescription()
            }
        }

    }, [details, editingMode])

    function onChangeHandler(value: string) {
        if (value.length > 300)
            return

        setMadeChanges(true)
        setDescription(value)
        setCharactersLeft(300 - value.length)

    }

    async function updateDescription() {
        try {
            const newDescription = await profileService.setDescription(description)
            setOriginalDescription(newDescription)
            setDescription(newDescription)
            onUpdate(description)
        } catch (error) {
            console.log(error)
            //TODO Error handling
        }
    }

    if (editingMode === true) {
        return (
            <div className='mt1'>
                <InputTextArea maxLength={300} initialValue={description} onChange={onChangeHandler} placeholder='Description'></InputTextArea>
                <span className={`${style.characterCount} mt2`}>{charactersLeft} {t('join.characters_left')}</span>

            </div>
        )
    }
    if(details === undefined) {
        return <></>
    } else {
        return (
            <div className='mt1'>{details}</div>
        )
    }
}
