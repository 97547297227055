import axios from "axios";
import Feed from "./model/feed.model";
import {IProfileTile} from "../search/model/profile-tile.model";

export class ExploreService {
    private static root = "/explore";
    private static client = axios;

    static async getExplore(): Promise<Feed> {
        try {
            let response = await this.client.get(this.root);
            return response.status === 200 ? response.data : null;
        } catch (error) {
            console.log(error);
            return { topProfiles: [], topOffers: [], seed: "", profiles: [] };
        }
    }

    static async getProfilesByPage(page: number, seed: string): Promise<IProfileTile[]> {
        let response = await this.client.get(`${this.root}/profiles`, {
            params: {page, seed},
        });
        return response.data
    }
}

