import React from "react";
import style from "./index.module.scss"

type PremiumBadgeProps = {
    className?: string  // Diese Klasse wird benutzt, um die Standard-Klassen zu überschreiben
    premium: boolean
}

export function PremiumBadge({ className, premium }: PremiumBadgeProps) {
    if (!premium) {
        return <></>
    }

    return (
        <span
            id='premium-badge'
            className={`${style.premium_badge} ${className || ''} tooltip `}
        >
            <span className={`${style.tooltip} tooltiptext`}>Premium</span>
        </span>
    );
}
