import React from 'react'
import { Link } from 'react-router-dom'
import sessionService from '../../../../../service/session/session.service'
import Panel from '../../../../../theme/component/panel/panel.component'

import style from '../error.module.scss'

export default function ProfileError({ username }: { username: string }) {
    const returnTo = sessionService.isAuthorized() ? '/explore' : '/'
    const buttonText = sessionService.isAuthorized() ? 'Explore Profiles' : 'Back to Influspace'

    return (
        <Panel className={style.panel}>
            <span className={style.icon} />
            <div className={style.title}>Profile not Found</div>
            <span className={style.description}>
                Profile "{username}" not available.<br />
                Return back to Influspace.
            </span>
            <Link to={returnTo} className={`${style.button} ${style.large}`}>{buttonText}</Link>
        </Panel>
    )
}
