import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styles from './blogdetail.module.scss';
import ReactMarkdown from 'react-markdown';

const BlogDetail = () => {
    const { ref } = useParams();
    const [blog, setBlog] = useState(null);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const createdAtDate = new Date(blog?.createdAt).toLocaleDateString(undefined, options);

    useEffect(() => {
        axios.get(`/blog/${ref}`)
            .then(response => {
                setBlog(response.data);
            })
            .catch(error => {
                console.error('Error fetching blog details', error);
            });
    }, [ref]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    return (
        <section className={styles.blogDetail}>
            <h1>{blog.title}</h1>
            <p className={styles.createdAt}>{createdAtDate}</p>
            <img src={`https://influspace.com/api/image?ref=${blog.image}`} alt={blog.title}/>
            <p className={styles.status}>{blog.status.toLocaleUpperCase()}</p>
            {/* blog.abstract and blog.content are in Markdown format, so they can contain breaks, bold text, and other formatting */}
            <ReactMarkdown className={styles.abstract}>{blog.abstract}</ReactMarkdown>
            <br/>
            <ReactMarkdown className={styles.content}>{blog.content}</ReactMarkdown>
        </section>
    );
};

export default BlogDetail;
