import React, { useEffect, useState } from 'react';
import InputCheckbox from '../../../../../../theme/component/input-checkbox/input-checkbox.component';
import InputButton from '../../../../../../theme/component/input-button';
import style from './notification-details.module.scss';
import settingsService from '../../../../../../service/settings/settings.service';
import InputText from '../../../../../../theme/component/input-text/input-text.component';
import ISettings from '../../../../../../service/settings/model/settings.model';
import InputSlider from '../../../../../../theme/component/slider/input-slider.component';
import { NotificationService } from '../../../../../../service/notification/notification.service';
import { NotificationType } from '../../../../../../service/notification/model/notification-type.enum';
import { useTranslation } from 'react-i18next';

type NotificationDetailsProps = {
    settings: ISettings;
    onSave: (settings: ISettings) => void;
};

export default function NotificationDetails({ settings, onSave }: NotificationDetailsProps) {
    const { t } = useTranslation();

    const [businessCanMessage, setBusinessCanMessage] = useState<boolean>(false);
    const [influencerCanMessage, setInfluencerCanMessage] = useState<boolean>(false);
    const [influencerFollowerRequired, setInfluencerFollowerRequired] = useState<number>(
        settings?.inquirySettings?.influencerFollowerRequired || 0
    );
    const [disableFollowerRequiredInput, setDisableFollowerRequiredInput] = useState<boolean>(
        !settings?.inquirySettings?.influencerCanMessage
    );
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);

    useEffect(() => {
        setBusinessCanMessage(settings?.inquirySettings?.businessCanMessage);
        setInfluencerCanMessage(settings?.inquirySettings?.influencerCanMessage);
        setInfluencerFollowerRequired(settings?.inquirySettings?.influencerFollowerRequired);
        setDisableFollowerRequiredInput(!settings?.inquirySettings?.influencerCanMessage);
    }, [settings]);

    async function onSaveInput() {
        setDisableSaveButton(true);
        try {
            settings.inquirySettings = await settingsService.setInquerySettings({
                businessCanMessage,
                influencerCanMessage,
                influencerFollowerRequired,
            });
            NotificationService.displayNotification({
                message: t('notification.save_success_message'),
                type: NotificationType.success,
            });
            onSave(Object.assign({}, settings));
        } catch (error) {
            NotificationService.displayNotification({
                message: t('notification.save_failure_message'),
                type: NotificationType.failure,
            });
        }
        setDisableSaveButton(false);
    }

    return (
        <>
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>{t('notification.title')}</div>
                    <div className={style.info}>{t('notification.manage_settings')}</div>
                </div>
            </div>
            <div>{t('notification.set_who_can_message_you')}</div>
            <InputCheckbox label={t('notification.businesses')} checked={businessCanMessage} onChange={setBusinessCanMessage} />
            <InputCheckbox
                label={t('notification.influencer')}
                checked={influencerCanMessage}
                onChange={(checked) => {
                    setInfluencerCanMessage(checked);
                    setDisableFollowerRequiredInput(!checked);
                    setInfluencerFollowerRequired(0);
                }}
            />
            <div>
        <span className={style.slider}>
          <InputSlider
              disabled={disableFollowerRequiredInput}
              min={0}
              max={100000}
              initialValue={influencerFollowerRequired}
              onChange={setInfluencerFollowerRequired}
          />
        </span>
                <span className={style.input}>
          <InputText
              pattern={{ match: /^\d+$/, allowedCharacters: /^\d+$/ }}
              initialValue={influencerFollowerRequired?.toString()}
              onChange={(value) => setInfluencerFollowerRequired(Number.parseInt(value))}
              disabled={disableFollowerRequiredInput}
              placeholder={t('notification.follower_count')}
          />
        </span>
                <div className={style.info}>{t('notification.specify_follower_count')}</div>
            </div>

            <div className='mt1'>
        <span className={style.buttonL}>
          <InputButton disabled={disableSaveButton} label={t('notification.save')} onClick={onSaveInput} />
        </span>
            </div>
        </>
    );
}
