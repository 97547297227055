import React, {useState} from "react";
import {DropdownPanel} from "../../../../theme/component/dropdown-panel/dropdown-panel.component";
import InputCheckbox from "../../../../theme/component/input-checkbox/input-checkbox.component";
import ICollaboration from "../../../profile/component/profile-collaboration/model/collaboration.model";
import style from "./index.module.scss"
import {useTranslation} from "react-i18next";

type CollaborationFilterProps = {
    onChange: (collaboration: ICollaboration) => void
    open: boolean
    onOpen?: (value: boolean) => void
    initialCollaboration: ICollaboration
}
export function CollaborationFilter({onChange, open, onOpen, initialCollaboration}: CollaborationFilterProps) {
    const [freeProductPlacement, setFreeProductPlacement] = useState<boolean>(initialCollaboration.freeProductPlacement)
    const [productPlacement, setProductPlacement] = useState<boolean>(initialCollaboration.productPlacement)
    const [influencerCollaboration, setInfluencerCollaboration] = useState<boolean>(initialCollaboration.influencerCollaboration)
    const { t, i18n } = useTranslation();

    function onChangeCollaboration(collaboration: ICollaboration) {
        onChange(collaboration);
        setFreeProductPlacement(collaboration.freeProductPlacement)
        setProductPlacement(collaboration.freeProductPlacement);
        setInfluencerCollaboration(collaboration.influencerCollaboration)
    }


    return <DropdownPanel label={'Collaboration'} open={open} onOpen={onOpen}>
        <div className={style.filter_element}>
            <InputCheckbox label={t('collaboration_filter_trade')} checked={freeProductPlacement} onChange={(value) => onChangeCollaboration({freeProductPlacement: value, productPlacement, influencerCollaboration})} />
        </div>
        <div className={style.filter_element}>
            <InputCheckbox label={'collaboration_filter_paid'} checked={productPlacement} onChange={(value) => onChangeCollaboration({freeProductPlacement, productPlacement: value, influencerCollaboration})} />
        </div>
        <div className={style.filter_element}>
            <InputCheckbox label={t('collaboration_filter_all')} checked={influencerCollaboration} onChange={(value) => onChangeCollaboration({freeProductPlacement, productPlacement, influencerCollaboration: value})} />
        </div>
    </DropdownPanel>
}