import React, {useEffect, useState} from 'react'
import style from './profile-location.module.scss'
import profileService from "../../../../service/profile/profile.service";
import {LocationInputText} from "../../../../theme/component/location-input-text";
import ProfileService from "../../../../service/profile/profile.service";

type ProfileLocationProps = {
    profileLocation: ILocation
    editingMode: boolean
    onUpdate: (location: ILocation) => void
}

export default function ProfileLocation({profileLocation, editingMode, onUpdate}: ProfileLocationProps) {

    const [location, setLocation] = useState<ILocation>()

    useEffect(() => {
        setLocation(profileLocation)
    }, [profileLocation])


    async function onChange(newLocation: ILocation) {
        try {
            if (!newLocation) {
                await profileService.deleteLocation()
            } else {
                await ProfileService.setLocation(newLocation)
            }
            onUpdate(newLocation)
        } catch (error) {
            console.log(error)
            //TODO: error handling
        }
    }

    if (editingMode === true) {
        return <div>
            <span className={style.location_icon}/>
            <span className={style.location_input}>
                <LocationInputText onChange={onChange} initialLocation={location}/>
            </span>
        </div>
    } else if (location?.display?.length > 0) {
        return <div className={style.location_container}>
            <span className={style.location_icon}></span>
            <span className={style.location_label}>{location.display}</span>
        </div>
    } else {
        return <></>
    }

}
