// navigation-button/index.tsx
import React from 'react';
import style from './index.module.scss';

interface NavigationButtonProps {
    label: string;
    onClick: () => void;
    disabled: boolean;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ label, onClick, disabled }) => {
    const isDoubleChevron = label === 'First' || label === 'Last';
    const isPrev = label === 'Previous' || label === 'First';
    const buttonClass = `${style.chevron} ${isPrev ? style['chevron--prev'] : ''} ${isDoubleChevron ? style['chevron--double'] : ''}`;

    return (
        <button onClick={onClick} disabled={disabled} className={buttonClass}></button>
    );
};

export default NavigationButton;
