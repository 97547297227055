export enum TargetGender {
    Mixed = 'mixed',
    Female = 'female',
    Male = 'male',
    Diverse = 'diverse',

    NotSpecified = 'not_specified'
}

export enum TargetAge {
    Kids = 'kids',
    Teens = 'teens',
    Adults = 'adults',
    Elderly = 'elderly',

    NotSpecified = 'not_specified'
}

export  interface IAudience {
    targetAge: TargetAge
    targetGender: TargetGender
}