import React from 'react'

import style from './pagination.module.scss'

type PaginationProps = {
    pages: number,
    complete: number
}

export default function Pagination({ pages, complete }: PaginationProps) {
    const pagination = [...Array(pages)].map((element, index) => {
        if (index < complete) {
            return <span key={index} className={style.done}></span>
        } else {
            return <span key={index}></span>
        }
    })

    return (
        <div className={style.pagination}>
            {pagination}
        </div>
    )
}
