import React, { useState } from 'react'

import style from './input-filter.module.scss'
import FilterOption from "./models/filter-option";
import {DropdownPanel} from "../dropdown-panel/dropdown-panel.component";


type InputFilterProps = {
  label?: string
  placeholder?: string
  options: FilterOption[]
  onSelect: (value: FilterOption) => void
  disabled?: boolean
  initialOption?: FilterOption
  error?: string
  onOpen?: (value: boolean) => void
  open: boolean
}

export function InputFilter({ label, placeholder, options, onSelect, disabled, initialOption, error, open, onOpen }: InputFilterProps) {
  const [selected, setSelected] = useState<FilterOption>(initialOption)

  function FilterBody() {
    if (open === true)
    return <>{options.map(option =>  <OptionElement option={option} key={option.value}/>)}</>
    else
        return <></>
  }

  function onChange(option: FilterOption) {
    console.log(option)
    setSelected(option)
    onSelect(option)
  }

  function OptionElement({option}: {option: FilterOption}) {
    return <div onClick={() => onChange(option)} className={`${style.filter_element} ${selected === option ? style.selected : ''}`}>{option.label}</div>
  }

  return <DropdownPanel label={selected.label} open={open} onOpen={onOpen}>
      <FilterBody/>
  </DropdownPanel>
}