import React, {useEffect, useState} from 'react'
import ProfileView from '../../../../service/profile/model/profile-view.model'

import style from './index.module.scss'
import { ProfileRecommendationComponent } from "./component/profile-recommendation";
import {useTranslation} from "react-i18next";

type ProfileRecommendationProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileRecommendations({ profile, editingMode }: ProfileRecommendationProps) {

    const [recommendations, setRecommendations] = useState<ProfileView[]>(profile.profileRecommendations)
    const { t } = useTranslation();

    useEffect(() => {
        setRecommendations(profile.profileRecommendations)
    }, [profile]);

    if (editingMode || profile.profileRecommendations?.length < 1) {
        return <></>
    }

    return (
        <div>
            <h1 className={style.title}>{t('profile_suggestions.title')}</h1>
            {recommendations.map(recommendation => <ProfileRecommendationComponent profile={recommendation} key={recommendation.username} />)}
        </div>
    )


}
