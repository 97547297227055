import React from 'react'

import style from './input-checkbox.module.scss'

type InputCheckboxProps = {
    label: string
    checked?: boolean
    onChange?: (checked: boolean) => void
    bold?: boolean
}

export default function InputCheckbox({ label, checked, onChange, bold }: InputCheckboxProps) {
    const fontStyle = bold ? style.bold : style.label

    function changeHandler(event: any) {
        const checked = event?.target?.checked
        
        if (onChange !== undefined)
            onChange(checked)
    }

    return (
        <label className={`${style.checkbox} protected`}>
            <input type='checkbox' checked={checked} onChange={changeHandler} />
            <span className={style.checkmark} />
            <span className={fontStyle}>{label}</span>
        </label>
    )
}
