import React, { MutableRefObject, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IKeyword } from "../model/profile-keyword.model"
import style from "./keyword-tag.module.scss"

type KeywordTagProps = {
    keyword: IKeyword
    editingMode: boolean
    onDelete?: (keyword: IKeyword) => void
    onChange?: (keyword: IKeyword) => void
    ref?: MutableRefObject<undefined>
}

export default function KeywordTag({keyword, editingMode, onDelete, onChange, ref}: KeywordTagProps) {

    const navigate = useNavigate()
    const [width, setWidth] = useState<string>('');
    const [content, setContent] = useState<string>(keyword.word);

    useEffect(() => {
        setWidth(`${content.length+1}ch`)
    }, [content]);


    function onClickHandler() {
        if (editingMode !== true) {
            navigate(`/search?query=${keyword.word}`)
        }
    }
    return (
        <span className={editingMode === false ? style.tag_view : style.tag_edit} onClick={onClickHandler}>
            <span className={ editingMode === true ? style.close : 'none'} onClick={() => onDelete(keyword)}></span>
            {keyword.word}
        </span>
    )

}