import React from 'react'
import Panel from '../../../../theme/component/panel/panel.component'



export default function DevPanel() {
    return (
        <section id="placeholder" className="mt4">
            <Panel title='Some Awesome Title'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Error quos vitae labore perspiciatis, aspernatur quibusdam 
                non voluptatem magni culpa at, molestias laboriosam delectus! 
                Asperiores ullam esse, iure perspiciatis sit itaque!
            </Panel>
        </section>
    )
}