import {INotification} from "./model/notification.model";

export class NotificationService {

    private static subscriptions: Function[] = []

    static displayNotification(notification: INotification) {
        this.subscriptions.forEach(subscription => subscription(notification))
    }

    static subscribe(callback: (notification: INotification) => void) {
        this.subscriptions.push(callback)
    }

    static unsubscribe(callback: (notification: INotification) => void) {
        this.subscriptions = this.subscriptions.filter(subscription => subscription !== callback)
    }
}