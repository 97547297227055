import React from "react";
import Panel from "../../../../../../theme/component/panel/panel.component";
import style from "./index.module.scss"
import {ISupportTicketResponse} from "../../../../../../service/support/model/SupportTicketResponse";
import {TicketOverview} from "./component/ticket-overview";
import InputDropdown from "../../../../../../theme/component/input-dropdown/input-dropdown.component";
import DropdownOption from "../../../../../../theme/component/input-dropdown/models/dropdown-option.model";
type TicketListProps = {
    tickets: ISupportTicketResponse[]
    onSelect: (ticket: ISupportTicketResponse) => void
    selectedTicket: ISupportTicketResponse
    onSelectFilterOption: (option: DropdownOption) => void
    options: DropdownOption[]
    selectedFilterOptionIndex: number
}

export function TicketList({ tickets, onSelect, selectedTicket, onSelectFilterOption, options, selectedFilterOptionIndex} : TicketListProps) {

    return <Panel className={style.container}>
        <div className={style.filter_container}><InputDropdown initialIndex={selectedFilterOptionIndex} onChange={onSelectFilterOption} options={options}/></div>

        {tickets?.map(ticket => <TicketOverview selected={selectedTicket?.ref === ticket.ref} onSelect={onSelect} key={ticket.ref} ticket={ticket}></TicketOverview>)}
    </Panel>
}