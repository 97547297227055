import React from 'react'
import style from './index.module.scss'
import {v4 as uuid} from 'uuid'



type LocationHintsProps = {
    hints: ILocation[]
    open: boolean,
    selectedIndex?: number,
    onClick: (hint: ILocation) => void
}

export default function LocationHints({hints, open, onClick, selectedIndex = -1}: LocationHintsProps) {



  if (!open || hints.length === 0) {
    return <></>
  }



  return <div className={style.dropdown}>
    {hints?.map((hint, index) =>  
      <div key={uuid()} className={selectedIndex === index ? style.selected_hint: style.hint} onClick={() => onClick(hint)}>
          <span className={style.icon} />
          <span className={style.hint_text}>{hint.display}</span>
      </div>
    )}
</div>
}