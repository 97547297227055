import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface ShowBusinessContextType {
    showBusiness: boolean;
    setShowBusiness: (showBusiness: boolean) => void;
}

// Define the provider props type
interface ShowBusinessProviderProps {
    children: ReactNode;
}

// Create context with an initial value that matches the context type
const ShowBusinessContext = createContext<ShowBusinessContextType>({
    showBusiness: true,
    setShowBusiness: () => { },
});

// Provider component with typed props
export const ShowBusinessProvider: React.FC<ShowBusinessProviderProps> = ({ children }) => {
    const [showBusiness, setShowBusiness] = useState<boolean>(true);

    return (
        <ShowBusinessContext.Provider value={{ showBusiness, setShowBusiness }}>
            {children}
        </ShowBusinessContext.Provider>
    );
};

// Custom hook for consuming context
export const useShowBusiness = () => useContext(ShowBusinessContext);
