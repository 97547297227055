import React, { useEffect, useState } from 'react';
import Panel from "../../../theme/component/panel/panel.component";
import { UserMetricsApi } from "../../../api/user-metrics/user-metrics-api";
import { IUserMetrics } from "../../../api/user-metrics/model/IUserMetrics";
import ProfileGrid from "../../../theme/component/profile-grid/profile-grid.component";
import Loading from "../../../theme/layout/loading/loading.component";
import style from "./index.module.scss";
import {useNavigate} from "react-router-dom";

export default function UserStatistics() {
    const [metrics, setMetrics] = useState<IUserMetrics>(undefined);
    const navigate = useNavigate()

    useEffect(() => {
        loadMetrics();
    }, []);

    async function loadMetrics() {
        try {
            const response = await UserMetricsApi.getMetrics();
            setMetrics(response.data);
            console.log(response);
        } catch (error) {
            console.log(error.status)
            console.log('Loading user-metrics failed.', error);
            navigate('/')
        }
    }

    if (!metrics) {
        return <Loading />;
    }

    function LastSignUps() {
        return (
            <div className={style.last_signups}>
                <h3 className={style.header}>Last SignUps</h3>
                <br/>
                <ProfileGrid profiles={metrics.lastSignUps} />
            </div>
        );
    }

    function BiggestProfiles() {
        return (
            <div className={style.last_signups}>
                <h3 className={style.header}>Biggeset Reach</h3>
                <br/>
                <ProfileGrid profiles={metrics.biggestProfiles} />
            </div>
        );
    }

    return (
        <section className={style.section}>
            <Panel className={style.panel}>
                <section className={style.header}>
                    <h1>User Statistics</h1>
                    <h3>User Metrics for Analytics</h3>
                </section>
                <section className={style.metrics}>
                    <div className={style.metric}>Total User Count:<br/><span className={style.number}>{metrics.totalUser}</span></div>
                    <div className={style.metric}>Total Brand Count:<br/><span className={style.number}>{metrics.totalBrands}</span></div>
                    <div className={style.metric}>Total Influencer Count:<br/><span className={style.number}>{metrics.totalInfluencer}</span></div>
                    <div className={style.metric}>Total Followers:<br/><span className={style.number}>{metrics.totalFollower}</span></div>
                    <div className={style.metric}>Total Offers:<br/><span className={style.number}>{metrics.totalOffers}</span></div>
                    <div className={style.metric}>Avg Influencer Age:<br/><span className={style.number}>{metrics.averageAge}</span></div>


                </section>
                <BiggestProfiles/>
                <LastSignUps />
            </Panel>
        </section>
    );
}
