import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MissingError from '../../components/app/component/error/missing/missing-error.component';
import ChatService from '../../service/chat/chat.service';
import ChatMessage from '../../service/chat/model/ChatMessage';
import ChatDetails from '../../service/inbox/model/ChatDetails';
import LoadingContent from '../../theme/layout/loading-content/loading-content.component';
import style from './index.module.scss';
import Chatbox from './component/chatbox';
import ConversationGrid from './component/conversation-grid';
import MessageGrid from './component/message-grid';
import Navbar from './component/navbar';
import { IWebMessage } from '../../service/websocket/model/web-message.interface';
import { WebsocketService} from '../../service/websocket/websocket.service';
import useQuery from "../../theme/hooks/use-query.component";
import { IOffer } from "../../service/marketplace/model/offer.model";
import marketplaceService from "../../service/marketplace/marketplace.service";
import InboxService from '../../service/inbox/inbox-client.service';
import Inbox from "../../service/inbox/model/Inbox";
import HasMessagesContext from "../../state/context/has-messages";
import {IWebTextMessage} from "../../service/websocket/model/web-text-message.interface";
import {MessageType} from "../../service/websocket/model/message-type.model";
import {text} from "stream/consumers";

export default function Chat() {
    const { username } = useParams();
    const navigate = useNavigate();
    const offerRef = useQuery('offer', undefined);

    const hasMessagesContext = useContext(HasMessagesContext);
    if (!hasMessagesContext) throw new Error("HasMessagesContext must be used within a HasMessagesContextProvider");
    const { fetchHasNewMessages } = hasMessagesContext;

    const [selectedChat, setSelectedChat] = useState<string>(username);
    const [disableSelection, setDisableSelection] = useState<boolean>(false);
    const [chatDetails, setChatDetails] = useState<ChatDetails | null>(null);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [inbox, setInbox] = useState<Inbox | null>(null);
    const [relatedOffer, setRelatedOffer] = useState<IOffer | undefined>(undefined);
    const [offset, setOffset] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const [lastLoadTime, setLastLoadTime] = useState(0);
    const [canLoadMore, setCanLoadMore] = useState(true);

    const selectedChatRef = useRef(selectedChat);
    const inboxRef = useRef(inbox)

    const onMessageReceivedChatHandlerCallback = useCallback((message: IWebMessage) => {
        onMessageReceivedHandler(message, inboxRef.current, selectedChatRef.current);
    }, []);

    useEffect(() => {
        selectedChatRef.current = selectedChat;
    }, [selectedChat]);

    useEffect(() => {
        inboxRef.current = inbox;
    }, [inbox]);


    const chatboxRef = useRef(null);

    useEffect(() => {

        fetchChatDetails(username);
    }, [username]);

    useEffect(() => {
        WebsocketService.subscribe(onMessageReceivedChatHandlerCallback);
        return () => {
            WebsocketService.unsubscribe(onMessageReceivedChatHandlerCallback);
            fetchHasNewMessages()
        };
    }, [onMessageReceivedChatHandlerCallback]);

    async function fetchChatDetails(chat: string) {
        try {

            setMessages([]);
            setOffset(0);
            setCanLoadMore(true);

            setSelectedChat(chat);
            setDisableSelection(true);

            const chatDetailsPromise = ChatService.getMessageDetails(chat);
            const messagesPromise = ChatService.getMessages(chat, 100, 0);
            const offerPromise = offerRef ? marketplaceService.getOffer(offerRef) : Promise.resolve(undefined as IOffer);
            const inboxPromise = InboxService.getInbox()
            const [chatDetails, messages, offer, inbox] = await Promise.all([chatDetailsPromise, messagesPromise, offerPromise, inboxPromise]);

            const index = inbox.active.findIndex(conversation => conversation.chat === chat)
            if (index !== -1)
                inbox.active[index].unread = 0

            if (!chatDetails.latestMessage) {
                inbox.active.push(chatDetails)
            }


            setInbox(inbox)
            setChatDetails(chatDetails);
            setMessages(messages.reverse());
            setRelatedOffer(offer?.owned ? offer : undefined);


            setLoading(false);
            setDisableSelection(false);

            setTimeout(() => {
                if (chatboxRef.current) {
                    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
                }
            }, 100);

        } catch (error) {
            setLoading(false);
        }
    }


    const loadMoreMessages = useCallback(async () => {
        const now = Date.now();
        if (loadingMore || now - lastLoadTime < 1000 || !canLoadMore) return;

        setLoadingMore(true);
        setLastLoadTime(now);

        try {
            const chat = chatboxRef.current;
            const previousScrollHeight = chat.scrollHeight;
            const previousScrollTop = chat.scrollTop;

            const newMessages = await ChatService.getMessages(selectedChat, 100, offset + 1);
            newMessages.reverse();

            setMessages((prevMessages) => [...newMessages, ...prevMessages]);
            setOffset((prevOffset) => prevOffset + 1);

            if (newMessages.length < 100) {
                setCanLoadMore(false);
            }

            setTimeout(() => {
                const newScrollHeight = chat.scrollHeight;
                chat.scrollTop = newScrollHeight - previousScrollHeight + previousScrollTop;
            }, 100);
        } catch (error) {
        } finally {
            setLoadingMore(false);
        }
    }, [loadingMore, lastLoadTime, offset, selectedChat, canLoadMore]);

    if (loading) {
        return (
            <>
                <Navbar username={selectedChat} displayname={selectedChat} avatarUrl={null} premium={false} />
                <main>
                    <section className={style.chat_loading}>
                        <LoadingContent />
                    </section>
                </main>
            </>
        );
    }

    if (chatDetails === null) {
        return (
            <>
                <Navbar username={selectedChat} displayname={selectedChat} avatarUrl={null} premium={false} />
                <main>
                    <section className={style.chat_not_found}>
                        <section className='text-center'>
                            <span className='mt4'>
                                <MissingError />
                            </span>
                        </section>
                    </section>
                </main>
            </>
        );
    }

    return (
        <>
            <Navbar username={username} displayname={chatDetails.chatter.displayname} avatarUrl={chatDetails.chatter.avatar} premium={chatDetails.chatter.premium} />
            <main className={style.chat_main}>
                <section className={style.section_container}>
                    <span className={style.inbox_container}>
                        <ConversationGrid inbox={inbox} selectedConversation={chatDetails} onSelect={onSelectHandler} disabled={disableSelection} />
                    </span>
                    <span className={style.chat_container} ref={chatboxRef}>
                        <MessageGrid
                            smaller={relatedOffer !== undefined}
                            messages={messages}
                            chatDetails={chatDetails}
                            loadMoreMessages={loadMoreMessages}
                            loadingMore={loadingMore}
                        />
                        <Chatbox offer={relatedOffer} username={username} onMessageSend={onMessageSendHandler} />
                    </span>
                </section>
            </main>
        </>
    );

    function onMessageSendHandler(message: ChatMessage) {
        setMessages(messages => [...messages, message]);
        setRelatedOffer(undefined);
        updateLatestMessage(message);
    }

    function onMessageReceivedHandler(message: IWebMessage, inbox: Inbox, currentSelectedChat: string) {
        if (message.type !== MessageType.text) {
            return;
        }

        const webMessage = message as IWebMessage;
        const textMessage = webMessage.content as IWebTextMessage;
        textMessage.read = false


        //add Message to conversation in Conversations
        const newInbox: Inbox = {...inbox}


        const index = newInbox.active.findIndex(conversation => conversation.chat === textMessage.senderUsername)

        if (index === -1) {
            fetchConversations()
            return;
        }


        if (currentSelectedChat === textMessage.senderUsername) {
            //add message to current chat
            textMessage.read = true
            setMessages(messages => [...messages, textMessage]);
            ChatService.markRead(textMessage.senderUsername)
        } else {
            newInbox.active[index].unread++
        }
        newInbox.active[index].latestMessage = textMessage
        setInbox(newInbox)
    }

    async function fetchConversations() {
        try {
            setInbox(await InboxService.getInbox())
        } catch (error) {
        }
    }

    function updateLatestMessage(message: ChatMessage) {
        const updatedChatDetails = Object.create(chatDetails);
        updatedChatDetails.latestMessage = message;
        setChatDetails(updatedChatDetails);
    }

    async function onSelectHandler(chat: ChatDetails) {
        navigate(`/chat/${chat.chat}`);
    }
}
