import Overlay from "../../../../../../../../theme/component/overlay/overlay.component";
import React from "react";
import InputButton from "../../../../../../../../theme/component/input-button";
import {ButtonColor} from "../../../../../../../../theme/component/input-button/models/button-color.model";


type DeleteOfferDisclaimer = {
    onDelete?: () => void
    onClose?: () => void
    open: boolean
}

export function DeleteOfferDisclaimer({onDelete, onClose, open}: DeleteOfferDisclaimer) {
    return (
        <Overlay title='Edit Link' open={open} onClose={onClose} >
            <div>Are you sure you want to delete yor offer? <br/> You can't undo this action.</div>
            <div className="mt2">
                <span><InputButton label='Delete' color={ButtonColor.Failure} onClick={onDelete} /></span>
                <span className='float-right'><InputButton label='Cancel' onClick={onClose}  /></span>
            </div>
        </Overlay>
    )
}