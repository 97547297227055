
import React, { useState } from 'react'
import DatePicker from '../../../../theme/component/date-picker/date-picker.component'

export default function DevDatePicker() {

    const [date, setDate] = useState<string>('-')

    return (
        <div>
            <DatePicker onChange={setDate} />
            <h2>{date}</h2>
        </div>
    )
}