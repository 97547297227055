import { IOffer } from "../../../../../../service/marketplace/model/offer.model";
import style from "./index.module.scss";
import React from "react";
import {
    IKeyword
} from "../../../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import { Tag } from "../../../../../../theme/component/tag/tag.component";
import { useNavigate } from "react-router-dom";
import {PremiumBadge} from "../../../../../../theme/component/premium-badge";
import ProfileView from "../../../../../../service/profile/model/profile-view.model";
import config from '../../../../../../service/config/config'
import KeywordTag from "../../../../../../theme/component/profile-tile/keyword-tag.component";
import {Blurhash} from "react-blurhash";
import CountdownTimer from "../../../../../../theme/component/countdown";
import InputButton from "../../../../../../theme/component/input-button";
const defaultAvatar = require('../../../../../../theme/resource/image/default/avatar_default.png')


type OfferHeaderProps = {
    offer: IOffer,
}
export function OfferHeader({ offer }: OfferHeaderProps) {
    const navigate = useNavigate()


    function Author({ profile }: { profile: ProfileView }) {
        return <span className={style.author} onClick={() => navigate(`/profile/${profile.username}`)}>
            {profile.displayname}
            <PremiumBadge premium={profile.premium} className={style.premium}/>
        </span>
    }

    function HiddenAuthor({}: {}) {
        return <span className={style.author}>
            Hidden
        </span>
    }


    function Icon({ name, icon, show }: { name: string, icon: string, show: boolean }) {
        if (show) {
            return (
                <div className={style.tooltip}>
                    <div  className={`pre-icon ${style.icon} ${style[icon]}`}></div>
                    <div className={style.info_item_name}>{name}</div>
                </div>
            );
        } else {
            return <div>
            </div>;
        }
    }

    function Location({ name, icon, show }: { name: string, icon: string, show: boolean }) {
        if (show) {
            return (
                <div className='tooltip'>
                    <div id='bookmark' className={`pre-icon ${style.location} ${style[icon]}`}>
                        {name}</div>
                    <span className='tooltiptext'>{name}</span>
                </div>
            )
        } else {
            return <div className={`${style.location} ${style[icon]}`}>
            </div>;
        }

    }


    function KeywordBar({keywords}: { keywords: IKeyword[] }) {
        // Sort keywords by the length of the word property in ascending order
        const sortedKeywords = keywords.sort((a, b) => {
            if (a.word && b.word) return a.word.length - b.word.length;
            return 0; // This handles cases where the word may be undefined
        });

        return <div className={style.keywords}>
            {sortedKeywords.slice(0, 6).map((keyword, index) =>
                <div  key={keyword.ref}><KeywordTag keyword={keyword} /></div>
            )}
        </div>
    }

    function Name({ offer }: { offer: IOffer }) {
        const hasApplied = offer?.applied;
        const hasOwned = offer?.owned;

        let nameClass = '';
        if (hasOwned) {
            nameClass = style.nameWithOwned;
        } else if (hasApplied) {
            nameClass = style.nameWithApplied;
        }

        return (
            <div className={style.container}>
                <span className={`${style.name} ${nameClass}`}>{offer.name}</span>
                {offer?.applied ? <Tag color={'green'} label={'Applied'} /> : null}
                {offer?.owned ? <Tag color={'blue'} label={'Owned'} /> : null}
            </div>
        );
    }

    function Image({offer}: {offer: IOffer}) {
        const avatarUrl = offer.image ? `/api/image?ref=${offer.image}` : offer.avatarUrl;
        const avatar = avatarUrl ? config.host + avatarUrl : defaultAvatar;
        return <div className={style.avatar_container}>
            <img className={style.avatar} src={avatar} alt='Profile Avatar'/>
        </div>
    }

    function HiddenOfferImage({ offer }: { offer: IOffer }) {
        return (
            <div className={style.avatar_container}>
                <div className={style.avatar}>
                     <Blurhash
                        hash={offer.image} // Verwendet den blurhash aus offer.image
                        width={'100%'} // Passe die Breite nach Bedarf an
                        height={'100%'} // Passe die Höhe nach Bedarf an
                        resolutionX={64}
                        resolutionY={64}
                        punch={1}
                        className={style.avatar}
                    />
                    <span className={style.premium_lock_container}>
                        <div className={style.premium_lock}/>
                        <CountdownTimer targetDate={offer.premiumOnlyUntil}/>
                        <div>Premium only</div>
                    </span>
                </div>
            </div>
        );
    }

    function onAvatarClick() {
        navigate(`/profile/${offer.author.username}`)
    }


    return <div className={style.header}>
        { offer.premiumOnlyUntil ? <HiddenOfferImage offer={offer}/> : <Image offer={offer}/> }
            <div className={style.info_bar}>
            <Name offer={offer}/>
                <div className={style.info_item}>
                    { offer.premiumOnlyUntil ? <HiddenAuthor/> : <Author profile={offer.author}/>}
                </div>
                <div className={style.info_item_labels}>
                    <Icon icon={'product_placement'} name={'Placement'}
                          show={offer?.collaborations?.productPlacement}/>
                    <Icon icon={'free_placement'} name={'Free Product'}
                          show={offer?.collaborations?.freeProductPlacement}/>
                    <Icon icon={'influencer'} name={'Collaborations'}
                          show={offer?.collaborations?.influencerCollaboration}/>
                </div>
                <div className={style.info_item}>
                    <KeywordBar keywords={offer.keywords}/>
                </div>
                {offer.premiumOnlyUntil ?
                    <div className={style.get_premium_button}>
                        <InputButton label={'Get Premium'} className={style.premium_button}/>
                    </div> :
                    <div className={style.description_short}>
                        {offer.description}
                    </div>
                }
            </div>
    </div>
}

