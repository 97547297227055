import React from 'react';

type SvgProps = {
    color?: string;
    size?: number;
};

const InfluencerIcon: React.FC<SvgProps> = ({ color = 'rgb(0,206,0)', size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
        <defs>
            <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(3.5708e-15,58.1148,-58.3156,3.5585e-15,32,2.16743)">
                <stop offset="0" style={{ stopColor: 'rgb(0,191,255)', stopOpacity: 1 }}/>
                <stop offset="0.89" style={{ stopColor: 'rgb(0,235,81)', stopOpacity: 1 }}/>
                <stop offset="0.93" style={{ stopColor: 'rgb(0,252,12)', stopOpacity: 1 }}/>
                <stop offset="1" style={{ stopColor: 'rgb(0,255,0)', stopOpacity: 1 }}/>
            </linearGradient>
            <linearGradient id="_Linear2" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(4.74987e-15,63.6127,-61.8269,3.27652e-15,31.9164,-0.748796)">
                <stop offset="0" style={{ stopColor: 'rgb(0,191,255)', stopOpacity: 1 }}/>
                <stop offset="0.62" style={{ stopColor: 'rgb(0,202,213)', stopOpacity: 1 }}/>
                <stop offset="0.82" style={{ stopColor: 'rgb(0,227,111)', stopOpacity: 1 }}/>
                <stop offset="1" style={{ stopColor: 'rgb(0,255,0)', stopOpacity: 1 }}/>
            </linearGradient>
        </defs>
        <rect id="icon" x="0" y="0" width="64" height="64" style={{ fill: 'none' }}/>
        <path
            d="M22.072,60.282C10.387,56.194 2,45.098 2,32.064C2,15.564 15.443,2.167 32,2.167C48.557,2.167 62,15.564 62,32.064C62,45.096 53.615,56.191 41.93,60.281L41.93,36.164C41.93,33.434 39.706,31.217 36.966,31.217L27.037,31.217C24.297,31.217 22.072,33.434 22.072,36.164L22.072,60.282ZM32.394,12.162C36.156,12.162 39.209,15.205 39.209,18.953C39.209,22.701 36.156,25.745 32.394,25.745C28.633,25.745 25.579,22.701 25.579,18.953C25.579,15.205 28.633,12.162 32.394,12.162Z"
            style={{ fill: color || 'url(#_Linear1)' }}
        />
        <path
            d="M31.916,2.167C48.428,2.167 61.833,15.572 61.833,32.084C61.833,48.595 48.428,62 31.916,62C15.405,62 2,48.595 2,32.084C2,15.572 15.405,2.167 31.916,2.167ZM31.916,6.365C46.111,6.365 57.635,17.889 57.635,32.084C57.635,46.278 46.111,57.802 31.916,57.802C17.722,57.802 6.198,46.278 6.198,32.084C6.198,17.889 17.722,6.365 31.916,6.365Z"
            style={{ fill: color || 'url(#_Linear2)' }}
        />
    </svg>
);

export default InfluencerIcon;
