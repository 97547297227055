import React from 'react'
import InputButton from '../../../../theme/component/input-button'
import {NotificationService} from "../../../../service/notification/notification.service";
import {NotificationType} from "../../../../service/notification/model/notification-type.enum";
import {DirectMessageData} from "../../../../service/notification/model/direct-message.model";

export default function DevNotifications() {

    function sendNotification() {
        NotificationService.displayNotification({
            message: 'This is a test notification, this notification is longer than the other notifications to check if the notification is displayed correctly when the message is longer than the other messages.',
            type: NotificationType.success,
            duration: 5
        })

        NotificationService.displayNotification({
            message: 'This is a test notification',
            type: NotificationType.info,
            duration: 6
        })

        NotificationService.displayNotification({
            message: 'This is a test notification',
            type: NotificationType.warning,
            duration: 7
        })

        NotificationService.displayNotification({
            message: 'This is a test notification',
            type: NotificationType.failure,
            duration: 8
        })

        NotificationService.displayNotification({
            message: 'This is a test notification',
            type: NotificationType.directMessage,
            duration: 9,
            data: {
                senderUsername: 'superkevin'
            } as DirectMessageData
        })
    }

    return <section>
        <br/>
        <h1>Notifications</h1>
        <br/>
        <InputButton label={'Send Notification'} onClick={sendNotification} />
    </section>
}