import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cookieService from '../../../service/cookie/cookie.service'
import SessionDetails from '../../../service/session/model/session-details'
import InputButton from '../../component/input-button'
import { ButtonColor } from '../../component/input-button/models/button-color.model'

import style from './cookie-banner.module.scss'
import {useTranslation} from "react-i18next";

export default function CookieBanner() {
    const [showCookiebanner, setShowCookiebanner] = useState<boolean>(!cookieService.getAccepted())
    const {t} = useTranslation()

    useEffect(() => {
        document.addEventListener('session', onSessionUpdate)
        
        return () => document.removeEventListener('session', onSessionUpdate)
    }, [])
    
    function onSessionUpdate(event: any) {
        const session = event?.detail as SessionDetails

        setShowCookiebanner(!session.authenticated)
    }

    function onAcceptHandler() {
        cookieService.setAccepted()
        setShowCookiebanner(false)
    }
    
    if (!showCookiebanner)
        return <></>

    return (
        <section className={style.container}>
            <div className={style.panel}>
                <span className={style.description}>
                    {t('cookie-banner.description')}
                     <Link to={'/privacy'}>{t('cookie-banner.link')}</Link>
                    {t('cookie-banner.houserules')}
                </span>
                <span className={style.accept_button_container}>
                    <InputButton label={t('cookie-banner.button')} onClick={onAcceptHandler} color={ButtonColor.Neutral} padding={style.accept_button} />
                </span>
            </div>
        </section>
    )
}
