import React, {useEffect, useState} from 'react'
import ImageContent from '../../../../theme/component/image-content/image-content.component'

import style from './dev-image-loading.module.scss'
import {ExploreService} from '../../../../service/explore/explore.service';

export default function DevImageLoading() {

    interface IX {
        url: string
        preview: string
    }

    const [images, setImages] = useState<IX[]>([])

    useEffect(() => {
        ExploreService
            .getExplore()
            .then(res => {
                const ix = res.profiles.map(profile => {
                    return {
                        url: profile.bannerUrl,
                        preview: profile.bannerPreview
                    }
                })
                setImages(ix)
            })
    }, [])

    const rendered = images.map((image, index) => {
        return (
            <div key={index}>
                <ImageContent className={style.banner} name='Avatar' url={image.url} preview={image.preview} height='30rem' width='90rem' />
            </div>
        )
    })


    return (
        <>
            {/* <section> */}
            {/*     <ImageContent className={style.banner} name='Avatar' url={'http://localhost:25566/api/image?ref=6a4ff8b2-3ac4-45cf-afcb-47723f905ab4'} preview={'UQEzo_X9%%%g~XWXIUo2t.RkITWUr=ogNFWA'} /> */}
            {/* </section> */}
            {/* <section> */}
            {/*     <ImageContentLegacy className={style.avatar} src={'http://localhost/api/image?ref=d1ca9291-7950-4bc9-a466-56c36a1928b3'} alt={'image'} /> */}
            {/* </section> */}
            <section>
                {rendered}
            </section>
        </>
    )
}
