import React, {useEffect, useRef, useState} from "react";
import {IOffer} from "../../../../../../service/marketplace/model/offer.model";
import style from '../offer/index.module.scss'
import offerStyle from './index.module.scss'
import {useNavigate} from "react-router-dom";
import marketplaceService from "../../../../../../service/marketplace/marketplace.service";
import {OfferHeader} from "./component/header";
import {Bookmark} from "../../../../../../theme/component/bookmark";
import {NotificationService} from "../../../../../../service/notification/notification.service";
import {NotificationType} from "../../../../../../service/notification/model/notification-type.enum";
import {DeleteOfferDisclaimer} from "./component/delete-disclaimer";
import DownloadComponent from "../../../offer/component/offer-downloader";


type OfferProps = {
  offer: IOffer
  onEdit: () => void
}
export function Offer({ offer, onEdit }: OfferProps) {
  const navigate = useNavigate()

  const [bookmarked, setBookmarked] = useState<boolean>(offer?.bookmarked);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false)
    const downloadRef = useRef(null);

    const handleDownload = () => {
        if (downloadRef.current) {
            downloadRef.current.downloadAsImage();
        }
    };

  useEffect(() => {
    setBookmarked(offer.bookmarked)
  }, [offer]);

  if (offer === undefined) {
    return <></>;
  }

  function Footbar({ date }: { date: string }) {


    return <div className={style.footbar}>
      <span className={style.date}>{date}</span>
    </div>
  }

  async function deleteOffer() {
    try {
      await marketplaceService.deleteOffer(offer.ref)
      navigate('/marketplace')
    } catch (error) {
        NotificationService.displayNotification({
            message: "Could not delete Offer", type: NotificationType.failure
        })
    }
  }


  function Menu() {
    if (offer?.owned) {
      return <>
          <span className={style.menu}>
              <span onClick={() => setShowDeletePopup(true)} className={offerStyle.delete_icon}/>
            <span onClick={onEdit} className={offerStyle.edit_icon}/>
            <span onClick={handleDownload} className={offerStyle.download_icon}/>
            <DownloadComponent offer={offer} ref={downloadRef}/>
          </span>
      </>
    } else {
        return <span onClick={bookmarkOffer} className={style.bookmark}><Bookmark enabled={bookmarked} /></span>
      //return <span id={'heart'} onClick={likeOffer} className={`${style.heart} ${(bookmarked ? style.heart_bookmarked : style.heart)}` } />
    }
  }

  async function bookmarkOffer() {
    try {
      await marketplaceService.bookmarkOffer(offer)
      setBookmarked(!bookmarked)
    } catch (error) {
      //TODO: Meldung wenn like failed
    }
  }



  return (
    <>
        <DeleteOfferDisclaimer open={showDeletePopup} onDelete={deleteOffer} onClose={() => setShowDeletePopup(false)}/>
      <span className={style.panel}>
        <OfferHeader offer={offer} />
        <Menu />

        <Footbar date={offer.created} />
      </span>
    </>
  );
}



