import React from 'react'
import SlideShow from '../../../../theme/component/slide-show/slide-show.component'

export default function DevSlideShow() {
  return (
    <section>
        <SlideShow slides={[]} />
    </section>
  )
}
