import React from "react";
import TypeFilter from "./type-filter/type-filter.component";
import PlatformFilter from "./platform-filter/platform-filter.component";
import CollaborationFilter from "./collaboration-filter/collaboration-filter.component";
import { LocationFilter } from "./location-filter";
import style from "./filter-bar.module.scss";

type FilterBarProps = {
    onChangePlatform: (value: string) => void,
    onChangeType: (value: string) => void,
    onChangeCollaboration: (value: string) => void
    onChangeLocation?: (location: ILocation) => void
}

export default function FilterBar({ onChangePlatform, onChangeType, onChangeCollaboration, onChangeLocation }: FilterBarProps) {

    return (
        <span className={style.filterBar}>
            <span ><TypeFilter onChange={onChangeType} /></span>
            <span ><PlatformFilter onChange={onChangePlatform} /></span>
            <span ><CollaborationFilter onChange={onChangeCollaboration} /></span>
            <span className={style.locationFilter}><LocationFilter onChange={onChangeLocation} /></span>
        </span>
    )
}