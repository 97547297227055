import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import inboxService from '../../service/inbox/inbox-client.service'
import ChatDetails from '../../service/inbox/model/ChatDetails'
import LoadingContent from '../../theme/layout/loading-content/loading-content.component'
import Conversation from './component/conversation/conversation.component'
import InputButton from "../../theme/component/input-button";
import {useNavigate} from "react-router-dom";
import styles from './index.module.scss'
import Panel from "../../theme/component/panel/panel.component";
import {ButtonColor} from "../../theme/component/input-button/models/button-color.model";
import {useTranslation} from "react-i18next";
import {IWebMessage} from "../../service/websocket/model/web-message.interface";
import {MessageType} from "../../service/websocket/model/message-type.model";
import {IWebTextMessage} from "../../service/websocket/model/web-text-message.interface";
import {WebsocketService} from "../../service/websocket/websocket.service";
import HasMessagesContext from "../../state/context/has-messages";

export default function Inbox() {
    const [conversations, setConversations] = useState<ChatDetails[]>([])
    const [inqueries, setInqueries] = useState<ChatDetails[]>([])
    const [archivedConversations, setArchivedConversations] = useState<ChatDetails[]>([])
    const [deletedConversations, setDeletedConversations] = useState<ChatDetails[]>([])
    const [initalized, setInitialized] = useState<boolean>(false)

    const navigate = useNavigate()
    const { t } = useTranslation()

    const conversationsRef = useRef(conversations)

    const hasMessagesContext = useContext(HasMessagesContext);
    if (!hasMessagesContext) throw new Error("HasMessagesContext must be used within a HasMessagesContextProvider");
    const { fetchHasNewMessages } = hasMessagesContext;




    const onMessageReceivedInboxHandlerCallback = useCallback((message: IWebMessage) => {
        onMessageReceivedHandler(message, conversationsRef.current);
    }, []);

    useEffect(() => {
        WebsocketService.subscribe(onMessageReceivedInboxHandlerCallback);
        return () => {
            WebsocketService.unsubscribe(onMessageReceivedInboxHandlerCallback);
        };
    }, [onMessageReceivedInboxHandlerCallback]);

    function onMessageReceivedHandler(message: IWebMessage, chatDetails: ChatDetails[]) {
        if (message.type !== MessageType.text) {
            return;
        }

        const webMessage = message as IWebMessage;
        const textMessage = webMessage.content as IWebTextMessage;

        const index = conversations.findIndex((conversation) => conversation.chat === textMessage.senderUsername)

        if (index === -1) {
            fetchInbox()
            return;
        } else {
            conversations[index].latestMessage = textMessage
            conversations[index].unread++
            setConversations([...conversations])
        }

    }

    useEffect(() => {
        conversationsRef.current = conversations
    }, [conversations]);

    useEffect(() => {
        fetchInbox()
    }, [])

    async function onArchive(chat: ChatDetails) {
        if (chat.archived === true) {
            const updatedActiveConersations = conversations.filter((conversation) => conversation.ref !== chat.ref)
            setConversations(updatedActiveConersations)

            let updatedArchivedConversations = archivedConversations
            updatedArchivedConversations.push(chat)
            updatedArchivedConversations = updatedArchivedConversations.sort(inboxService.isLatestMessage)
            setArchivedConversations(updatedArchivedConversations)

        } else {
            const updatedArchivedConversations = archivedConversations.filter((conversation) => conversation.ref !== chat.ref)
            setArchivedConversations(updatedArchivedConversations)

            let updatedActiveConersations = conversations
            updatedActiveConersations.push(chat)
            updatedActiveConersations = updatedActiveConersations.sort(inboxService.isLatestMessage)
            setConversations(updatedActiveConersations)
        }
    }

    async function onBlock(chat: ChatDetails) {
        const updatedConversations = conversations.filter((chatDetails) => chatDetails.ref !== chat.ref)
        fetchHasNewMessages()
        setConversations(updatedConversations)
    }

    async function onDelete(chat: ChatDetails) {
            const updatedActiveConversations = conversations.filter((conversation) => conversation.ref !== chat.ref)
            setConversations(updatedActiveConversations)
    }

    async function fetchInbox() {
        try {
            const inbox = await inboxService.getInbox()

            setConversations(inbox.active)
            setInqueries(inbox.inquiries)
            setArchivedConversations(inbox.archived)
        } catch (error) {
            setConversations([])
            setInqueries([])
            setArchivedConversations([])
        }
        setInitialized(true)
    }

    function Conversations({chatDetails, title}: { chatDetails: ChatDetails[], title: string }) {
        const conversations = chatDetails.map((chatDetail) => <Conversation chat={chatDetail} onArchive={onArchive}
                                                                            key={chatDetail.ref}
                                                                            onBlock={onBlock}
                                                                            onDelete={onDelete}/>)

        return (
            <div>
                <h2 className={chatDetails.length > 0 ? "mb1" : "mb1 none"}>{title}</h2>
                {conversations}
            </div>
        )
    }

    if (initalized === false)
        return <LoadingContent/>

    // TODO: <h2>{inqueries.length} New Requests</h2>


    if (conversations === undefined || (conversations.length === 0 && archivedConversations?.length === 0)) {
        return <Panel className={styles.container}>
            <h2>{t('chats.inbox_empty_title')}</h2>
            <div style={{color: 'var(--g-label-color)'}}>{t('chats.inbox_empty_call_to_action')}</div>
            <div className={styles.orange_cat} />
            <InputButton label={t('chats.inbox_empty_explore_action')} onClick={() => navigate('/explore')} fill={true} color={ButtonColor.Primary}/>
        </Panel>
    }

    return (
        <section>
            <Conversations title={t('chats.inbox')} chatDetails={conversations}/>
            {/*<Conversations title={"Archived"} chatDetails={archivedConversations}/>*/}
        </section>
    )
}