import React, { useState } from 'react'
import InputDropdown from '../../../../theme/component/input-dropdown/input-dropdown.component'
import OptionModel from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import Panel from '../../../../theme/component/panel/panel.component'

import style from './dev-input-dropdown.module.scss'

export default function DevInputDropdown() {
    const [value, setValue] = useState<OptionModel>()

    const options: OptionModel[] = [
        { label: 'Instagram', value: 'instragram' },
        { label: 'Snapchat', value: 'snapchat' },
        { label: 'Spotify', value: 'spotify' },
        { label: 'Twitch', value: 'twitch' },
        { label: 'Twitter', value: 'twitter' },
        { label: 'YouTube', value: 'youtube' }
    ]

    return (
        <section>
            <Panel className='l-6'>
                <h1>Demo of Dropdown</h1>
                <br />
                <div className={`${style.ui} float-left`}><InputDropdown placeholder='Select Network' options={options} onChange={setValue} /></div>
                <br />
                <br />
                <h2>{value?.label}</h2>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </Panel>
        </section>
    )
}
