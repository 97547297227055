import React from 'react'
import CustomError from '../../../app/component/error/custom/technical-error.component'
import MissingError from '../../../app/component/error/missing/missing-error.component'
import PermissionError from '../../../app/component/error/permission/permission-error.component'
import ProfileError from '../../../app/component/error/profile/profile-error.component'
import TechnicalError from '../../../app/component/error/technical/technical-error.component'
import VerificationError from '../../../app/component/error/verification/verification-error.component'

export default function DevErrors() {
    return (
        <section>
            <h1>Dev Errors</h1>
            <span className='ml2'><MissingError /></span>
            <span className='ml2'><PermissionError /></span>
            <span className='ml2'><ProfileError username={'kevin'} /></span>
            <span className='ml2 vertical-top'><TechnicalError /></span>
            <span className='ml2'><CustomError title='Cusom Error' reason='Custom Reason' /></span>
            <span className='ml2'><VerificationError /></span>
        </section>
    )
}
