import React from 'react'
import numberFormatService from '../../../../../../service/number-format.service'
import INetworkDetails from '../../../profile-networks/models/network-details.model'

import style from './follower-count.module.scss'

type FollowerCountProps = {
    networkDetails: INetworkDetails
}

export default function FollowerCount({ networkDetails }: FollowerCountProps) {
    if (networkDetails?.networks?.length === 0)
        return <></>

    const follower = numberFormatService.renderNumber(networkDetails?.totalFollower)

    return <span className={`${style.follower} pre-icon`}>{follower}</span>
}