import React from "react";
import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import HandshakeImage from '../../../../theme/resource/image/landing/handshake.png'
import {useTranslation} from "react-i18next";

export function HeroSection() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return <section className={style.section}>
        <span className={style.hero_call}>
            <h1 className={style.big_text}>{t('hero_section_headline_firstline')}<br />{t('hero_section_headline_secondline')}</h1>
            <div className={style.small_text}>{t('hero_section_subtitle')}</div>
            <span className={style.button_bar}>
                <span className={style.signup_button} onClick={() => navigate('/join')}>{t('hero_section_join')}</span>
                <span className={style.see_more_button} onClick={() => scrollToSection('about')}>{t('hero_section_see_more')}</span>
            </span>
        </span>
        <span className={style.header_images}>
            <span className={style.box_Section}>
                <div className={style.box} id={style.box1}>
                    <div className={style.icon_statistics}></div>
                    <span className={style.box_text}>{t('hero_section_header_one')}</span>
                </div>
                <div className={style.box} id={style.box2}>
                    <div className={style.icon_rank}></div>
                </div>
                <div className={style.box} id={style.box3}>
                    <div className={style.icon_community}></div>
                    <span className={style.box_text}>{t('hero_section_header_two')}</span>
                </div>
                <img className={style.HandshakeImage} src={HandshakeImage}/>
            </span>
        </span>
    </section >
}