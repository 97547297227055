import axios from 'axios'
import ChatDetails from '../inbox/model/ChatDetails'
import ChatMessage from './model/ChatMessage'

class ChatService {

    private root = '/chat'
    private client = axios

    async sendMessage(receiverUsername: string, content: string, offerRef?: string) : Promise<ChatMessage> {
        let response = await this.client.post(`${this.root}/message`, {
            chat: receiverUsername,
            content: content,
            offer: offerRef
        })

        return response.data
    }

    async getMessages(receiverUsername: string, size: number, offset: number, signal?: AbortSignal) {
        let response = await this.client.get(`${this.root}/message?chat=${receiverUsername}&size=${size}&offset=${offset}`, { signal });
        return response.data;
    }


    async getMessageDetails(receiverUsername: string, signal?: AbortSignal): Promise<ChatDetails> {
        let response = await this.client.get(`${this.root}/details?chat=${receiverUsername}`, { signal })

        return response.data
    }


    async markRead(receiverUsername: string): Promise<boolean> {
        try {
            await this.client.post(`${this.root}/read?chat=${receiverUsername}`)

            return true
        } catch (error) {
            return false
        }
    }

    async reportChat(chat: string, topic: string, issue: string, type: string) {
        await this.client.post(`${this.root}/report`, {chat, topic, issue, type})
    }
}

export default new ChatService()