import axios from 'axios'
import { ImageType } from './models/image-type.model'
import UploadDetails from './models/upload-details.model'

class ImageService {

    private root = '/image'
    private client = axios

    public async uploadImage(file: File, type: ImageType): Promise<UploadDetails> {
        const formData = new FormData()
        formData.append('image', file)

        const response = await this.client.post(`${this.root}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            params: { type }
        })

        return response?.data
    }

}

export default new ImageService()