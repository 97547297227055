import React, {useState} from "react";
import InputButton from "../../theme/component/input-button";
import Panel from "../../theme/component/panel/panel.component";

import style from "./index.module.scss"
import {PaymentPicker} from "../../theme/component/payment-picker";
import {BillingService} from "../../service/billing/billing.service";
import {useNavigate} from "react-router-dom";
import sessionService from "../../service/session/session.service";
import SessionService from "../../service/session/session.service";
import profileService from "../../service/profile/profile.service";
import {useTranslation} from "react-i18next";

export default function BoostPicker() {
    const [orderName, setOrderName] = useState<string>(undefined)
    const [orderId, setOrderId] = useState<string>(undefined)
    const boostCount = SessionService.getMeDetails().boosterCount

    const navigate = useNavigate()

    const {t} = useTranslation()

    if (orderId) {
        return <PaymentPicker name={orderName} createOrder={createOrder} onApprove={onApprove} />
    }

    async function createOrder() {
        try {
            return await BillingService.createOrder(orderId)
        } catch (error) {
            console.error(error);
        }
    }

    async function onApprove(data: any, actions: any) {
        try {
            await BillingService.approveOrder(data.orderID)
            navigate(`/profile/${sessionService.getUsername()}`)
        } catch (error) {
            console.log(error)
        }
    }

    function purchaseOffer(orderName: string, orderId: string) {
        setOrderName(orderName)
        setOrderId(orderId)
    }

    async function onBoost() {
        try {
            await profileService.boostProfile()
            await SessionService.validateMe()
            navigate(`/profile/${sessionService.getUsername()}`)

        } catch (error) {

        }
    }

    function BoostCount() {
        if (SessionService.getMeDetails().boostedUntil)
            return <></>

        return boostCount < 1 ? <></> :
            <div>
                <div className={style.boost_count}>You have {boostCount} boost{boostCount > 1 && 's'} left</div>
                <span onClick={onBoost} className={`${style.boost_button} protected`}><span className={style.boost_icon}/><span className={style.boost_button_text}>Boost Profile</span></span>
            </div>
    }

    function BoostChoice({icon, name, price, onPurchase}: {icon: string, price: string, name: string, onPurchase: () => void}) {
        return <span className={style.booster_item}>
        <div className={style[icon]} />
        <div className={style.name}>{name}</div>
        <div className={style.price}>{price}</div>
        <InputButton className={style.purchase_button} onClick={onPurchase} label={t('boost_picker.buy')}/>
    </span>

    }

    return <section>
        <Panel className={style.panel}>
            <div className={style.header}>{t("boost_picker.header")}</div>
            <div className={style.info}>{t("boost_picker.info_1")} <br/> {t("boost_picker.info_2")} </div>
            <div className={style.boost_count}><BoostCount/></div>
            <div className={style.choices_container}>
                <BoostChoice name={"1 Boost"} price={"0,99€"} icon={"boost_icon_1"} onPurchase={() => purchaseOffer('1 Boost', "Boost1")}/>
                <BoostChoice name={"5 Boosts"} price={"3,99€"} icon={"boost_icon_5"} onPurchase={() => purchaseOffer('5 Boost', "Boost5") }/>
                <BoostChoice name={"10 Boosts"} price={"6,99€"} icon={"boost_icon_10"} onPurchase={() => purchaseOffer('10 Boost', "Boost10") }/>
            </div>
        </Panel>
    </section>
}

