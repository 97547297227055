import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'

import style from './panel-verification-reminder.module.scss'
import { useTranslation } from 'react-i18next'

function PanelVerificationReminder({ onCompletion }: { onCompletion: () => void }, ref: any) {
    const { t } = useTranslation()

    return (
        <div ref={ref}>
            <div className={`mb2 ${style.title}`}>{t('join.verify_mail')}</div>
            <span className={style.icon_verified} />
            <span className={style.description}>
                {t('join.check_mails')} <br />
                {t('join.verify_account')}
            </span>
            <div className='mb2'>
                <Link to='/explore' className={`${style.button} ${style.buttonFilled}`}>{t('join.explore')}</Link>
            </div>
        </div>
    )
}

export default forwardRef(PanelVerificationReminder)
