export enum ChatStatus {
    NotRequested = 'not_requested', // No chat between the two users           // blue
    Requested = 'requested',        // One user send one message to a user     // yellow
    Invited = 'invited',            // Receiver of one message from a user     // yellow
    Accepted = 'accepted',          // Other user replied with a message       // green
    Rejected = 'rejected',          // Other user rejected the chat            // red
    Blocked = 'blocked',            // Other user blocked the chat             // black
    NoBusinesses = 'no_businesses', // You cannot connect because you are an Influencer and the Influencer only wants business inquires
    NoInfluencer = 'no_influencer', // You cannot connect because you are a Brand and the Influencer only wants Influencer inquires
    AudienceSize = 'larger_audience_required', // You cannot connect because your Total Followership is to small
}