import React from 'react'

import style from './influspace-connect.module.scss'

export default function InfluspaceConnect() {
  return (
    <span className={style.container}>
        <span className={style.icon} />
        <span className={style.cta}>Connect on</span>
        <span className={style.label}>Influspace</span>
    </span>
  )
}
