import React, { useEffect, useState } from "react";
import { IOffer } from "../../../../service/marketplace/model/offer.model";
import marketplaceService from "../../../../service/marketplace/marketplace.service";
import { useNavigate, useParams } from "react-router-dom";
import { Offer } from "./component/offer";
import { ApplicationCreatePanel } from "./component/application-create";
import TechnicalError from "../../../../components/app/component/error/technical/technical-error.component";
import LoadingContent from "../../../../theme/layout/loading-content/loading-content.component";
import { Application } from "./component/application";
import OfferCreator from "../offer-creator";
import SessionService from "../../../../service/session/session.service";
import { useTranslation } from 'react-i18next';
import {OfferConnectCTA} from "./component/offer/component/connect-cta";
import DownloadComponent from "../offer/component/offer-downloader";

export function OfferDetail() {
    const { t } = useTranslation();
    const { offerRef } = useParams();

    const [offer, setOffer] = useState<IOffer>(undefined);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [serversideError, setServersideError] = useState<boolean>(false);
    const [openOfferEditor, setOpenOfferEditor] = useState<boolean>(false);

    const navigate = useNavigate();
    const loggedIn = SessionService.isAuthenticated();

    const networkCount = SessionService.getMeDetails().networkCount

    useEffect(() => {
        fetchOffer();
    }, []);

    async function fetchOffer() {
        try {
            const c = await marketplaceService.getOffer(offerRef);
            setOffer(c);
        } catch (error) {
            const response = error.toJSON();

            if (response.status === 404)
                setNotFound(true);

            if (response.status >= 500)
                setServersideError(true);
        }
    }

    async function onApply(text: string) {
        try {
            await marketplaceService.apply(offer, text);
            await fetchOffer();
        } catch (error) {
            //TODO: handle error
        }
    }

    async function onDeleteApplication() {
        try {
            await marketplaceService.deleteApplication(offer.ref);
            await fetchOffer();
        } catch (error) {
            if (error.toJSON().response.status >= 500)
                setServersideError(true);
        }
    }

    function ApplicationArea() {
        if (offer.owned === false && offer.applied === false) {
            return <ApplicationCreatePanel onSend={onApply} />;
        } else {
            return (
                <>
                    <ApplicationHeader />
                    {offer.applications.map((application) => (
                        <Application
                            onDelete={onDeleteApplication}
                            offer={offer}
                            key={application.ref}
                            application={application}
                        />
                    ))}
                </>
            );
        }
    }

    function ApplicationHeader() {
        if (offer?.owned) {
            return offer?.applications.length > 0 ? (
                <h2 className='mb2 mt2'>{t('offer_detail.header.applications')}</h2>
            ) : (
                <h2 className='mb2 mt2'>{t('offer_detail.header.waiting_for_applications')}</h2>
            );
        } else {
            return <h2 className='mb2 mt2'>{t('offer_detail.header.my_application')}</h2>;
        }
    }



    if (notFound) navigate('/marketplace');

    if (serversideError) return <TechnicalError />;

    if (offer === undefined) return <LoadingContent />;

    function onUpdateOffer(offer: IOffer) {
        setOffer(offer);
        setOpenOfferEditor(false);
    }

    return (
        <section>
            <OfferCreator offer={offer} open={openOfferEditor} onClose={onUpdateOffer} />
            <Offer onEdit={() => setOpenOfferEditor(true)} offer={offer} />
            { !loggedIn ? <></> : (networkCount < 1 ? <OfferConnectCTA/> : <ApplicationArea />) }
            {<div><DownloadComponent offer={offer}/></div>}
        </section>
    );
}
