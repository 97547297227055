import React from "react"
import {Route, Routes} from "react-router-dom"
import Navbar from "../navbar/navbar.component"
import CreateBlog from "../blog/create/index.component";
import ManageBlogs from "../blog/manage/index.component";
import MissingError from "../../../../components/app/component/error/missing/missing-error.component";
import BackofficeMenu from "../../menu/index.component";
import Templates from "../../mail-templates";
import UserStatistics from "../../user-statistics";
import {SupportManager} from "../support";

export default function BackofficeRouter() {
    // if (config.isProduction) // replace with admin role
    //   return <MissingError />

    return (
        <>
            <Navbar/>
            <Routes>
                <Route path='/' element={<BackofficeMenu/>}/>

                <Route path='/email-templates' element={<Templates/>}/>
                <Route path='/user-statistics' element={<UserStatistics/>}/>
                <Route path='/support' element={<SupportManager/>}/>



                {/*<Route path='/profiles' element={<Profiles/>}/>*/}

                {/*<Route path='/analytics' element={<Analytics/>}/>*/}

                {/*<Route path='/tickets' element={<h1>User Tickets</h1>}/>*/}

                {/*<Route path='/reports/message' element={<ReportedMessages/>}/>*/}
                {/*<Route path='/reports/profile' element={<ReportedProfiles/>}/>*/}

                <Route path='/blog-creation' element={<CreateBlog/>}/>
                <Route path='/blog-management' element={<ManageBlogs/>}/>

                {/*<Route path='/mail-communication' element={<h1>Every System generated mail and user requests from his mail address. Also you can send and reply to mails from the user</h1>}/>*/}
                {/*<Route path='/service-logs' element={<h1>Service Logs Error Occurence</h1>}/>*/}


                <Route path='*' element={<MissingError/>}/>
            </Routes>
        </>
    )
}