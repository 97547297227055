import React, { useState } from 'react'

import style from './input-label.module.scss'

type InputLabelProps = {
    label: string
    hint?: string
}

export default function InputLabel({ label, hint }: InputLabelProps) {
    const [showInfo, setShowInfo] = useState<boolean>(false)

    const renderedInfoIcon = hint ? <span className={style.infoIcon} onClick={() => setShowInfo(!showInfo)} /> : <></>
    const renderedInfoMessage = showInfo ? <div className={style.infoMessage}>{hint}</div> : <></>

    return (
        <div className={style.container}>
            <span className={style.label}>{label}</span>
            {renderedInfoIcon}
            {renderedInfoMessage}
        </div>
    )
}