import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import style from './index.module.scss'
import DevComponentBadge from "./component/dev-component-badge";
import Panel from "../../../theme/component/panel/panel.component";
import {IBackofficeOption} from "./model/backoffice-option";
import {componentList} from "./model/backoffice-options";

export default function BackofficeMenu() {

    const [components, setComponents] = useState<IBackofficeOption[]>()

    useEffect(() => {
        setComponents(componentList)
    }, [])

    function searchComponent(event: React.ChangeEvent<HTMLInputElement>) {
        const searchValue = event.target.value.toLowerCase()
        if (searchValue.length === 0) {
            setComponents(componentList)
        } else {
            setComponents(componentList.filter(component => {
                return component.name.toLowerCase().includes(searchValue)
                    || component.description.toLowerCase().includes(searchValue)
            }))
        }
    }

    return <section>
        <br/>
        <h1>Dev Menu</h1>
        <br/>
        <input className={style.search} type={'text'} placeholder={'Search'} onChange={searchComponent} />
        <div className={style.componentList}>
            {components && components.map((component: IBackofficeOption) => {
                return <Link to={component.path} key={component.path}>
                    <Panel className={style.component}>
                        <h3>
                            {component.name}
                            <DevComponentBadge devComponentType={component.topic} />
                        </h3>
                        <p>{component.description}</p>
                    </Panel>
                </Link>
            })}
        </div>
    </section>
}