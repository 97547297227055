// Updated Tile.tsx

import React from 'react';
import style from './tile.module.scss';
import { useNavigate } from 'react-router-dom';
import { Blog } from '../module/blog.model';

type TileSize = 'big' | 'medium' | 'small';

interface TileProps {
    size: TileSize;
    blog: Blog;
}

const Tile: React.FC<TileProps> = ({ size, blog }) => {
    const navigate = useNavigate();
    const tileClasses = `${style.tile} ${style[size]}`;

    const navigateToBlog = (ref: any) => {
        navigate(`/blogs/${ref}`);
    };

    return (
        <div className={tileClasses} onClick={() => navigateToBlog(blog.ref)}>
            <div className="image-container">
                <img src={`https://influspace.com/api/image?ref=${blog.image}`} alt="image" />
            </div>
            <div className={`${style.tileContent}`}>
                <h6 className={style.status}>{blog.status.toUpperCase()}</h6>
                <h3 className={style.title}>{blog.title}</h3>
                <h6 className={style.createdAt}>{blog.createdAt}</h6>
            </div>
        </div>
    );
};

export default Tile;
