import React, { useState } from "react";
import style from "./index.module.scss";
import CountdownTimer from "../countdown";

type BoostButtonType = {
    boostCount: number;
    boostedUntil?: Date;
    onClick: () => void;
};

export function BoostButton({ boostCount, boostedUntil, onClick }: BoostButtonType) {
    const [showBoostCount, setShowBoostCount] = useState(false);

    const handleClick = () => {
        if (boostedUntil) {
            return;
        }

        setShowBoostCount(true);
        onClick();
        setTimeout(() => {
            setShowBoostCount(false);
        }, 1000);
    };

    return (
        <span onClick={handleClick} className={`${style.container} ${(boostedUntil && !showBoostCount) && style.container_expanded}`}>
            <span className={style.icon} />
            {showBoostCount ? (
                <span className={style.count}>{boostCount}</span>
            ) : (
                boostedUntil && <CountdownTimer className={style.timer} targetDate={boostedUntil} format={"HHh"} />
            )}
        </span>
    );
}
