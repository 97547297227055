import React, { useState } from "react";
import style from "./index.module.scss";
import InputTextArea from "../../../../../../theme/component/input-text-area/input-text-area.component";
import InputButton from "../../../../../../theme/component/input-button";
import { useTranslation } from 'react-i18next';

type ApplicationCreatePanelProps = {
    onSend: (text: string) => void
}

export function ApplicationCreatePanel({ onSend }: ApplicationCreatePanelProps) {
    const { t } = useTranslation();
    const [text, setText] = useState<string>('');

    return (
        <div className={style.panel}>
            <InputTextArea
                onChange={value => setText(value)}
                className={style.application_area}
                label={t('application_create_panel.label')}
                placeholder={t('application_create_panel.placeholder')}
            />
            <InputButton
                className={'mt1'}
                onClick={() => onSend(text)}
                label={t('application_create_panel.send')}
            />
        </div>
    );
}
