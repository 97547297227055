import React, {useEffect, useState} from 'react'
import SearchService from '../../service/search/search.service'
import ProfileGrid from '../../theme/component/profile-grid/profile-grid.component'
import useQuery from '../../theme/hooks/use-query.component'
import {useLocation} from 'react-router-dom'
import FilterBar from './component/filter-bar.component'
import {IProfileTile} from '../../service/search/model/profile-tile.model'

export default function Search() {
    const location = useLocation()
    const urlExtractedQuery = useQuery('query', null)

    const [query, setQuery] = useState<string>()
    const [platform, setPlaform] = useState<string>()
    const [type, setType] = useState<string>()
    const [collaboration, setCollaboration] = useState<string>()
    const [selectedLocation, setSelectedLocation] = useState<ILocation>()


    const [profiles, setRestuls] = useState<IProfileTile[]>([])


    const fetchResults = async () => {
        setQuery(urlExtractedQuery)

        const results = await SearchService.search({
            query: urlExtractedQuery,
            platform,
            type,
            collaboration,
            location: selectedLocation
        })

        setRestuls(results)
    }

    useEffect(() => {
        fetchResults()
    }, [location, type, platform, collaboration, selectedLocation])

    function onChangePlatform(value: string) {

        if (value !== platform) {
            setPlaform(value)
        }
    }

    function onChangeType(value: string) {
        if (value !== type) {
            setType(value)
        }
    }

    function onChangeCollaboration(value: string) {
        if (value !== collaboration) {
            setCollaboration(value)
        }
    }


    return (
        <section>
            <div className='mb2'>
                <FilterBar onChangePlatform={onChangePlatform} onChangeCollaboration={onChangeCollaboration}
                           onChangeType={onChangeType} onChangeLocation={setSelectedLocation}/>
            </div>
            <ProfileGrid profiles={profiles}/>
        </section>
    )
}
