const config = {
    environment: process.env.REACT_APP_MODE,
    isProduction: process.env.REACT_APP_MODE === 'PRODUCTION',
    isDevelopment: process.env.REACT_APP_MODE === 'DEVELOPMENT',
    
    host: process.env.REACT_APP_HOST || window.location.protocol + '//' + window.location.hostname,
    apiRoot: process.env.REACT_APP_API_ROOT || window.location.protocol + '//' + window.location.hostname + '/api',
    wsRoot: process.env.REACT_APP_WS_ROOT || 'wss://' + window.location.hostname + '/api/chat/subscribe',

    timeout: 10000
}

export default config