import React from "react";
import OptionModel from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import style from './collaboration-filter.module.scss'
import FilterDropdown from "../filter-dropdown/filter-dropdown.component";

const options: OptionModel[] = [
    { label: 'All Collaborations', value: undefined},
    { label: 'Free Product Placement', value: 'freeProductPlacement'},
    { label: 'Product Placement', value: 'productPlacement'},
    { label: 'Influencer Collaboration', value: 'influencerCollaboration'},
]

type CollaborationFilterProps = {
    onChange: (value: string) => void
}
export default function CollaborationFilter({onChange}: CollaborationFilterProps) {
    return (
         <span>

            <span className={style.dropdown}><FilterDropdown options={options} onChange={value => onChange(value.value)}/></span>
        </span>
    )
}