import style from "./index.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
    IKeyword
} from "../../../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";

export function KeywordBar({ keywords }: { keywords: IKeyword[] }) {
    const navigate = useNavigate()
    function Keyword({ keyword }: { keyword: string }) {

        return <span className={style.keyword} onClick={() => navigate(`/search?query=${keyword}`)}>
            {keyword}
        </span>

    }
    return <div className={style.keywords}>
        {keywords?.slice(0, 4).map((keyword, index) => <Keyword keyword={keyword.word} key={index} />)}
    </div>
}