import React from 'react'
import style from './keyword-hints.module.scss'


type KeywordHintsProps = {
    hints: string[],
    open: boolean,
    selectedIndex?: number,
    onClick: (hint:string) => void
}

export default function KeywordHints({hints, open, onClick, selectedIndex = -1}: KeywordHintsProps) {
  if (!open || hints.length == 0) {
    return <></>
  }

  return <div className={style.dropdown}>
    {hints?.map((hint, index) =>  
      <div key={hint} className={selectedIndex === index ? style.selected_hint: style.hint} onClick={(event) => onClick(hint)}>
      {hint}
      </div>
    )}
</div>
}