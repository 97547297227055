import React from "react";
import OptionModel from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import style from './platform-filter.module.scss'
import Platform from "../../../../service/network/model/Platform";
import FilterDropdown from "../filter-dropdown/filter-dropdown.component";

const options: OptionModel[] = [
    { label: 'All Platforms', value: undefined},
    { label: 'Youtube', value: Platform.Youtube},
    { label: 'Instagram', value: Platform.Instagram},
    { label: 'TikTok', value: Platform.Tiktok},
    { label: 'Twitter', value: Platform.Twitter},
    { label: 'Twitch', value: Platform.Twitch}
]
type PlatformFilterProps = {
    onChange: (value: string) => void
}
export default function PlatformFilter({onChange}: PlatformFilterProps) {
    return (
         <span>
            <span className={style.dropdown}><FilterDropdown options={options} onChange={value => onChange(value.value)}/></span>
        </span>
    )
}