import React from 'react'

import style from './profile-grid.module.scss'
import { IProfileTile } from '../../../service/search/model/profile-tile.model'
import ProfileTile from '../profile-tile/profile-tile.component'

export default function ProfileGrid({ profiles, showReject, labelOverride }: { profiles: IProfileTile[], showReject?: boolean, labelOverride?: string }) {
    const listProfiles = profiles.map((profile) =>  <ProfileTile profile={profile} showReject={showReject} labelOverride={labelOverride} key={profile.username} />)

    return (
        <div className={style.grid}>{listProfiles}</div>
    )
}
