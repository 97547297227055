class NumberFormatService {

    renderNumber(num: number): string {
        if (num >= 1000000) {
            return `${this.toFixed(num / 1000000, 2).toString()} mio`
        } else if (num >= 10000) {
            return `${this.toFixed(num / 1000, 1).toString()} k`
        } else if (num >= 1000) {
            return `${this.toFixed(num / 1000, 2).toString()} k`
        } else {
            return `${this.toFixed(num).toFixed(0)}`
        }
    }

    toFixed(num: number, decimals = 0) {
        return Number(Number(num).toFixed(decimals))
    }

}

export default new NumberFormatService()