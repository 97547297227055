import React, { ReactNode, useEffect, useState } from 'react'
import { updateNamespaceImport } from 'typescript'
import profileService from '../../../../../../service/profile/profile.service'

import style from './profile-displayname.module.scss'
import {useTranslation} from "react-i18next";

type DisplaynameProps = {
    editingMode: boolean
    displayname: string
    children?: ReactNode;
}

const displaynameRegex = /^$|^[a-zA-Z0-9_\-. ]+$/

export default function Displayname({ editingMode, displayname, children }: DisplaynameProps) {
    const [originalName, setOriginalName] = useState<string>(displayname)
    const [name, setName] = useState<string>(displayname)
    const [madeChanges, setMadeChanges] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (displayname !== originalName) {
            setOriginalName(displayname)
            setName(displayname)
            setMadeChanges(false)
        }

        if (editingMode === false && madeChanges) {
            if (name !== originalName) {
                updateName()
            }
        }

    }, [displayname, editingMode])

    async function updateName() {
        try {
            const newName = await profileService.setDisplayname(name)
            setName(newName)
        } catch (error) {
            console.log(error)
        }
    }

    function onChangeHandler(event: any) {
        const input = event?.target?.value

        if (input.length > 20)
            return

        if (displaynameRegex.test(input) === false) {
            return
        }

        setMadeChanges(true)
        setName(input)
    }

    if (editingMode === false)
        return (
            <span className={style.name}>
                <span className='show'>{name}</span>
                {children}
            </span>
        )

    return (
        <span className={style.name}>
            <input className={style.input}
                type='text'
                value={name}
                placeholder={t('profile_displayname.placeholder')}
                onChange={onChangeHandler} />
        </span>
    )
}
