import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import TechnicalError from '../../components/app/component/error/technical/technical-error.component'
import Platform from '../../service/network/model/Platform'
import networkService from '../../service/network/network.service'
import sessionService from '../../service/session/session.service'
import useQuery from '../../theme/hooks/use-query.component'
import Loading from '../../theme/layout/loading/loading.component'
import {InstagramAccountChoice} from "./component/instagram-account-choice";
import {AccountChoice} from "../../service/network/model/AccountChoice";
import {NotificationService} from "../../service/notification/notification.service";
import {NotificationType} from "../../service/notification/model/notification-type.enum";
import SessionService from "../../service/session/session.service";

export default function Network() {
    const navigate = useNavigate()

    const [error, setError] = useState<boolean>(false)
    const [accounts, setAccounts] = useState<AccountChoice[]>(undefined)

    const location = useLocation()
    const redirectCode = useQuery('code', '')
    const redirectState = useQuery('state', '')

    useEffect(() => {
        const sauce = location.pathname.substring(location.pathname.lastIndexOf('/') + 1).split('?')[0]

        async function redirect() {
            let response;
            const username = sessionService.getUsername()

            try {
                response = await networkService.redirect(sauce as Platform, redirectCode, redirectState)
            } catch (error) {
                navigate(`/profile/${username}`)
            }

            if (!response) {
                setError(true)
            }
            await SessionService.validateSession()
        }

        if (sauce === 'instagram') {
            redirectInstagram()
        } else if (sauce === 'facebook') {
            redirectFacebook()
        } else {
            redirect()
        }

    }, [])


    async function redirectFacebook() {
        let response;
        const username = sessionService.getUsername()

        try {
            response = await networkService.redirectFacebook(redirectCode, redirectState)
        } catch (error) {
            navigate(`/profile/${username}`)
        }

        if (!response) {
            setError(true)
        }

        if (response.facebookPages === undefined) {
            navigate(`/profile/${username}`)
            return
        } else if (response.facebookPages.length > 0) {
            response.platform = Platform.Facebook
            setAccounts(response.facebookPages)
        } else {
            navigate(`/profile/${username}`)
            NotificationService.displayNotification({
                message: 'No Facebook Pages found!', // TODO translate
                type: NotificationType.failure,
                duration: 3
            })
        }

        await SessionService.validateSession()
    }

    async function redirectInstagram() {
        let response;
        const username = sessionService.getUsername()

        try {
            response = await networkService.redirectInstagram(redirectCode, redirectState)
        } catch (error) {
            navigate(`/profile/${username}`)
        }

        if (!response) {
            setError(true)
        }

        if (response.instagramAccounts === undefined) {
            navigate(`/profile/${username}`)
            return
        } else if (response.instagramAccounts.length > 0) {
            response.platform = Platform.Instagram
            setAccounts(response.instagramAccounts)
        } else {
            navigate(`/profile/${username}`)
            NotificationService.displayNotification({
                message: 'No instagram Accounts found!', // TODO translate
                type: NotificationType.failure,
                duration: 3
            })
        }

        await SessionService.validateSession()
    }

    if (accounts) {
        return <InstagramAccountChoice state={redirectState} instagramAccounts={accounts}/>
    }

    if (error)
        return <TechnicalError/>
    else
        return <Loading/>
}
