import style from "./index.module.scss"
import { InputSwitch } from "../../../../theme/component/input-switch";
import SwitchFilterOption from "../../../../theme/component/input-switch/model/switch-filter-options";
import { useShowBusiness } from "./SubscriptionContext";
import BusinessPhone1 from "../../../../theme/resource/image/landing/phone1.png";
import BusinessPhone2 from "../../../../theme/resource/image/landing/phone2.png";
import BusinessPhone3 from "../../../../theme/resource/image/landing/phone3.png";
import {useTranslation} from "react-i18next";
import React from "react";

interface BenefitProps {
    title: string;
    coloredTitle: string;
    color: string;
    lineColor?: string;
    text: string;
    floatDirection: 'left' | 'right';
    business?: boolean;
}

export function BenefitsSection() {
    const { showBusiness, setShowBusiness } = useShowBusiness();
    const { t } = useTranslation();

    const businessOption: SwitchFilterOption = {
        label: t('benefits_section_business'),
        value: 'business',
        background: 'transparent linear-gradient(129deg, #03e600 0%, #1cff1a 100%) 0% 0% no-repeat padding-box'
    }

    const influencerOption: SwitchFilterOption = {
        label: 'Influencer',
        value: 'influencer',
        background: "transparent linear-gradient(129deg, #0099c9 0%, #30ceff 100%) 0% 0% no-repeat padding-box"
    }

    function Benefit({ title, coloredTitle, color, text, floatDirection, business }: BenefitProps) {
        return <div className={style.benefit} style={{ float: floatDirection }}>
            <span className={style.benefit_tile}>
                <div className={style.benefit_header}>
                    {title && <span>{title}</span>}
                    {" "}
                    {coloredTitle && <span style={{ color }}>{coloredTitle}</span>}
                </div>
                {business && <div className={style.line_business} style={{ backgroundColor: color }} />}
                {!business && <div className={style.line_influencer} style={{ backgroundColor: color }} />}
                <span className={style.benefit_text}>{text}</span>
            </span>
        </div>
    }

    return (
        <div className={style.container} id='features'>
            <div className={style.benefit_header}>{t('benefits_section_header')}</div>
            <span className={style.switch}>
                <InputSwitch option1={influencerOption} option2={businessOption} onSwitch={(selectedOption) => setShowBusiness(selectedOption.value === 'influencer')} />
            </span>
            <div className={style.gridContainer}>
                {!showBusiness ? (
                    <>
                        <Benefit
                            title={t('benefits_section_benefit_title_one')}
                            coloredTitle={t('benefits_section_benefit_title_colored_one')}
                            color="#03FF00"
                            floatDirection="left"
                            business={true}
                            text={t('benefits_section_benefit_description_one')}
                        />
                        <div className={style.image}><img src={BusinessPhone1} alt="App Explorer function" /></div>
                        <div className={style.image}><img src={BusinessPhone2} alt="App Marketplace function" /></div>
                        <Benefit
                            title={t('benefits_section_benefit_title_two')}
                            coloredTitle={t('benefits_section_benefit_title_colored_two')}
                            color="#03FF00"
                            floatDirection="right"
                            business={true}
                            text={t('benefits_section_benefit_description_two')}
                        />
                        <Benefit
                            title={t('benefits_section_benefit_title_three')}
                            coloredTitle={t('benefits_section_benefit_title_colored_three')}
                            color="#03FF00"
                            floatDirection="left"
                            business={true}
                            text={t('benefits_section_benefit_description_three')}
                        />
                        <div className={style.image}><img src={BusinessPhone3} alt="Business Image" /></div>
                    </>
                ) : (
                    <>
                        <Benefit
                            title={t('benefits_section_benefit_title_four')}
                            coloredTitle={t('benefits_section_benefit_title_colored_four')}
                            color="#00c0fc"
                            floatDirection="left"
                            business={false}
                            text={t('benefits_section_benefit_description_four')}
                        />
                        <div className={style.image}><img src={BusinessPhone1} alt="Business Image" /></div>
                        <div className={style.image}><img src={BusinessPhone2} alt="Business Image" /></div>
                        <Benefit
                            title={t('benefits_section_benefit_title_five')}
                            coloredTitle={t('benefits_section_benefit_title_colored_five')}
                            color="#00c0fc"
                            floatDirection="right"
                            business={false}
                            text={t('benefits_section_benefit_description_five')}
                        />
                        <Benefit
                            title={t('benefits_section_benefit_title_six')}
                            coloredTitle={t('benefits_section_benefit_title_colored_six')}
                            color="#00c0fc"
                            floatDirection="left"
                            business={false}
                            text={t('benefits_section_benefit_description_six')}
                        />
                        <div className={style.image}><img src={BusinessPhone3} alt="Business Image" /></div>
                    </>
                )}
            </div>
        </div>
    );
}
