import React from "react";
import style from "./index.module.scss"
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {IOffer} from "../../../../../../service/marketplace/model/offer.model";
import {IApplication} from "../../../../../../service/marketplace/model/application.model";
import SessionService from "../../../../../../service/session/session.service";
import Panel from "../../../../../../theme/component/panel/panel.component";
import {KeywordBar} from "../keyword-bar";
import Avatar from "../../../../../inbox/component/conversation/component/avatar/avatar.component";


type ApplicationProps = {
    application: IApplication,
    onDelete: (ref: string) => void,
    offer: IOffer
}

export function Application({application, onDelete, offer}: ApplicationProps) {
    const navigate = useNavigate()

    function Header() {
        return <div className={style.header}>
              <span className={style.avatar_container}>
                <Avatar avatarUrl={application?.sender.avatar} unread={0} username={application?.sender.username}/>
            </span>
          <span className={style.info}>
            <span className={style.name}>{application?.sender.displayname}</span>
            <span className={`pre-icon ${style.follower}`}>{application?.sender.networks.totalFollower}</span>
            <span className={style.keyword_container}>
                <KeywordBar keywords={application.keywords}/>
            </span>
          </span>
        </div>
    }

    function onClick() {
        if (SessionService.getUsername() !== application.sender.username) {
            navigate(`/chat/${application.sender.username}`)
        }
    }


  return <Panel onClick={onClick} className={style.panel}>
        <Header/>
        <div className={style.application_text_expanded}>{application.text}</div>
      <span className={`pre-icon ${style.date}`}>{moment(application.date).format('hh:mm')}</span>
      <span className={`${style.navigator}`} onClick={() => {}}>
        </span>
    </Panel>
}