import React from 'react';

type SvgProps = {
    color?: string;
    size?: number;
};

const PlacementIcon: React.FC<SvgProps> = ({ color = 'rgb(0,206,0)', size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
        <rect id="placement" x="0" y="0" width="64" height="64" style={{ fill: 'none' }}/>
        <path
            id="placement1"
            d="M40.505,27.513C40.505,29.99 38.495,32 35.986,32L27.515,32L27.515,22.994L35.986,22.994C38.497,22.994 40.505,25.004 40.505,27.513ZM46.499,27.513C46.499,21.686 41.81,17 35.986,17L21.487,17L21.487,47L27.513,47L27.513,37.994L35.984,37.994C41.81,37.994 46.496,33.305 46.496,27.513L46.499,27.513ZM62,32C62,48.573 48.573,62 32,62C15.427,62 2,48.573 2,32C2,15.427 15.427,2 32,2C48.573,2 62,15.427 62,32Z"
            style={{ fill: color, fillRule: 'nonzero' }}
        />
    </svg>
);

export default PlacementIcon;
