import React from 'react'
import DropdownOption from '../../models/dropdown-option.model'

import style from './input-option.module.scss'

type InputOptionProps = {
    value: DropdownOption
    onSelection: (value: DropdownOption) => void
    open: boolean
}

export default function InputOption({ value, onSelection, open }: InputOptionProps) {
    const closedStyle = open === false ? style.closed : ''
    const preIconClass = value.icon ? `${style.pre_icon} ${style[value.icon]}` : ''

    return <div
        className={`${preIconClass} ${style.option} ${closedStyle}`}
        onClick={() => onSelection(value)}
    >{value.label}</div>
}
