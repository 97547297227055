import React, { useEffect, useState } from 'react'
import style from './filter-dropdown.module.scss'
import useClickOutside from '../../../../theme/hooks/use-click-outside.component'
import DropdownOption from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import OptionModel from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import InputOption from '../../../../theme/component/input-dropdown/component/input-option/input-option.component'

type FilterDropdownProps = {
  label?: string // deprecated
  placeholder?: string
  options: OptionModel[]
  onChange?: (value: OptionModel) => void
  disabled?: boolean,
  initialIndex?: number
}
export default function FilterDropdown({ label, placeholder, options, onChange, disabled, initialIndex }: FilterDropdownProps) {
  const [selected, setSelected] = useState<OptionModel>(options[initialIndex])
  const [open, setOpen] = useState<boolean>(false)
  const [animationStyle, setAnimationStyle] = useState<any>({ display: 'none' })

  const dropdownRef = useClickOutside(() => {
    setOpen(false)
  })

  const disabledStyle = disabled ? style.disabled : ''
  const closedStyle = open === false ? style.closed : ''

  useEffect(() => {
    if (selected === undefined) {
      if (placeholder)
        setSelected({
          label: placeholder,
          value: 'default'
        })
      else if (options.length > 0)
        setSelected(options[0])
    }

  }, [options])

  function selectionHandler(option: DropdownOption) {
    setSelected(option)
    setOpen(false)
    clickHandler()

    if (onChange)
      onChange(option)
  }

  function changeHandler(event: any) {
    const value = event?.target?.value

    if (onChange)
      onChange(value)
  }

  function clickHandler() {
    if (open) {
      setOpen(false)
      setTimeout(() => {
        setAnimationStyle({ display: 'none' })
        setOpen(false)
      }, 250)
    } else {
      setAnimationStyle({})
      setTimeout(() => {
        setOpen(true)
      }, 1)
    }
  }

  return (
    <div className={style.container}>
      <Label label={label} />
      <div className={`${style.input} ${open ? style.open : ''} ${disabledStyle} protected`} onChange={changeHandler} ref={dropdownRef}>
        <div className={style.selected} onClick={clickHandler}>{selected?.label}</div>
        <div className={`${style.dropdown} ${closedStyle}`} style={animationStyle} >
          {options.map((option) => <InputOption key={option?.label} onSelection={selectionHandler} value={option} open={open} />)}
        </div>
      </div>
    </div>
  )
}

function Label({ label }: { label: string }) {
  if (label === undefined || label === '')
    return <></>

  return <span className={style.label}>{label}</span>
}