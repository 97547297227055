import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function DevMenuButton() {
    const location = useLocation()
    const [isDevMenuLocation, setIsDevMenuLocation] = useState<boolean>(false)

    useEffect(() => {
        const isDevMenuPath = location.pathname === '/dev'
        setIsDevMenuLocation(isDevMenuPath)
    }, [location])
    
    if (isDevMenuLocation)
        return (
            <section>
                <br />
                <Link to='/about'>Back to About</Link>
            </section> 
        )

    return (
        <section>
            <br />
            <Link to='/dev'>Back to Laboratory</Link>
        </section> 
    )
}
