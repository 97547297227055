import React, { useEffect, useState } from 'react';
import InputButton from '../../../../../../../theme/component/input-button';
import InputTextArea from '../../../../../../../theme/component/input-text-area/input-text-area.component';
import { Resize } from '../../../../../../../theme/component/input-text-area/model/resize.model';
import Overlay from '../../../../../../../theme/component/overlay/overlay.component';
import ChatService from '../../../../../../../service/chat/chat.service';
import style from './message-popup.module.scss';
import { OfferReference } from '../../../../../../../service/chat/model/OfferReference';
import profileService from '../../../../../../../service/profile/profile.service';
import { OfferDropwdown } from './offer-dropwdown';
import {useTranslation} from "react-i18next";
import SessionService from "../../../../../../../service/session/session.service";
import {PopupCTA} from "../../../../../../marketplace/component/offer-detail/component/offer/component/popup-cta";

type MessagePopupProps = {
    openPopup: boolean;
    profileUsername: string;
    onClose: () => void;
    onMessageSend: () => void;
};

const messagePattern: Pattern = {
    match: /^.{1,300}$/,
    error: 'Message can not be empty'
};

export default function MessagePopup({ openPopup, onClose, onMessageSend, profileUsername }: MessagePopupProps) {
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(false);
    const [disableBackdropClose, setDisableBackdropClose] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [charactersLeft, setCharactersLeft] = useState<number>(300);
    const [offerReferences, setOfferReferences] = useState<OfferReference[]>([]);
    const [selectedOfferRef, setSelectedOfferRef] = useState<string>(undefined);

    useEffect(() => {
        setOpen(openPopup);
    }, [openPopup]);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        fetchOffers(signal);

        return () => abortController.abort();
    }, []);

    async function fetchOffers(signal: AbortSignal) {
        try {
            const offers = await profileService.getOffers(signal);
            setOfferReferences(offers);
        } catch (error) {
            // Handle error gracefully
            // console.log(error);
        }
    }

    function closeHandler() {
        setOpen(false);
        onClose();
    }

    function changeHandler(value: string) {
        setMessage(value);
        setCharactersLeft(300 - value.length);
    }

    async function checkAndSendMessage() {
        if (messagePattern.match.test(message)) {
            setErrorMessage('');
            await ChatService.sendMessage(profileUsername, message, selectedOfferRef);
            onMessageSend();
            setOpen(false);
        } else {
            setErrorMessage(messagePattern.error);
        }
    }

    if (SessionService.getMeDetails().networkCount < 1) {

        return <Overlay title={'Connect'} open={open} onClose={closeHandler} disableTapout={disableBackdropClose} className={style.connect_cta_container}>
            <div className={"mt2"}>
                <PopupCTA className={style.connect_cta} />
            </div>
        </Overlay>
    }

    return (
        <Overlay title={t('messagepopup.header')} open={open} onClose={closeHandler} disableTapout={disableBackdropClose}>
            <div className='mt3'>
                <InputTextArea maxLength={300} placeholder={t('messagepopup.placeholder')} rows={5} pattern={messagePattern} onChange={changeHandler} error={errorMessage} resize={Resize.Vertical} />
            </div>
            <span className={style.characterCount}>
                {charactersLeft} {t('messagepopup.charactersLeft')}
            </span>
            <OfferDropwdown offers={offerReferences} onSelect={selected => setSelectedOfferRef(selected.value === 'none' ? undefined : selected.value)} />
            <span className='mt2 float-right'>
                <InputButton label={t('messagepopup.sendButton')} onClick={checkAndSendMessage} padding={style.sendButton} />
            </span>
        </Overlay>
    );
}
