import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import SessionDetails from '../../../../../service/session/model/session-details'
import sessionService from '../../../../../service/session/session.service'
import useClickOutside from '../../../../hooks/use-click-outside.component'

import style from './user.module.scss'
import {ProfileType} from "../../../../../service/marketplace/model/offer.model";
import {useTranslation} from "react-i18next";
import SessionService from "../../../../../service/session/session.service";

type UserProps = {
    loggedIn: boolean
}

export default function User({ loggedIn }: UserProps) {
    const [session, setSession] = useState<SessionDetails>({ authenticated: false, authorized: false, active: false, premium: false })
    const [profileType, setProfileType] = useState<ProfileType>(undefined)
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
    const userRef = useClickOutside(() => setOptionsOpen(false))
    const { t } = useTranslation()
    const location = useLocation()

    function updateSessionDetails() {
        const session = sessionService.getSessionDetails()
        setSession(session)
        setProfileType(sessionService.getMeDetails().profileType)
    }

    function toggleOptions() {
        setOptionsOpen(!optionsOpen)
    }

    useEffect(() => {
        updateSessionDetails()
    }, [loggedIn])

    useEffect(() => {
        setOptionsOpen(false)
    }, [location])

    if (session.authenticated) {
        return <>
            <span className={style.user} onClick={toggleOptions} ref={userRef}>
                <span className={style.label}>{session?.username}</span>
                <span className={style.icon_user}/>
            </span>
            <span className={`${style.options} ${optionsOpen ? '' : style.closed}`}>
                <Link to='/settings'
                      className={`${style.pre_icon} ${style.icon_settings}`}>{t('nav.options_settings')}</Link>
                <Link to='/bookmarks'
                      className={`${style.pre_icon} ${style.icon_bookmarks}`}>{t('nav.options_bookmarks')}</Link>
                <Link to={`/profile/${session?.username}`}
                      className={`${style.pre_icon} ${style.icon_account}`}>{t('nav.options_profile')}</Link>
                {!session.premium && profileType === ProfileType.INFLUENCER ? <Link to={`/premium`}
                                                                                    className={`${style.pre_icon} ${style.icon_premium}`}>{t('nav.options_premium')}</Link> : <></>}
                <Link to='/boost' className={`${style.pre_icon} ${style.icon_booster}`}>
                    {t('nav.options_booster')} {SessionService.getMeDetails().boosterCount > 0 && (SessionService.getMeDetails().boosterCount + 'x')}
                </Link>
                <Link to='/signout'
                      className={`${style.pre_icon} ${style.icon_sign_out}`}>{t('nav.options_logout')}</Link>
            </span>
        </>
    }

    return <>
        <Link to='/login' className={style.user}>
            <span className={style.label}>
                <span>{t('nav.options_login')}</span>
            </span>
            <span className={style.icon_sign_in} />
        </Link>
    </>
}
