import React from 'react'

import style from './menu-item.module.scss'
import {DetailView} from "../../../../model/detail-view.model";

type MenuItemProps = {
    name: DetailView,
    selected: DetailView,
    onSelection: (details: DetailView) => void
}

export default function MenuItem({ name, onSelection, selected, }: MenuItemProps) {
    const selectedStyle = selected === name ? style.selected : ''

    return <li className={`${style.icon} ${style[`icon_${name.toLowerCase()}`]} pre-icon post-icon ${selectedStyle}`} onClick={() => onSelection(name)}>{name}</li>
}


