export enum MessageType {
    authentication = 'authentication',
    text = 'text',
    unknown = 'unknown'
}

// image = 'image',
// video = 'video',
// audio = 'audio',
// file = 'file',
// location = 'location',
// contact = 'contact',
// sticker = 'sticker',
// emoji = 'emoji',