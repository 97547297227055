import React, { useImperativeHandle, forwardRef } from 'react';
import { IOffer } from "../../../../../../service/marketplace/model/offer.model";
import OfferPreview from '../offer-download-preview/index';
import html2canvas from 'html2canvas';
import style from '../offer-download-preview/index.module.scss';

type DownloadComponentProps = {
    offer: IOffer;
};

const DownloadComponent = forwardRef(({ offer }: DownloadComponentProps, ref) => {
    const downloadAsImage = () => {
        const element = document.getElementById("offerPreview");
        if (element) {
            html2canvas(element, { useCORS: true, allowTaint: false }).then(canvas => {
                const link = document.createElement('a');
                link.href = canvas.toDataURL("image/jpeg");
                link.download = `${offer.name}.jpg`;
                link.click();
            });
        }
    };

    useImperativeHandle(ref, () => ({
        downloadAsImage
    }));

    return (
        <div className={style.downloadContainer}>
            <div id="offerPreview" style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
                <OfferPreview offer={offer} />
            </div>
        </div>
    );
});

export default DownloadComponent;
