import React, {useState} from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputText from '../../../../theme/component/input-text/input-text.component'
import Overlay from '../../../../theme/component/overlay/overlay.component'

import style from './dev-overlay.module.scss'

export default function DevOverlay() {
    const [open, setOpen] = useState(false)
    const [disableBackdropClose, setDisableBackdropClose] = useState<boolean>(false)

    const [testName, setTestName] = useState<string>('')
    const [testAge, setTestAge] = useState<string>('')
    const [testSaved, setTestSaved] = useState<boolean>(false)

    function nameChange(value: string) {
        setDisableBackdropClose(true)
        setTestName(value)
    }

    function ageChange(value: string) {
        setDisableBackdropClose(true)
        setTestAge(value)
    }

    function save() {
        setTestSaved(true)
        setOpen(false)
    }

    function reset() {
        setDisableBackdropClose(false)
        setTestSaved(false)
        setTestName('')
        setTestAge('')
    }

    return (
        <section>
            <div className='mt2'><InputButton label='Open Popup' onClick={() => setOpen(true)}/></div>
            <div className='mt2'><InputButton label='Reset State' onClick={reset}/></div>
            <Overlay title='Test Popup' open={open} onClose={() => setOpen(false)} disableTapout={disableBackdropClose}>
                <div className='mt3'>
                    <InputText label='Name' placeholder='Your Name' onChange={nameChange} info='A nickname is enough'/>
                </div>
                <div className='mt3'>
                    <InputText label='Age' placeholder='Your Age' onChange={ageChange}
                               info='You need to be at least 17+'/>
                </div>
                <span className='mt3'>
                    <InputButton padding={style.save} label='Cancel' onClick={() => setOpen(false)}/>
                </span>
                <span className='mt3 float-right'>
                    <InputButton padding={style.save} label='Save' onClick={save}/>
                </span>
                <br/>
                <br/><br/><br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
                <br/>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget
                    condimentum ultricies, ipsum elit ullamcorper elit, at sollicitudin nisi
                    magna at ex. Donec in est sit amet dolor fermentum lacinia. Sed vitae
                    tincidunt massa. Sed eget odio et metus lacinia aliquet. Donec
                    consectetur, libero sit amet ultricies lacinia, velit enim
                    efficitur augue, eget consectetur diam diam non
                    lectus. Donec euismod, nisl eget condimentum
                    ultricies, ipsum elit ullamcorper elit, at
                    sollicitudin nisi magna at ex. Donec in
                    est sit amet dolor fermentum lacinia.
                </p>
            </Overlay>
            <div className="mt3">
                <div>
                    <div>Name</div>
                    <span className='ml-3'>{testName ? testName : '-'}</span>
                </div>
                <div>
                    <div>Age</div>
                    <span className='ml-3'>{testAge ? testAge : '-'}</span>
                </div>
                <div>
                    <div>Saved</div>
                    <span className='ml-3'>{testSaved ? 'Yes' : 'No'}</span>
                </div>
                <div>
                    <div>Tapout Disabled</div>
                    <span className='ml-3'>{disableBackdropClose ? 'Yes' : 'No'}</span>
                </div>
            </div>
        </section>
    )
}
