import { IKeyword } from "../../../influspace/profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import { IApplication } from "./application.model";
import ICollaboration from "../../../influspace/profile/component/profile-collaboration/model/collaboration.model";
import ProfileView from "../../profile/model/profile-view.model";
export interface IOffer {
    ref?: string;
    name: string;
    description: string;
    keywords: IKeyword[];
    open?: boolean;
    applications?: IApplication[];
    created: string;
    avatarUrl?: string;
    bookmarked: boolean;
    applied: boolean;
    owned: boolean;
    collaborations?: ICollaboration;
    audience: ProfileType;
    location?: ILocation;
    image?: string;
    author: ProfileView;
    premiumOnly: boolean;
    premiumOnlyUntil?: Date;
}

export enum ProfileType {
    INFLUENCER = "influencer",
    BRAND = "brand",
    ALL = "all",
}

export interface IOffersResponse {
    offers: IOffer[];
    totalPages: number;
}
