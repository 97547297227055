import React from 'react'
import {INotification} from "../../../../../service/notification/model/notification.model";
import style from './index.module.scss'
import {NotificationType} from "../../../../../service/notification/model/notification-type.enum";
import {DirectMessage} from "../../../../../service/notification/model/direct-message.model";
import {useNavigate} from "react-router-dom";

export default function Notification({ notification, onSelfDestruct }: { notification: INotification, onSelfDestruct: () => void }) {

    const background = getBackground(notification)
    const icon = getIcon(notification)
    const animationDuration = { animationDuration: `${notification.duration}s` }
    const navigator = useNavigate()

    function getIcon(notification: INotification) {
        switch (notification.type) {
            case 'info':
                return style['info_icon']
            case 'success':
                return style['success_icon']
            case 'warning':
                return style['warning_icon']
            case 'failure':
                return style['failure_icon']
            case 'directMessage':
                return style['directMessage_icon']
            default:
                return style['info_icon']
        }
    }

    function getBackground(notification: INotification) {
        switch (notification.type) {
            case 'info':
                return style['info_background']
            case 'success':
                return style['success_background']
            case 'warning':
                return style['warning_background']
            case 'failure':
                return style['failure_background']
            case 'directMessage':
                return style['directMessage_background']
            default:
                return style['info_background']
        }
    }

    function onClick() {
        if (notification.type === NotificationType.directMessage) {
            const directMessage = notification as DirectMessage

            navigator(`/chat/${directMessage.data.senderUsername}`)
            onSelfDestruct()
        }
    }

    return <span className={`${style.notification} ${background}`} onClick={onClick}>
        <span className={`${style.icon} ${icon}`} />
        <span className={style.message}>{notification.message}</span>
        <span className={style.loading} style={animationDuration} />
    </span>
}