import React from "react";
import {PayPalButtons} from "@paypal/react-paypal-js";
import Panel from "../panel/panel.component";

import style from "./index.module.scss"

type PaymentPickerProps = {
    onApprove: (data: any, actions: any) => Promise<void>
    createSubscription?: (data: any, actions: any) => Promise<string>
    createOrder?: () => Promise<string>
    name: string
}


export function PaymentPicker({ createSubscription, createOrder, onApprove, name}: PaymentPickerProps) {

    return <section className={'text-center'}>
        <Panel className={style.panel}>
            <h1>Choose a payment option</h1>
            {name}
            <div className={'mt2'}>
                <PayPalButtons
                    createSubscription={createSubscription}
                    onApprove={onApprove}
                    createOrder={createOrder}
                />
            </div>

        </Panel>

    </section>
}