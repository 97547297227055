import React from "react";
import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import config from "../../../../../../service/config/config";
import {PremiumBadge} from "../../../../../../theme/component/premium-badge";
import ProfileView from "../../../../../../service/profile/model/profile-view.model";




export function ProfileRecommendationComponent({ profile }: { profile: ProfileView }) {

    const navigate = useNavigate();

    const avatarUrl = profile.avatar ? config.host + profile.avatar : require('../../../../../../theme/resource/image/default/avatar_default.png')

    function onClick() {
        navigate(`/profile/${profile.username}`)
        window.location.reload();


    }

    return (
        <div className={style.profile_container} onClick={onClick}>
            <img className={style.avatar} src={avatarUrl} alt="Avatar" />
            <span className={style.content}>
                <span className={style.title}>{profile.displayname}</span>
                <PremiumBadge premium={profile?.premium} className={style.premium}/>
                <div className={style.description}>{profile.description}</div>
            </span>
        </div>
    )
}