import React, {useEffect, useState} from 'react';
import SupportTicketRequest from '../../service/support/model/SupportTicketRequest';
import supportService from '../../service/support/support.service';
import InputButton from '../../theme/component/input-button';
import InputTextArea from '../../theme/component/input-text-area/input-text-area.component';
import InputText from '../../theme/component/input-text/input-text.component';
import PanelComponent from '../../theme/component/panel/panel.component';
import OptionModel from '../../theme/component/input-dropdown/models/dropdown-option.model';
import InputDropdown from '../../theme/component/input-dropdown/input-dropdown.component';
import sessionService from '../../service/session/session.service';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {NotificationService} from "../../service/notification/notification.service";
import {NotificationType} from "../../service/notification/model/notification-type.enum";
import style from "./support.module.scss"

export default function Support() {
    const { t } = useTranslation();

    const issuePattern = {
        match: new RegExp('^.{10,500}$'),
        error: t('support.error.issue_pattern')
    };

    const titlePattern = {
        match: new RegExp('^.{4,30}$'),
        error: t('support.error.title_pattern')
    };
    const emailPattern = {
        match: new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$'),
        error: t('support.error.email_pattern')
    };

    const options: OptionModel[] = [
        { label: t('support.topic.help'), value: 'help' },
        { label: t('support.topic.bug'), value: 'bug' },
        { label: t('support.topic.spam'), value: 'spam' },
        { label: t('support.topic.abuse'), value: 'abuse' },
        { label: t('support.topic.harassment'), value: 'harassment' },
        { label: t('support.topic.other'), value: 'other' },
    ];

    const navigate = useNavigate();
    const [email, setEmail] = useState(undefined);
    const [showEmailInput, setShowEmailInput] = useState(false); // Flag to show email input
    const [title, setTitle] = useState('');
    const [topics, setTopics] = useState(options[0]);
    const [description, setDescription] = useState('');

    const [errorTitle, setErrorTitle] = useState('');
    const [errorDescription, setErrorDescription] = useState('');
    const [ticketSent, setTicketSent] = useState(false); // New state to track if the ticket is sent

    useEffect(() => {
        const sessionDetails = sessionService.getSessionDetails();
        setShowEmailInput(!sessionDetails.username)
    }, []);

    async function sendTicket() {
        if (validateInput()) {
            let ticket: SupportTicketRequest = {
                topic: title,
                type: topics.value,
                issue: description,
                mail: email,
            };

            try {
                await supportService.sendTicket(ticket);
                setTicketSent(true)
            } catch (error) {
                NotificationService.displayNotification({
                    message: t('support.error.unexpected'),
                    type: NotificationType.failure
                })
            }

        }
    }

    function validateInput(): boolean {
        if (showEmailInput && !emailPattern.match.test(email)) {
            setErrorDescription(emailPattern.error);
            return false;
        }

        if (!titlePattern.match.test(title)) {
            setErrorTitle(titlePattern.error);
            return false;
        }

        if (!issuePattern.match.test(description)) {
            setErrorDescription(issuePattern.error);
            return false;
        }

        return true;
    }

    return (
        <section className='text-center'>
            <h1 className='text-center mb1'>{t('support.header.title')}</h1>
            <PanelComponent className={style.panel}>
                {!ticketSent ? (
                    <div>
                        <span className={style.support_icon}/>
                        {showEmailInput && (
                            <div className="mb3">
                                <InputText placeholder={t('support.form.email')} onChange={setEmail} initialValue={email} />
                            </div>
                        )}
                        <div className="mb3">
                            <InputText placeholder={t('support.form.title')} error={errorTitle} onChange={setTitle} initialValue={title} />
                        </div>
                        <div className="mb3">
                            <InputDropdown placeholder={t('support.form.topics')} options={options} onChange={setTopics} initialIndex={0} />
                        </div>
                        <div className="mb3">
                            <InputTextArea placeholder={t('support.form.description')} error={errorDescription} onChange={setDescription} rows={7} maxLength={500} initialValue={description} />
                        </div>
                        <div>
                            <InputButton label={t('support.form.send')} onClick={sendTicket} fill={true} />
                        </div>
                    </div>
                ) : (
                    <div className={style.success}>
                        <span className={style.success_icon}/>
                        <h2 className='mb2'>{t('support.thank_you.title')}</h2>
                        <br/>
                        <h4>{t('support.thank_you.message1')}</h4>

                        <br/>
                        <InputButton label={t('support.thank_you.button')} onClick={() => navigate(-1)} fill={true} />
                    </div>
                )}
            </PanelComponent>
        </section>
    );
}
