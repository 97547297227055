import style from './index.module.scss'
import InputText from "../input-text/input-text.component";
import React, { useEffect, useState } from "react";
import profileService from "../../../service/profile/profile.service";
import LocationHints from "./component/location-hints";

type LocationInputTextProps = {
    initialLocation?: ILocation,
    onChange: (location: ILocation) => void
    placeholder?: string
    className?: string
}

export function LocationInputText({ initialLocation, onChange, className = '', placeholder = '' }: LocationInputTextProps) {
    const [locationString, setLocationString] = useState<string>(initialLocation?.display ? initialLocation.display : '')
    const [locationHints, setLocationHints] = useState<ILocation[]>([])
    const [openHints, setOpenHints] = useState<boolean>(false)
    const [selectedHintIndex, setSelectedHintIndex] = useState<number>(-1);

    useEffect(() => {
        const delayTimer = setTimeout(async () => {
            await fetchLocationHints()
        }, 300);

        return () => clearTimeout(delayTimer)
    }, [locationString]);

    async function fetchLocationHints() {
        if (!locationString || locationString.length < 3 || locationString === initialLocation?.display) {
            return;
        }

        try {
            setLocationHints(await profileService.getLocationHints(locationString))
        } catch (error) {
            setLocationHints([])
        }
    }

    function onBlur() {
        if (locationString?.length === 0) {
            onChange(undefined)
        }

        setTimeout(async () => {
            if (locationString?.length > 0 && initialLocation?.display !== locationString) {
                const locationHint = locationHints.length > 0 ? locationHints[0] : (await profileService.getLocationHints(locationString))[0]
                onChange(locationHint)
                setLocationString(locationHint.display)
            }
            setOpenHints(false)
        }, 200)
    }

    useEffect(() => {
        setLocationHints([])
    }, [locationString]);

    function onSelectHint(selectedLocation: ILocation) {
        selectedLocation.display = selectedLocation.country ? `${selectedLocation.country}${selectedLocation.province ? ', ' + selectedLocation.province : ''}${selectedLocation.city ? ', ' + selectedLocation.city : ''}` : undefined
        setLocationHints([])
        setLocationString(selectedLocation.display)
        onChange(selectedLocation)
    }

    function onEnter() {
        if (locationString?.length === 0) {
            onChange(undefined)
        }

        if (locationHints?.length > 0) {
            onSelectHint(locationHints[0])
        }
    }

    function onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedHintIndex((prevIndex) =>
                prevIndex < locationHints.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedHintIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        } else if (event.key === 'Enter') {
            if (selectedHintIndex !== -1) {
                event.preventDefault();
                const selectedHint = locationHints[selectedHintIndex];
                setOpenHints(false);
                setLocationHints([])
                onChange(selectedHint)
            }
        }
    }


    return <div className={style.area}>
            <InputText className={className} onKeyDown={onKeyDownHandler} onFocus={() => setOpenHints(true)} onEnter={onEnter} onBlur={onBlur} onChange={setLocationString} placeholder={placeholder} initialValue={locationString} />
            <LocationHints selectedIndex={selectedHintIndex} open={openHints} hints={locationHints} onClick={onSelectHint} />
        </div>




}