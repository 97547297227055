import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProfileView from '../../../../../../service/profile/model/profile-view.model'
import sessionService from '../../../../../../service/session/session.service'
import MessagePopup from './component/message-popup.component'
import ChatService from '../../../../../../service/chat/chat.service'

import style from './profile-connect.module.scss'
import { ChatStatus } from '../../../../../chat/model/chat-status.model'
import {useTranslation} from "react-i18next";

type ConnectProps = {
    profile: ProfileView,
    editModeChanged: (editmode: boolean) => void
}

export default function ConnectButton({ profile, editModeChanged }: ConnectProps) {
    const navigate = useNavigate()

    const [chatStatus, setChatStatus] = useState<ChatStatus>()
    const [label, setLabel] = useState<string>('Connect')
    const [labelStyle, setLabelStyle] = useState<string>(style.connect)

    const [profileOwner, setProfileOwner] = useState<boolean>(false)
    const [editingMode, setEditingMode] = useState<boolean>(false)

    const [openMessagePopup, setOpenMessagePopup] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        if (sessionService.getUsername() === profile?.username) {
            setProfileOwner(true)
            setLabel(t('profile_connect.edit_action'))
            setLabelStyle('')
            return
        }

        setChatStatus(profile.chatStatus)
        setLabelByStatus(profile.chatStatus)
        setConnectStyle(profile.chatStatus)
    }, [profile])


    function setLabelByStatus(status: ChatStatus) {
        switch (status) {
            case ChatStatus.NotRequested:
                setLabel(t('profile_connect.connect_action'))
                break
            case ChatStatus.Requested:
            case ChatStatus.Invited:
            case ChatStatus.Accepted:
            case ChatStatus.Rejected:
            case ChatStatus.Blocked:
                setLabel(t('profile_connect.view_chat_action'))
                break
            case ChatStatus.NoInfluencer:
            case ChatStatus.NoBusinesses:
            case ChatStatus.AudienceSize:
                setLabel(t('profile_connect.cant_message'))
                break
        }
    }

    function setConnectStyle(status: ChatStatus) {
        switch (status) {
            case ChatStatus.NotRequested:
                setLabelStyle('')
                break
            case ChatStatus.Requested:
                setLabelStyle(style.waiting)
                break
            case ChatStatus.Invited:
            case ChatStatus.Accepted:
                setLabelStyle(style.responded)
                break
            case ChatStatus.Rejected:
            case ChatStatus.Blocked:
                setLabelStyle(style.blocked)
                break
            case ChatStatus.NoInfluencer:
            case ChatStatus.NoBusinesses:
            case ChatStatus.AudienceSize:
                setLabelStyle(style.cantMessage)
                break
        }
    }

    function handleClick() {
        if (profileOwner)
            toggleEditMode()
        else
            connect()
    }

    function connect() {
        if (chatStatus === ChatStatus.NotRequested)
            setOpenMessagePopup(true)
        else if ([ChatStatus.NoBusinesses, ChatStatus.NoInfluencer, ChatStatus.Blocked, ChatStatus.AudienceSize].includes(chatStatus)) {
            return
        } else {
            navigate(`/chat/${profile.username}`)
        }

    }


    function toggleEditMode() {
        if (editingMode) {
            setLabel(t('profile_connect.edit_action'))
            changeEditingMode(false)
        } else {
            setLabel(t('profile_connect.edit_done_action'))
            changeEditingMode(true)
        }
    }

    async function updateConnectStatus() {
        try {
            const chatDetails = await ChatService.getMessageDetails(profile.username)
            setChatStatus(chatDetails.status)
            setLabelByStatus(chatDetails.status)
            setConnectStyle(chatDetails.status)
        } catch (error) {
            // TODO: Show error Post
            console.log(error)
        }
    }

    function changeEditingMode(editingMode: boolean) {
        setEditingMode(editingMode)
        editModeChanged(editingMode)
    }


    return (
        <>
            <button className={`${style.connect} ${labelStyle}`} onClick={handleClick}>{label}</button>
            <MessagePopup openPopup={openMessagePopup} profileUsername={profile.username} onClose={() => setOpenMessagePopup(false)} onMessageSend={updateConnectStatus} />
        </>
    )
}
