import axios from "axios";
import ProfileView from "../profile/model/profile-view.model";
import {PasswordForgotResponse} from "./model/password-forgot.response";
import {ResetTokenValidationResponse} from "./model/reset-token-validation.response";
import {PasswordRecoverResponse} from "./model/password-recover.response";

export class PasswordService {

    private static root = '/account/password'
    private static client = axios

    static async forgotPassword(mail: string, signal: AbortSignal): Promise<PasswordForgotResponse> {
        const response = await this.client.post(
            `${this.root}/forgot`,
            {mail},
            {signal})

        return response?.data
    }

    static async validateResetToken(token: string, signal: AbortSignal): Promise<ResetTokenValidationResponse> {
        const response = await this.client.post(
            `${this.root}/proof`,
            {ref: token},
            {signal})

        return response?.data
    }

    static async recoverPassword(token: string, newPassword: string, signal: AbortSignal): Promise<PasswordRecoverResponse> {
        const response = await this.client.post(
            `${this.root}/recover`,
            {ref: token, newPassword},
            {signal})

        return response?.data
    }
}