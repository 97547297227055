import axios from 'axios'
import { INetworDetails } from './model/INetworkDetails'
import Platform from './model/Platform'
import {AccountChoice} from "./model/AccountChoice";
import {FacebookPage} from "./model/FacebookPage";

class NetworkService {

    private root = 'profile/network'
    private client = axios

    async getNetworks() : Promise<INetworDetails> {
        try {
            let response = await this.client.get(this.root)
            return response.status === 200 ? response.data : null
        } catch(error) {
            console.log(error)
            return null
        }
    }

    async connect(platform: Platform) : Promise<string> {
        let response = await this.client.post(`${this.root}/connect`, {
            platform: platform
        })
        return response.status === 200 ? response.data.oAuthUri : null
    }

    async connectInstagram(account: AccountChoice, state: string) {
        await this.client.post(`${this.root}/connect-instagram`, {
            accountId: account.id,
            pageId: account.pageId,
            state: state
        })
    }

    async connectFacebook(account: AccountChoice, state: string) : Promise<void> {
        await this.client.post(`${this.root}/connect-facebook`, {
            id: account.id,
            state: state
        })
    }

    async redirect(platform: Platform, code: string, state: string) : Promise<any> {
            let response = await this.client.get(`${this.root}/redirect`, {
                params: {
                    sauce : platform,
                    code,
                    state
                }
            })
            return response.data
    }

    async redirectFacebook(code: string, state: string) : Promise<any> {
        let response = await this.client.get(`${this.root}/redirect-facebook`, {
            params: {
                sauce : Platform.Facebook,
                code,
                state
            }
        })
        return response.data
    }

    async redirectInstagram(code: string, state: string) : Promise<any> {
        let response = await this.client.get(`${this.root}/redirect-instagram`, {
            params: {
                sauce : Platform.Instagram,
                code,
                state
            }
        })
        return response.data
    }

    async disconnect(ref: string) : Promise<boolean> {
        try {    
            let response = await this.client.delete(`${this.root}/disconnect`, {
                params: { ref }
            })
            return response.status === 200
        } catch(error) {
            console.log(error)
            return false
        }
    }

}

export default new NetworkService()