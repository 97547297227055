import React from 'react';
import style from './index.module.scss';


interface PageNumbersProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const PageNumbers: React.FC<PageNumbersProps> = ({ currentPage, totalPages, onPageChange }) => {
    const pages: JSX.Element[] = [];
    let startPage: number, endPage: number;
    const [isMobile, setMobile] = React.useState(window.innerWidth < 550);

    React.useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth < 550);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //calculate start and end pages for desktop
    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }

    //calculate start and end pages for mobile
    if (isMobile) {
        // Für kleine Bildschirme: Zeige nur 3 Seiten
        // for small screens: show only 3 pages
        if (currentPage === 1) {
            startPage = 1;
            endPage = Math.min(3, totalPages);
        } else if (currentPage === totalPages) {
            startPage = Math.max(totalPages - 2, 1);
            endPage = totalPages;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }
    } else if (totalPages <= 5) {
        //when less than 5 pages: show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // More than 5 pages
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        const isLongNumber = currentPage > 100;
        const buttonClass = `${style.pageNumberButton} ${i === currentPage ? style.currentPageButton : ''} ${isLongNumber ? style.pillShapeButton : ''}`;

        pages.push(
            <li key={i}>
                <button
                    className={buttonClass}
                    onClick={() => onPageChange(i)}
                    disabled={i === currentPage}
                >
                    {i}
                </button>
            </li>
        );
    }

    return <ul>{pages}</ul>;
};

export default PageNumbers;

