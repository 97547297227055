import React, { useState } from 'react';
import inboxService from '../../../../../../service/inbox/inbox-client.service';
import ChatDetails from '../../../../../../service/inbox/model/ChatDetails';
import useClickOutside from '../../../../../../theme/hooks/use-click-outside.component';
import style from './index.module.scss';
import { NotificationService } from '../../../../../../service/notification/notification.service';
import { NotificationType } from '../../../../../../service/notification/model/notification-type.enum';
import { ConfirmationDialog } from '../../../../../../theme/component/confirmation-dialog';
import ProfileService from '../../../../../../service/profile/profile.service';
import { useTranslation } from 'react-i18next';

type MenuProps = {
    className: string;
    chat: ChatDetails;
    onArchive: (chat: ChatDetails) => void;
    onBlock: (chat: ChatDetails) => void;
    onDelete: (chat: ChatDetails) => void;
    onReport: (chat: ChatDetails) => void;
};

export default function Menu({ className, chat, onArchive, onBlock, onDelete, onReport }: MenuProps) {
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const menuRef = useClickOutside(() => setOpenMenu(false));
    const [openDeletePopup, setOpenDeletePopup] = useState<boolean>(false);
    const [openBlockConfirmation, setOpenBlockConfirmation] = useState<boolean>(false);

    return (
        <span className={className} ref={menuRef}>
            <span className={style.menu_toggle} onClick={toggleMenu}>
                <span id='menu_toggle' />
            </span>
            <ul className={`${style.menu} ${openMenu ? '' : style.closed}`}>
                <li className={`pre-icon ${style.icon} ${style.block_icon}`} onClick={() => setOpenBlockConfirmation(true)}>
                    {t('menu.block')}
                </li>
                <li className={`pre-icon ${style.icon} ${style.report_icon}`} onClick={() => onReport(chat)}>
                    {t('menu.report')}
                </li>
                <li className={`pre-icon ${style.icon} ${style.delete_icon}`} onClick={onDeleteHandler}>
                    {t('menu.delete')}
                </li>
            </ul>
            <ConfirmationDialog
                title={t('menu.delete_chat_title')}
                payload={chat}
                dialog={t('menu.delete_chat_dialog', { chat: chat.chat })}
                open={openDeletePopup}
                onConfirm={onDeleteChat}
                onClose={() => setOpenDeletePopup(false)}
                actionButtonText={t('menu.delete')}
            />
            <ConfirmationDialog
                payload={undefined}
                onConfirm={onBlockHandler}
                title={t('menu.block_title')}
                dialog={t('menu.block_dialog', { chat: chat.chat })}
                actionButtonText={t('menu.block')}
                open={openBlockConfirmation}
                onClose={() => setOpenBlockConfirmation(false)}
            />
        </span>
    );

    function toggleMenu() {
        setOpenMenu(!openMenu);
    }

    async function onDeleteChat() {
        try {
            NotificationService.displayNotification({
                message: t('menu.delete_chat_success'),
                type: NotificationType.success
            });
            onDelete(chat);
            await inboxService.deleteChat(chat.ref);
        } catch (error) {
            NotificationService.displayNotification({
                message: t('menu.delete_chat_error'),
                type: NotificationType.failure
            });
        }
    }

    async function onArchiveHandler() {
        try {
            chat.archived = !chat.archived;
            onArchive(chat);

            await inboxService.archive(chat.chat, chat.archived);
        } catch (error) {
            console.log(error);
        }
    }

    async function onBlockHandler() {
        try {
            setOpenBlockConfirmation(false);
            NotificationService.displayNotification({
                message: t('menu.block_success'),
                type: NotificationType.success
            });
            onBlock(chat);
            await ProfileService.blockProfile(chat.chat);
        } catch (error) {
            NotificationService.displayNotification({
                message: t('menu.block_error'),
                type: NotificationType.failure
            });
        }
    }

    async function onDeleteHandler() {
        setOpenDeletePopup(true);
    }
}
