import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import InputButton from '../../theme/component/input-button'
import InputText from '../../theme/component/input-text/input-text.component'
import Panel from '../../theme/component/panel/panel.component'
import style from './index.module.scss'
import {InlineToastType} from '../../theme/component/inline-notification/model/inline-notification-type.model'
import InlineToast from '../../theme/component/inline-notification'
import {PasswordService} from "../../service/password/password.service";
import useQuery from "../../theme/hooks/use-query.component";
import {Criteria} from "../../theme/component/criteria";

export default function PasswordRecovery() {
    const navigate = useNavigate()

    const token = useQuery('token')

    const [newPassword, setNewPassword] = useState<string>('')
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('')
    const [disableButton, setDisableButton] = useState<boolean>(false)
    const [recoverySuccessful, setRecoverySuccessful] = useState<boolean>(false)

    const [showToast, setShowToast] = useState<boolean>(false)
    const [toastMessage, setToastMessage] = useState<string>('')
    const [toastType, setToastType] = useState<InlineToastType>(null)
    const [showInvalidLinkError, setShowInvalidLinkError] = useState<boolean>(false)

    const abortController = new AbortController()
    const signal = abortController.signal

    const [passwordContainsLetter, setPasswordContainsLetter] = useState<boolean>(false)
    const [passwordContainsNumberOrSpecialCharacter, setPasswordContainsNumberOrSpecialCharacter] = useState<boolean>(false)
    const [passwordContainsTenCharacters, setPasswordContainsTenCharacters] = useState<boolean>(false)
    const [passwordValid, setPasswordValid] = useState(false)


    useEffect(() => {

        async function checkToken() {
            try {
                const passwordResetTokenResponse = await PasswordService.validateResetToken(token, signal)

                if (passwordResetTokenResponse.validLink === false) {
                    setShowInvalidLinkError(true)
                    return
                }

            } catch (error) {
                setShowInvalidLinkError(true)
                return
            }
        }

        checkToken()

        return () => abortController.abort()
    }, []);

    function showErrorToast(message: string) {
        setDisableButton(false)
        setToastMessage(message)
        setToastType(InlineToastType.Failure)
        setShowToast(true)
    }

    async function recoverPassword() {
        setDisableButton(true)

        if (newPassword !== newPasswordConfirmation) {
            showErrorToast('Passwords do not match')
            return
        }

        try {
            const passwordForgotResponse = await PasswordService.recoverPassword(token, newPassword, signal)
        } catch (error) {
            showErrorToast(error.response.data?.error || 'Something went wrong')
            return
        }

        setRecoverySuccessful(true)
        setDisableButton(false)
    }

    function validateNewPassword(password: string) {
        setNewPassword(password)

        const specialChars = password.replace(/[^a-zA-Z]/g, '')
        const letters = password.replace(/[a-zA-Z]/g, '')

        const passwordContainsLetter = letters.length > 0
        const passwordContainsNumberOrSpecialCharacter = specialChars.length > 0
        const passwordContainsTenCharacters = password.length >= 10

        const passwordValid = passwordContainsLetter && passwordContainsNumberOrSpecialCharacter && passwordContainsTenCharacters

        setPasswordContainsLetter(passwordContainsLetter)
        setPasswordContainsNumberOrSpecialCharacter(passwordContainsNumberOrSpecialCharacter)
        setPasswordContainsTenCharacters(passwordContainsTenCharacters)

        setPasswordValid(passwordValid)
    }

    if (showInvalidLinkError === true) {
        return <section className='text-center'>
            <h1 className='mb1'>Password Reset</h1>
            <Panel className={style.container}>
                <div className='text-center max-width'>
                    <span className={style.icon_error}/>
                    <div className={style.title}>Invalid Link</div>
                    <span className={style.description}>
                        The recovery link is expired.<br/>
                        If you forgot your password,<br/> recover <Link to='/forgot-password'>here</Link>.
                    </span>
                    <Link to={'/login'} className={`${style.button} ${style.buttonFilled}`}>Back to Influspace</Link>
                </div>
            </Panel>
        </section>
    }

    if (recoverySuccessful === true) {
        return <section className='text-center'>
            <h1 className='mb1'>Password Reset</h1>
            <Panel className={style.container}>
                <div className='text-center max-width'>
                    <span className={style.icon_verified}/>
                    <div className={style.title}>Password Recovered</div>
                    <span className={style.description}>
                        Return to sign in <br/>
                        and log in with your new password.
                    </span>
                    <Link to={'/login'} className={`${style.button} ${style.buttonFilled}`}>Back to Influspace</Link>
                </div>
            </Panel>
        </section>
    }

    const passwordLabel = newPassword.length > 0 ? 'New Password' : undefined

    const passwordCriteria = newPassword.length > 0 ? (
        <div className={style.criteria_container}>
            <Criteria fulfilled={passwordContainsLetter}>1 letter</Criteria>
            <Criteria fulfilled={passwordContainsNumberOrSpecialCharacter}>1 number or special character (example: # ? ! &)</Criteria>
            <Criteria fulfilled={passwordContainsTenCharacters}>10 characters</Criteria>
        </div>
    ) : undefined

    return <section className='text-center'>
        <h1 className='mb1'>Password Reset</h1>
        <Panel className={style.container}>
            <div className='text-center max-width'>
                <span className={style.icon}/>
                <div className='mb2 mt1'>
                    <InlineToast show={showToast} message={toastMessage} type={toastType}/>
                </div>
                <div className='mb2'>
                    <InputText placeholder='New Password'
                               onChange={validateNewPassword}
                               isPassword={true}
                               label={passwordLabel} />
                    {passwordCriteria}
                </div>
                <div className='mb2'>
                    <InputText placeholder='Confirm Password'
                               onChange={setNewPasswordConfirmation}
                               isPassword={true}/>
                </div>
                <div className='mb2'>
                    <InputButton label='Recover Password'
                                 fill={true}
                                 onClick={recoverPassword}
                                 disabled={disableButton}/>
                </div>
                <span className={`${style.signup} mb1`}>
                    <span className={style.label}>If you still need help, get</span>
                    <Link to='/support'>Support</Link>
                  </span>
            </div>
        </Panel>
    </section>

}
