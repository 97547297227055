import React, { useState} from "react";
import {DropdownPanel} from "../../../../theme/component/dropdown-panel/dropdown-panel.component";
import style from "./index.module.scss"
import {LocationInputText} from "../../../../theme/component/location-input-text";

type LocationFilterProps = {
    onChange: (location: ILocation) => void
    open: boolean
    onOpen: (value: boolean) => void
    initialLocation: ILocation
}
export function LocationFilter({onChange, open, onOpen, initialLocation}: LocationFilterProps) {

    const [location, setLocation] = useState<ILocation>(initialLocation)



    return <DropdownPanel label={ !initialLocation ? 'Location' : initialLocation.display} open={open} onOpen={onOpen}>
        <div className={style.filter_element}>
            <LocationInputText onChange={onChange} initialLocation={location} />
        </div>
    </DropdownPanel>
}