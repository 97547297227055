import React, { forwardRef, useState } from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputDropdown from '../../../../theme/component/input-dropdown/input-dropdown.component'
import DropdownOption from '../../../../theme/component/input-dropdown/models/dropdown-option.model'
import InputLabel from '../../../../theme/component/input-label/input-label.component'
import profileService from '../../../../service/profile/profile.service'
import { TargetAge, TargetGender } from '../../../profile/component/profile-audience/model/demography.model'
import joinStyle from '../../join.module.scss'
import { useTranslation } from 'react-i18next'

function PanelAudience({ onCompletion, onReturn }: { onCompletion: () => void, onReturn: () => void }, ref: any) {
    const { t } = useTranslation()

    const [targetAge, setTargetAge] = useState<DropdownOption>(undefined)
    const [targetGender, setTargetGender] = useState<DropdownOption>(undefined)

    const ageOptions: DropdownOption[] = [
        { label: t('join.kids'), value: TargetAge.Kids },
        { label: t('join.teens'), value: TargetAge.Teens },
        { label: t('join.adults'), value: TargetAge.Adults },
        { label: t('join.elderly'), value: TargetAge.Elderly },
        { label: t('join.not_specified'), value: TargetAge.NotSpecified }
    ]

    const genderOptions: DropdownOption[] = [
        { label: t('join.female'), value: TargetGender.Female },
        { label: t('join.male'), value: TargetGender.Male },
        { label: t('join.mixed'), value: TargetGender.Mixed },
        { label: t('join.diverse'), value: TargetGender.Diverse },
        { label: t('join.not_specified'), value: TargetGender.NotSpecified }
    ]

    async function submit() {
        try {
            await profileService.setAudience(targetAge.value, targetGender.value)
        } catch (error) {
            console.log('error', error)
            return
        }

        onCompletion()
    }

    return (
        <div ref={ref}>
            <span className={joinStyle.back_button} onClick={onReturn}/>
            <InputLabel label={t('join.audience')} />
            <p className={`${joinStyle.info} mb2`}>{t('join.main_audience')}</p>
            <div className='mb2'>
                <InputDropdown options={ageOptions} onChange={setTargetAge} placeholder={t('join.select_age')} />
            </div>
            <div className='mb3'>
                <InputDropdown options={genderOptions} onChange={setTargetGender} placeholder={t('join.select_gender')} />
            </div>
            <div className='mb2'>
                <InputButton label={t('join.next')} fill={true} onClick={submit} />

            </div>
        </div>
    )
}

export default forwardRef(PanelAudience)
