import React from "react";
import Panel from "../../../../../../theme/component/panel/panel.component";
import style from "./index.module.scss";
import {
    ISupportTicketResponse,
    SupportTicketReason,
    SupportTicketType
} from "../../../../../../service/support/model/SupportTicketResponse";
import {useNavigate} from "react-router-dom";
import InputButton from "../../../../../../theme/component/input-button";
import {ButtonColor} from "../../../../../../theme/component/input-button/models/button-color.model";

type TicketDetailProps = {
    ticket?: ISupportTicketResponse
    onResolve: () => void
}
export function TicketDetailView({ ticket, onResolve }: TicketDetailProps) {
    const navigate = useNavigate();

    if (!ticket) {
        return <Panel className={style.container}>No ticket selected</Panel>;
    }

    const mailToLink = `mailto:${ticket.mail}?subject=${encodeURIComponent(`Support Answer: ${ticket.topic}`)}`;

    function Username() {
        return (
            <div>
                <strong>Creator Username: </strong>
                <span
                    onClick={() => ticket.creatorUsername && navigate(`/profile/${ticket.creatorUsername}`)}
                    className={ticket.creatorUsername ? style.clickable : ''}
                >
                    {ticket.creatorUsername || 'No Account'}
                </span>
            </div>
        );
    }

    return (
        <Panel className={style.container}>
            <div><strong>Topic:</strong> {ticket.topic}</div>
            <div><strong>Issue:</strong> {ticket.issue}</div>
            <div><strong>Reference:</strong> {ticket.ref}</div>
            <div><strong>Reason:</strong> {SupportTicketReason[ticket.reason]}</div>
            <div><strong>Mail:</strong> <a href={mailToLink}>{ticket.mail}</a></div>
            <Username />
            {ticket.reportItem && <div><strong>Report Item:</strong> {ticket.reportItem}</div>}
            <div><strong>Type:</strong> {SupportTicketType[ticket.type]}</div>
            <div><strong>Resolved:</strong> {ticket.resolved ? 'Yes' : 'No'}</div>
            <div className={style.button_container}><InputButton color={ticket.resolved ? ButtonColor.Failure : ButtonColor.Success} label={ticket.resolved ? 'Mark as not resolved' : 'Mark as resolved'} onClick={onResolve}/></div>
        </Panel>
    );
}
