import { useEffect, useRef } from 'react'

export default function useClickOutside(handler: () => void) {
    const domNode = useRef<HTMLDivElement>()

    useEffect(() => {
        function outsideHandler(event: MouseEvent) {
            const target = event?.target as HTMLDivElement

            if (!domNode?.current?.contains(target)) {
                handler()
            }
        };

        document.addEventListener('click', outsideHandler)
    
        return () => {
            document.removeEventListener('click', outsideHandler)
        }
    })

    return domNode
}