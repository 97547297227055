import React, { useState, useEffect } from 'react';
import { IProfileTile } from '../../service/search/model/profile-tile.model';
import ProfileGrid from '../../theme/component/profile-grid/profile-grid.component';
import { IOffer } from '../../service/marketplace/model/offer.model';
import { OfferOverview } from '../marketplace/component/offer/component/overview';
import style from './index.module.scss';
import { ExploreService } from "../../service/explore/explore.service";
import LoadingContent from "../../theme/layout/loading-content/loading-content.component";
import { useInfiniteScroll } from "../../theme/component/infinite-scroll/infinite-scroll.componet";
import LoadingMoreContent from "../../theme/layout/loading-spinner/loading-spinner.component";
import {useTranslation} from "react-i18next";
import {FeedEnd} from "../../theme/component/feed-end";

export default function Explore() {
  const [profiles, setProfiles] = useState<IProfileTile[]>([]);
  const [topProfiles, setTopProfiles] = useState<IProfileTile[]>([]);
  const [topOffers, setTopOffers] = useState<IOffer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [seed, setSeed] = useState('');
  const [loadingMore, setLoadingMore] = useState(false);
  const [showFeedEnd, setShowFeedEnd] = useState<boolean>(false)
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const feed = await ExploreService.getExplore();
        if (feed) {
          setProfiles(feed.profiles);
          setTopProfiles(feed.topProfiles);
          setTopOffers(feed.topOffers);
          setSeed(feed.seed);
        }
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const loadMoreProfiles = async () => {
    if (loadingMore) return;
    setLoadingMore(!showFeedEnd);
    try {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);

      const feedResponse = await ExploreService.getProfilesByPage(newPage, seed);
      setShowFeedEnd(feedResponse.length === 0)

      if (feedResponse && Array.isArray(feedResponse)) {
        setProfiles(prevProfiles => {
          return prevProfiles.concat(feedResponse.filter(newProfile =>
            !prevProfiles.some(prevProfile => prevProfile.username === newProfile.username)
          ));
        });
      }
    } catch (error) {
      console.error('Error loading more profiles', error);
    } finally {
      setLoadingMore(false);
    }
  };

  useInfiniteScroll(loadMoreProfiles, loadingMore, 'down');


    if (loading) {
        return <LoadingContent />;
    }

  return (
    <>
      <section className="mb3">
        <h2 className={style.title}>{t('explorer.top_profiles')}</h2>
        <ProfileGrid profiles={topProfiles} />
      </section>
      <section className="mb3">
        <h2 className={style.title}>{t('explorer.top_campaigns')}</h2>
        <div className={style.offerContainer}>
          {topOffers.map(offer => (
            <OfferOverview key={offer.ref} offer={offer} />
          ))}
        </div>
      </section>
      <section className="mb3">
        <h2 className={style.title}>{t('explorer.more_profile')}</h2>
        <ProfileGrid profiles={profiles} />
          {showFeedEnd && <div className={style.feed_end}><FeedEnd/></div>}
          {loadingMore && <LoadingMoreContent />}
      </section>
    </>
  );
}
