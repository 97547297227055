import React, {useEffect, useState} from "react";
import {TicketDetailView} from "./component/ticket-detail";
import {TicketList} from "./component/ticket-list";
import {ISupportTicketResponse} from "../../../../service/support/model/SupportTicketResponse";
import SupportService from "../../../../service/support/support.service";
import {NotificationService} from "../../../../service/notification/notification.service";
import {NotificationType} from "../../../../service/notification/model/notification-type.enum";
import DropdownOption from "../../../../theme/component/input-dropdown/models/dropdown-option.model";

const ticketOptions: DropdownOption[] = [
    { label: 'All Tickets', value: 'all' },
    { label: 'Resolved Tickets', value: 'resolved' },
    { label: 'Unresolved', value: 'unresolved' },
]
export function SupportManager() {
    const [tickets, setTickets] = useState<ISupportTicketResponse[]>([])
    const [selectedTicket, setSelectedTicket] = useState<ISupportTicketResponse>(undefined)
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0)
    useEffect(() => {
        fetchTickets()
    }, [selectedOptionIndex])

    async function fetchTickets() {
        try {
            const tickets = await SupportService.getTickets(ticketOptions[selectedOptionIndex].value)
            setTickets(tickets)
            setSelectedTicket(tickets.length > 0 ? tickets[0] : undefined)
        } catch (error) {
            console.log(error)
            NotificationService.displayNotification({
                type: NotificationType.failure,
                message: error.message
            })
        }
    }

    async function onResolve() {
        try {
            const ticket = await SupportService.resolveTicket(selectedTicket.ref, !selectedTicket.resolved)
            setTickets(prevTickets => prevTickets.map(t => t.ref === ticket.ref ? ticket : t));

            setSelectedTicket(ticket)
        } catch (error) {
            console.log(error)
            NotificationService.displayNotification({
                type: NotificationType.failure,
                message: error.message
            })
        }
    }

    function onSelectFilterOption(option: DropdownOption) {
        console.log(option)
        setSelectedOptionIndex(ticketOptions.findIndex(value => value.value === option.value))
    }

    return <section>
        <TicketList options={ticketOptions} onSelectFilterOption={onSelectFilterOption} selectedFilterOptionIndex={selectedOptionIndex} selectedTicket={selectedTicket} tickets={tickets} onSelect={ticket => setSelectedTicket(ticket)}/>
        <TicketDetailView  ticket={selectedTicket} onResolve={onResolve}/>
    </section>
}