import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useClickOutside from '../../../../hooks/use-click-outside.component';
import { useLocation } from 'react-router-dom';
import style from './menu.module.scss';
import SessionService from '../../../../../service/session/session.service';
import { useTranslation } from "react-i18next";
import HasMessagesContext from "../../../../../state/context/has-messages";

export default function Menu() {
    const [quickactionsOpen, setQuickactionsOpen] = useState<boolean>(false);
    const hasMessagesContext = useContext(HasMessagesContext);
    if (!hasMessagesContext) throw new Error("HasMessagesContext must be used within a HasMessagesContextProvider");
    const { hasNewMessages } = hasMessagesContext;

    const menuRef = useClickOutside(closeMenu);
    const quickationRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [currentPage, setCurrentPage] = useState<string>('');

    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            setCurrentPage('home');
        } else if (path.includes('/explore')) {
            setCurrentPage('explore');
        } else if (path.includes('/inbox')) {
            setCurrentPage('inbox');
        } else if (path.includes('/marketplace')) {
            setCurrentPage('marketplace');
        }
    }, [location]);

    function openMenu() {
        setQuickactionsOpen(true);
        const qActions = quickationRef.current as HTMLElement;

        if (qActions?.style) qActions.style.zIndex = '200';
    }

    function closeMenu() {
        setQuickactionsOpen(false);
        setTimeout(() => {
            const qActions = quickationRef.current as HTMLElement;

            if (qActions?.style) qActions.style.zIndex = '0';
        }, 400);
    }

    function navigateToHome() {
        if (SessionService.isAuthenticated()) {
            navigate('/explore');
            return;
        }

        navigate('/');
    }

    function InboxIcon() {
        return (
            <>
                <span className={`${style.inbox} ${currentPage === 'inbox' ? style.current : ''}`} />
                <div className={`${currentPage === 'inbox' ? style.lineIndicator : ''}`}></div>
                <span className={`${style.inbox_dot} ${!hasNewMessages && 'none'}`}></span>
                <span className={`${style.mobiletooltip} tooltiptext`}>Chats</span>
            </>
        );
    }

    return (
        <span ref={menuRef}>
            <span className={style.menu}>
                <span className={`${style.icon_open_menu} ${quickactionsOpen ? style.hidden : ''}`} onClick={openMenu} />
                <span className={`${style.icon_close_menu} ${quickactionsOpen ? '' : style.hidden}`} onClick={closeMenu} />
            </span>
            <span className={`${style.quickactions} ${quickactionsOpen ? '' : style.closed}`} ref={quickationRef}>
                <span className={`${style.item} ${currentPage === 'home' ? style.current : ''}`} onClick={navigateToHome}>
                    <span className={style.home} />
                </span>
                <Link to='/explore' className={`${style.item} tooltip`}>
                    <span className={`${style.explore} ${currentPage === 'explore' ? style.current : ''}`} />
                    <div className={`${currentPage === 'explore' ? style.lineIndicator : ''}`}></div>
                    <span className={`${style.mobiletooltip} tooltiptext`}>Explore</span>
                </Link>
                <Link to='/inbox' className={`${style.item} tooltip`}>
                    <InboxIcon />
                </Link>
                <Link to='/marketplace' className={`${style.item} tooltip`}>
                    <span className={`${style.marketplace} ${currentPage === 'marketplace' ? style.current : ''}`} />
                    <div className={`${currentPage === 'marketplace' ? style.lineIndicator : ''}`}></div>
                    <span className={`${style.mobiletooltip} tooltiptext`}>Marketplace</span>
                </Link>
            </span>
        </span>
    );
}
