import React from "react";
import { useTranslation } from "react-i18next";
import Panel from "../panel/panel.component";
import style from "./index.module.scss";

export function FeedEnd() {
    const { t } = useTranslation();

    return (
        <Panel className={style.container}>
            <span className={style.icon} />
            <h2 className={style.header}>{t('feed_end.header')}</h2>
            <h3 className={style.info}>{t('feed_end.info')}</h3>
        </Panel>
    );
}
