import React, {useEffect, useState} from 'react'
import Panel from "../../../theme/component/panel/panel.component";
import InputButton from "../../../theme/component/input-button";
import SessionService from "../../../service/session/session.service";
import {MailApi} from "../../../api/mail-api";
import {ButtonColor} from "../../../theme/component/input-button/models/button-color.model";
import {IMailSendable} from "../../../interface/mail-sendable.interface";

export default function Templates() {

    const [templates, setTemplates] = useState<string[]>()
    const [renderedMail, setRenderedMail] = useState<IMailSendable>()

    useEffect(() => {
        laodTemplates()
    }, []);

    async function laodTemplates() {
        try {
            const templatesResponse = await MailApi.getAllTemplates()

            setTemplates(templatesResponse.data)
        } catch (error) {
            console.log('Load Templates failed.', error)
        }
    }

    async function displayTemplate(template: string) {
        try {
            const templatesResponse = await MailApi.renderTemplate(template)

            setRenderedMail(templatesResponse.data)
        } catch (error) {
            console.log('Rendering template failed.', error)
        }
    }

    async function sendTemplate(template: string) {
        try {
            const templatesResponse = await MailApi.sendTemplateMail(template)

            // Notification Send accountConfirmation mail to @Grifting [max.eichen@icloud.com]
        } catch (error) {
            console.log('Sending template failed.', error)
        }

    }

    // Load or send

    const username = SessionService.getUsername()

    return <section>
        <Panel>
            <section>
                <h1>Mail-Templates</h1>
                <h3>E-Mails will be send to @{username} linked mail address.</h3>
                <br/>
            </section>

            <section>
                {templates && templates.map((template, index) => {
                    return <div key={index}>
                        <InputButton  label={`View ${template}`} onClick={() => displayTemplate(template)} color={ButtonColor.Success} />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <InputButton label={`Send ${template}`} onClick={() => sendTemplate(template)} />
                        <br/><br/>
                    </div>
                })}
            </section>

            <section>
                <h3>Rendered Mail</h3>
                {renderedMail && <>
                    <p>From: {renderedMail.from}</p>
                    <p>To: {renderedMail.to}</p>
                    <p>Subject: {renderedMail.subject}</p>
                    <div className={'remove-all-styles'} dangerouslySetInnerHTML={{__html: renderedMail.html}} />
                </>}
            </section>

        </Panel>
    </section>
}