import React from 'react'

import style from './required-screen.module.scss'

export default function RequiredScreenSize() {
  return (
    <section className={style.overlay}>
        <span>
            <span className={style.icon} />
            <span className={style.description}>Screen too small</span>
        </span>
    </section>
  )
}
