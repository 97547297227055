import React, {useEffect, useRef, useState} from 'react'

import style from './input-text.module.scss'

type InputTextProps = {
    placeholder?: string
    initialValue?: string
    onChange?: (value?: string) => void
    onFocus?: () => void
    onBlur?: () => void
    onEnter?: () => void
    focus?: boolean
    disabled?: boolean
    error?: string
    label?: string
    isPassword?: boolean
    maxLength?: number
    pattern?: Pattern
    onValidInput?: (valid: boolean) => void
    info?: string
    onKeyDown?: (event: any) => void
    className?: string
}

export default function InputText({
                                      placeholder,
                                      onFocus,
                                      onChange,
                                      onBlur,
                                      onEnter,
                                      focus,
                                      disabled,
                                      error,
                                      label,
                                      maxLength,
                                      isPassword = false,
                                      pattern,
                                      onValidInput,
                                      initialValue = '',
                                      info,
                                      onKeyDown,
                                      className = ''
                                  }: InputTextProps) {

    const [value, setValue] = useState<string>(initialValue)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const errorStyle = errorMessage ? style.error : ''
    const type = isPassword ? 'password' : 'text'

    const inputRef = useRef()

    function onChangeHandler(event: any) {
        const input = event?.target?.value

        if (pattern !== undefined &&
            pattern?.allowedCharacters !== undefined &&
            pattern.allowedCharacters.test(input) === false) {
            setValue(value)
            return
        }

        setValue(input)

        if (onChange !== undefined)
            onChange(input)
    }

    function onKeyDownHandler(event: any, onEnter?: Function) {
        if (event?.key === 'Escape')
            event?.target?.blur()

        if (event?.key === 'Enter' && onEnter !== undefined) {
            validation()
            onEnter()
        }

        if (onKeyDown !== undefined) {
            onKeyDown(event)
        }
    }

    function validation() {
        const validationResult = validateInput()
        if (onValidInput !== undefined)
            onValidInput(validationResult)
    }

    function validateInput(): boolean {
        if (pattern !== undefined &&
            pattern.match !== undefined &&
            pattern.match.test(value) === false) {
            setErrorMessage(pattern.error)
            return false
        }

        if (pattern !== undefined) {
            setErrorMessage('')
        }
        return true
    }

    function onBlurHandler() {
        validation()

        if (onBlur !== undefined)
            onBlur()
    }

    useEffect(() => {
        setErrorMessage(error)
        setValue(initialValue)

        if (focus) {
            const input = inputRef.current as HTMLInputElement
            input.focus()
        }

    }, [initialValue, error])


    return (
        <span className={`${style.container} ${errorStyle} ${className}`}>
      <Label label={label}/>
      <span className={style.inputContainer}>
        <input className={`${style.input} ${className}`}
               ref={inputRef}
               type={type}
               value={value}
               placeholder={placeholder}
               onChange={onChangeHandler}
               onFocus={onFocus}
               maxLength={maxLength}
               onBlur={onBlurHandler}
               onKeyDown={event => onKeyDownHandler(event, onEnter)}
               disabled={disabled}/>
        <Info info={info}/>
      </span>
      <ErrorMessage errorMessage={errorMessage}/>
    </span>
    )
}

function Label({label}: { label: string }) {
    if (label === undefined || label === '')
        return <></>

    return <span className={style.label}>{label}</span>
}

function ErrorMessage({errorMessage}: { errorMessage?: string }) {

    if (errorMessage === undefined || errorMessage === '')
        return <></>

    return <span className={style.errorMessage}>{errorMessage}</span>
}

function Info({info}: { info?: string }) {
    const [showInfo, setShowInfo] = useState<boolean>(false)

    if (info)
        return (
            <>
                <span className={style.infoIcon} onClick={() => setShowInfo(!showInfo)}/>
                <InfoMessage showInfo={showInfo} info={info}/>
            </>
        )

    return <></>
}

function InfoMessage({showInfo, info}: { showInfo: boolean, info?: string }) {
    if (!showInfo)
        return <></>

    return <span className={style.infoMessage}>{showInfo ? info : ''}</span>
}