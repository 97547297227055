import React from 'react'
import InputButton from '../../../../theme/component/input-button'
import networkService from '../../../../service/network/network.service'
import Platform from '../../../../service/network/model/Platform'
import {PayPalButtons} from "@paypal/react-paypal-js";
import {BillingService} from "../../../../service/billing/billing.service";

export default function DevNetwork() {


    async function createOrder() {
        try {
            return await BillingService.createOrder('Boost1')
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async function onApprove(data: any, actions: any) {
        try {
            console.log(data)
            await BillingService.approveOrder(data.orderID)
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <>
        <div>dev-network.component</div>
        <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
        />
        <InputButton label="Connect" onClick={doStuff}/>
    </>
  )
}

async function doStuff() {
  window.location.href = await networkService.connect(Platform.Youtube)
}
