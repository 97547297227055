import React, { useState } from 'react'
import MenuItem from './component/menu-item/menu-item.component'
import style from './settings-menu.module.scss'
import {DetailView} from "../../model/detail-view.model";

type MenuProps = {
  handleClick: (detailView: DetailView) => void,
  open: boolean
}


export default function Menu({ handleClick }: MenuProps) {
  const [selectedItem, setSelectedItem] = useState<DetailView>(DetailView.Notification)

  return (
    <ul className={`${style.menu}`}>
        <MenuItem name={DetailView.Notification} selected={selectedItem} onSelection={selectionHandler} />
      <MenuItem name={DetailView.Password} selected={selectedItem} onSelection={selectionHandler} />
      <MenuItem name={DetailView.Mail} selected={selectedItem} onSelection={selectionHandler} />
      <MenuItem name={DetailView.Billing} selected={selectedItem} onSelection={selectionHandler} />
        <MenuItem name={DetailView.Account} selected={selectedItem} onSelection={selectionHandler} />
        <MenuItem name={DetailView.Networks} selected={selectedItem} onSelection={selectionHandler} />
        <MenuItem name={DetailView.Blocked} selected={selectedItem} onSelection={selectionHandler} />

    </ul>
  )

  function selectionHandler(details: DetailView) {
    handleClick(details)
    setSelectedItem(details)
  }


}