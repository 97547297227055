import React from 'react'

import style from './dropdown-panel.module.scss'
import Panel from "../panel/panel.component";


type DropdownPanelProps = {
    children?: any
    label: string
    open: boolean
    onOpen?: (value: boolean) => void
    className?: string
}

export function DropdownPanel({ children, label, open, onOpen, className = '' }: DropdownPanelProps) {

    function FilterBody() {
        if (open === true)
            return <>{children}</>
        else
            return <></>
    }
    return <Panel className={`${style.filter_panel} ${className}`} >
        <div onClick={() => onOpen(!open)} className={style.filter_header}>{label}
            <div className={`${style.chevron} ${open ? style.open : style.closed}`}></div>
        </div>
        <FilterBody/>
    </Panel>
}