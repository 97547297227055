import React, { forwardRef } from 'react'
import InputButton from '../../../../theme/component/input-button'
import profileService from '../../../../service/profile/profile.service'
import {LocationInputText} from "../../../../theme/component/location-input-text"
import InputLabel from "../../../../theme/component/input-label/input-label.component"
import ProfileService from "../../../../service/profile/profile.service"
import { useTranslation } from 'react-i18next'
import joinStyle from "../../join.module.scss";

function PanelLocation({ onCompletion, onReturn }: { onCompletion: () => void, onReturn: () => void }, ref: any) {
    const { t } = useTranslation()

    async function onChange(location: ILocation) {
        try {
            if (!location) {
                await profileService.deleteLocation()
            } else {
                await ProfileService.setLocation(location)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function submit() {
        onCompletion()
    }

    return (
        <div ref={ref}>
            <span className={joinStyle.back_button} onClick={onReturn}/>
            <InputLabel label={t('join.location_label')} />
            <div className='mb2'>
                <LocationInputText onChange={onChange} placeholder={t('join.location_placeholder')} />
            </div>
            <div className='mb2 mt2'>
                <InputButton label={t('join.next')} fill={true} onClick={submit} />
            </div>
        </div>
    )
}

export default forwardRef(PanelLocation)
