import React, { useEffect, useState } from 'react'
import InputOption from './component/input-option/input-option.component'

import style from './input-dropdown.module.scss'
import DropdownOption from './models/dropdown-option.model'
import useClickOutside from '../../hooks/use-click-outside.component'

type InputDropdownProps = {
  label?: string // deprecated
  placeholder?: string
  options: DropdownOption[]
  onChange?: (value: DropdownOption) => void
  disabled?: boolean
  initialIndex?: number
  error?: string
}

export default function InputDropdown({ label, placeholder, options, onChange, disabled, initialIndex, error }: InputDropdownProps) {
  const [selected, setSelected] = useState<DropdownOption>(initialIndex ? options[initialIndex] : undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [animationStyle, setAnimationStyle] = useState<any>({ display: 'none' })

  const dropdownRef = useClickOutside(() => {
    setOpen(false)
    // clickHandler()
  })

  const disabledStyle = disabled ? style.disabled : ''
  const closedStyle = open === false ? style.closed : ''

  useEffect(() => {
    if (selected === undefined) {
      if (placeholder)
        setSelected({
          label: placeholder,
          value: 'default'
        })
      else if (options.length > 0)
        setSelected(options[0])
    }
  
  }, [options])

    useEffect(() => {
        if(initialIndex)
            setSelected(options[initialIndex])
    }, [initialIndex]);
  function selectionHandler(option: DropdownOption) {
    setSelected(option)
    setOpen(false)
    clickHandler()
    
    if (onChange)
      onChange(option)
  }

  function changeHandler(event: any) {
    const value = event?.target?.value

    if (onChange)
      onChange(value)
  }

  function clickHandler() {
    if (open) {
      setOpen(false)
      setTimeout(() => {
        setAnimationStyle({ display: 'none' })
        setOpen(false)
      }, 250)
    } else {
      setAnimationStyle({ })
      setTimeout(() => {
        setOpen(true)
      }, 1)
    }
  }

  return (  
    <div className={style.container}>
      <Label label={label} />
      <div className={`${style.input} ${disabledStyle} protected`} onChange={changeHandler} ref={dropdownRef}>
        <div className={style.selected} onClick={clickHandler}>{selected?.label}</div>
        <div className={`${style.dropdown} ${closedStyle}`} style={animationStyle} >
            {options.map((option) =>  <InputOption key={option?.label} onSelection={selectionHandler} value={option} open={open} />)}
        </div>
      </div>
      <ErrorMessage errorMessage={error} />
    </div>
  )
}

function Label({ label} : { label: string }) {
  if (label === undefined || label === '')
      return <></>

  return <span className={style.label}>{label}</span>
}

function ErrorMessage({ errorMessage }: { errorMessage?: string }) {

  if (errorMessage === undefined || errorMessage === '')
    return <></>
  
  return <span className={style.errorMessage}>{errorMessage}</span>
}