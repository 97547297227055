import React from 'react'

import style from './image-upload.module.scss'

type ImageUploadProps = {
    editignMode: boolean
    uploadImage: (file: File) => void
    useEdge?: boolean
}

export default function ImageUpload({ editignMode, uploadImage, useEdge }: ImageUploadProps) {
    const uploadStyle = useEdge ? style.uploadEdge : ''

    function changeHandler(event: any) {
        const file = event?.target?.files[0]

        if (file === undefined)
            return

        uploadImage(file)
    }

    if (editignMode === false)
        return <></>

    return (
        <label className={`${style.upload} ${uploadStyle}`}>
            <input type='file' accept='image/png,image/jpg,image/jpeg' onChange={changeHandler} />
        </label>
    )
}
