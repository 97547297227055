import React from "react";
import { OfferReference } from "../../../../../../../../service/chat/model/OfferReference";
import InputDropdown from "../../../../../../../../theme/component/input-dropdown/input-dropdown.component";
import DropdownOption from "../../../../../../../../theme/component/input-dropdown/models/dropdown-option.model";
import style from "./index.module.scss";
import {useTranslation} from "react-i18next";

export function OfferDropwdown({ offers, onSelect }: { offers: OfferReference[], onSelect: (option: DropdownOption) => void }) {
    const { t } = useTranslation();

    if (offers?.length < 1) {
        return <></>;
    }

    function createOptions() {
        const options = [{ label: t('offerdropdown.selectOffer'), value: 'none' }];
        options.push(...offers.map(offer => {
            return { label: offer.name, value: offer.ref };
        }));

        return options;
    }

    return (
        <div className={style.container}>
            <InputDropdown label={t('offerdropdown.referenceOffer')} onChange={onSelect} options={createOptions()} />
        </div>
    );
}
