import React from 'react'
import InfluspaceConnect from '../../../../theme/component/influspace-connect/influspace-connect.component'

export default function BrandLabel() {
    return (
        <section>
            <h1>Dev Brand Labels</h1>
            <InfluspaceConnect />
        </section>
    )
}
