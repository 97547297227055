import React from 'react'

import style from './index.module.scss'
import moment from 'moment'

type DatestampProps = {
    date: Date
}

export default function Datestamp({ date }: DatestampProps) {
    return (
        <div className={style.date}>
            <span>{moment(date).format('DD.MM.YY')}</span>
        </div>
    )
}
