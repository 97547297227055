import React from 'react';
import Panel from '../../../theme/component/panel/panel.component';
import { useTranslation } from 'react-i18next';

export default function About() {
    const { t } = useTranslation();

    return (
        <section>
            <Panel overridePadding='px5 py3 s-px2 s-py2'>
                <h1>{t('about.title')}</h1>
                <h2>{t('about.usage_benefits')}</h2>
                <p>{t('about.choose_interests')}</p>
                <br/>
                <p>{t('about.free_placement')}</p>
                <p>{t('about.free_placement_details')}</p>
                <br/>
                <p>{t('about.paid_advertisements')}</p>
                <p>{t('about.paid_advertisements_details')}</p>
                <br/>
                <p>{t('about.influencer_collaborations')}</p>
                <p>{t('about.influencer_collaborations_details')}</p>
                <br/>
                <p>{t('about.cross_placements')}</p>
                <p>{t('about.cross_placements_details')}</p>
                <br/>
                <p>{t('about.premium_benefits')}</p>
                <br/>
                <h2>{t('about.introduction')}</h2>
                <p>{t('about.introduction_details')}</p>
                <br/>
                <p>{t('about.define_content')}</p>
                <br/>
                <p>{t('about.set_preferences')}</p>
                <br/>
                <p>{t('about.chat_feature')}</p>
                <br/>
                <p>{t('about.all_awaits')}</p>
            </Panel>
        </section>
    );
}
