import React from "react";
import style from './index.module.scss'
import {useTranslation} from "react-i18next";

export function OverviewSection() {
    const { t } = useTranslation()

    return <div className={style.container} id='about'>
        <span className={style.benefit}>
            <div className={style.icon_collaboration}></div>
            <div className={style.title}>{t('overview_section_benefit_one_title')}</div>
            {t('overview_section_benefit_one_description')}
        </span>
        <span className={style.benefit}>
            <div className={style.icon_verified}></div>
            <div className={style.title}>{t('overview_section_benefit_two_title')}</div>
            {t('overview_section_benefit_two_description')}
        </span>
        <span className={style.benefit}>
            <div className={style.icon_control}></div>
            <div className={style.title}>{t('overview_section_benefit_three_title')}</div>
            {t('overview_section_benefit_three_description')}
        </span>
    </div>
}