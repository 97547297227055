import React from 'react'

import style from './index.module.scss'
import { ButtonColor } from './models/button-color.model'

type InputButtonProps = {
  label: string
  onClick?: () => void
  disabled?: boolean
  outline?: boolean
  padding?: string
  color?: ButtonColor
  fill?: boolean
  className?: string
}

export default function InputButton({ label, onClick, disabled, outline = false, padding, color = ButtonColor.Primary, fill, className = '' }: InputButtonProps) {
  const buttonStyle = outline ? style.buttonOutlined : style.buttonFilled
  const paddingStyle = padding ? padding : style.defaultPadding
  const fillStyle = fill ? style.filled : ''
  const colorStyle = style[color]

  return (
    <button className={`${buttonStyle} ${paddingStyle} ${colorStyle} ${fillStyle} ${className}`}
            onClick={onClick} 
            disabled={disabled}>{label}</button>
  )
}