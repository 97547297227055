
import React, { useEffect, useState } from 'react'
import config from '../../../../../../service/config/config'
import imageService from '../../../../../../service/image/image.service'
import { ImageType } from '../../../../../../service/image/models/image-type.model'
import ImageContent from '../../../../../../theme/component/legacy/image-content-legacy/image-content.component'
import ImageUpload from '../image-upload/image-upload.component'

import style from './avatar.module.scss'

const defaultAvatar = require('../../../../../../theme/resource/image/default/avatar_default.png')

type AvatarProps = {
    editingMode: boolean
    avatarUrl: string
    className?: string
}

export default function Avatar({ editingMode, avatarUrl, className }: AvatarProps) {
    const [avatar, setAvatar] = useState<string>('')

    useEffect(() => {
        const banner = avatarUrl ? config.host + avatarUrl : defaultAvatar
        setAvatar(banner)
    }, [avatarUrl])


    async function uploadImage(file: File) {
        try {
            const newBanner =  await imageService.uploadImage(file, ImageType.Avatar)
            console.log(config.host + newBanner.url)
            setAvatar(config.host + newBanner.url)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <span className={`${style.avatar} ${className}`}>
            <ImageContent className={style.avatar_image} src={avatar} alt='Profile Avatar' />
            <ImageUpload editignMode={editingMode} uploadImage={uploadImage} />
        </span>
    )
}
