import React, { useState, useEffect } from 'react';
import style from './input-box.module.scss';

type InputBoxProps = {
    icon: string;
    label: string;
    selected?: boolean;
    onChange?: (selected: boolean) => void;
    deselactable?: boolean
}

export default function InputBox({ icon, label, selected, onChange }: InputBoxProps) {
    const [isSelected, setIsSelected] = useState(selected || false);

    useEffect(() => {
        setIsSelected(selected || false);
    }, [selected]);

    function handleClick() {
        const newSelectedState = !isSelected;
        //setIsSelected(newSelectedState);
        if (onChange) onChange(newSelectedState);
    }

    const boxStyle = isSelected ? `${style.box} ${style.selected}` : style.box;
    const iconStyle = style.icon;
    const labelStyle = style.label;

    return (
        <div className={boxStyle} onClick={handleClick}>
            <div className={`pre-icon ${iconStyle} ${style[icon]}`}>
                <svg className={iconStyle}>
                    <use xlinkHref={`#${icon}`} />
                </svg>
            </div>
            <div className={labelStyle}>{label}</div>
        </div>
    );
}
