import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import ISettings from "../../../../../../service/settings/model/settings.model";
import { useTranslation } from 'react-i18next';
import ProfileView from "../../../../../../service/profile/model/profile-view.model";
import { BlockedTile } from "./component/blocked-tile";
import ProfileService from "../../../../../../service/profile/profile.service";
import { NotificationService } from "../../../../../../service/notification/notification.service";
import { NotificationType } from "../../../../../../service/notification/model/notification-type.enum";

type BlockedDetailsProps = {
    settings: ISettings;
};

export default function BlockedDetails({ settings }: BlockedDetailsProps) {
    const { t } = useTranslation();
    const [blockedProfiles, setBlockedProfiles] = useState<ProfileView[]>(settings.blockedProfiles);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(settings.blockedProfiles);
    }, []);

    async function onUnblock(profile: ProfileView) {
        try {
            await ProfileService.blockProfile(profile.username);
            const updatedBlockedProfiles = blockedProfiles.filter(blockedProfile =>
                blockedProfile.username !== profile.username
            );
            setBlockedProfiles(updatedBlockedProfiles);
            NotificationService.displayNotification({
                message: t('blocked_details.unblocked_success', { name: profile.displayname }),
                type: NotificationType.success
            });
        } catch (error) {
            NotificationService.displayNotification({
                message: t('blocked_details.unblocked_error'),
                type: NotificationType.failure
            });
        }
    }

    const blockedProfileTable = blockedProfiles.map(profile =>
        <BlockedTile key={profile.username} onUnblock={onUnblock} profile={profile} />
    );

    return (
        <>
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>{t('blocked_details.header')}</div>
                    <div className={style.info}>{t('blocked_details.info')}</div>
                </div>
            </div>
            <div className={style.container}>
                <div className={style.blocked_container}>
                    {blockedProfiles.length !== 0 ?  blockedProfileTable :
                    <h4>{t('blocked_details.unblocked_empty_message')}</h4>
                    }
                </div>
            </div>
        </>
    );
}
