import React, { useState, useEffect } from 'react';
import InputText from '../../../../theme/component/input-text/input-text.component';

import style from './index.module.scss';
import {useTranslation} from "react-i18next"; // Ensure this SCSS file contains the new grid styles

export default function NewsletterSection() {
    const { t } = useTranslation();

    return <div className={style.container}>
                <h2>{t('newsletter_section.title')}</h2>
                <p>{t('newsletter_section.call_to_action')}</p>
                <div className={style.section_input}>
                    <span className={style.input_email}>
                        <InputText placeholder={t('newsletter_section.email_address_field')} />
                    </span>
                    <span className={style.button_send}>{t('newsletter_section.subscribe_button')}</span>
                </div>
            </div>
}
