import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import useQuery from '../../theme/hooks/use-query.component'
import TechnicalError from "../../components/app/component/error/technical/technical-error.component";
import Loading from "../../theme/layout/loading/loading.component";
import {ThirdPartyAuthorizationService} from "../../service/third-party-authorization/third-party-authorization.service";
import sessionService from "../../service/session/session.service";

type LoginProps = {
    onLogin: (loggedIn: boolean) => void
}

export default function ThirdPartyLogin({onLogin}: LoginProps) {
    const abortController = new AbortController()
    const signal = abortController.signal

    const provider = useQuery('provider')
    const code = useQuery('code')
    const state = useQuery('state')

    console.log(provider, code)

    const navigate = useNavigate()

    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        async function redirect() {

            const thirdPartyAuthorizationResponse = await ThirdPartyAuthorizationService.authorize(provider, code, state, signal)

            const joinToken = thirdPartyAuthorizationResponse?.joinToken
            const loggedInSession = thirdPartyAuthorizationResponse?.loggedInSession

            console.log('joinToken', joinToken)
            console.log(joinToken !== undefined, 'b')
            console.log(loggedInSession !== undefined, 'a')

            if (loggedInSession !== undefined) {
                console.log('loggedInSession', loggedInSession)
                sessionService.setSessionDetails(loggedInSession)
                onLogin(true)
                navigate(`/explore`)
                return
            }

            if (joinToken !== undefined) {
                navigate(`/join?token=${joinToken}`)
                return
            }

            setError(true)
        }

        redirect()
    }, [])


    if (error)
        return <TechnicalError/>
    else
        return <Loading/>
}
