import React from "react";
import style from './search-hints.module.scss'


type SearchHintsProps = {
    hints: string[],
    selectedIndex?: number,
    open: boolean,
    onClick: (hint:string) => void
}

export default function SearchHints({hints, open, onClick, selectedIndex}: SearchHintsProps) {
  if (!open || hints.length === 0) {
    return <></>
  }

  function Suggestion({index, hint}: {index: number, hint: string}) {
      return <div key={hint}>
          <div className={selectedIndex === index ? style.selected_hint: style.hint} onClick={() => onClick(hint)}>
                        <span className={style.search_icon}/>
              {hint}
          </div>
      </div>
  }

  return <div className={style.dropdown}>
    {hints?.map((hint, index) =>  
      <Suggestion hint={hint} index={index}/>
    )}
</div>
}