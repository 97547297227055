import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Footer from '../../theme/layout/footer/footer.component';
import Chat from '../chat';
import RequireAuthorization from '../../components/app/component/require-authorizion/require-authorization.component';
import MissingError from '../../components/app/component/error/missing/missing-error.component';

export default function ChatRouter() {
    return <>
        <Routes>
            <Route path='/:username' element={<RequireAuthorization><Chat/></RequireAuthorization>}/>

            <Route path='*' element={<MissingError/>}/>
        </Routes>
        <Footer/>
    </>
}