import React from "react";
import style from './index.module.scss'
import {useNavigate} from "react-router-dom";
import {OfferReference} from "../../../../../../../../service/chat/model/OfferReference";
import {Receipient} from "../../model/receipient.model";

export function OfferRef({offer, receipient} : {offer: OfferReference, receipient: Receipient}) {

    const navigate = useNavigate()
    if (!offer) {
        return <></>
    }

    return <div className={`${style[receipient]}`} onClick={() => navigate(`/marketplace/offer/${offer.ref}`) }>
        <div className={`${style.container}`}>
            <div className={style.header}>{offer.name}</div>
            <div className={style.description}>{offer.description}</div>
        </div>
    </div>
}