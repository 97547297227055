import React from 'react'
import style from './index.module.scss'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export function ConnectCTA({border = true}: {border?: boolean}) {
    const { t } = useTranslation()



    return <div className={`${style.container} ${!border && style.border_none}`}>
        <div className={style.inner_container}>
            <div className={style.networks}>
                <span className={`${style.network} ${style.facebook}`}></span>
                <span className={`${style.network} ${style.tiktok}`}></span>
                <span className={`${style.network} ${style.twitch}`}></span>
                <span className={`${style.network} ${style.instagram}`}></span>
                <span className={`${style.network} ${style.x}`}></span>
                <span className={`${style.network} ${style.youtube}`}></span>
            </div>
            <div className={style.cta}>{t('profile_networks.connect_cta.title')}</div>
            <Link to="/guide" className={style.learn_more}>{t('profile_networks.connect_cta.learn_more')}</Link>
        </div>
    </div>
}