import Overlay from "../../../../../../theme/component/overlay/overlay.component";
import InputButton from "../../../../../../theme/component/input-button";
import React, {useState} from "react";
import {DropdownPanel} from "../../../../../../theme/component/dropdown-panel/dropdown-panel.component";
import style from './index.module.scss'
type InstagramConnectTutorialProps = {
    open: boolean
    onContinue: () => void
    onClose: () => void
}

export function InstagramConnectTutorial({ open, onContinue, onClose }: InstagramConnectTutorialProps) {
    const [openInstagramAccountTutorial, setOpenInstagramAccountTutorial] = useState<boolean>(false)
    const [openFacebookTutorial, setOpenFacebookTutorial] = useState<boolean>(false)

    return (
        <Overlay title='Connect Instagram' open={open} onClose={onClose} disableTapout={false}>
            <div className='mt2'>You have to connect your professional Instagram Account
                with a Facebook Page. Follow the easy steps displayed below.
            </div>
            <div className='mt3'>
                <DropdownPanel label={'Change Instagram Account Type'} open={openInstagramAccountTutorial} onOpen={value => setOpenInstagramAccountTutorial(value)}>
                    <div className={style.tutorial_container}>
                        Step 1: Open your Profile Menu in Instagram<br/>
                        Step 2: Go into <span className={style.marked}>Settings</span><br/>
                        Step 3: Select <span className={style.marked}>Account type & tools </span><br/>
                        Step 4: Switch to professional Account<br/>
                        Step 5: Select <span className={style.marked}>Business</span> or <span className={style.marked}>Creator</span>
                    </div>
                </DropdownPanel>
            </div>
            <div className='mt1'>
                <DropdownPanel label={'Connect Instagram Account with Facebook Page'} open={openFacebookTutorial} onOpen={value => setOpenFacebookTutorial(value)}>
                    <div className={style.tutorial_container}>
                        Step 1: Open Instagram and click on <span className={style.marked}>Edit profile</span><br/>
                        Step 2: Click on <span className={style.marked}>Page</span><br/>
                        Step 3: Follow the displayed steps<br/>
                    </div>
                </DropdownPanel>
            </div>
            <div className='mt3 float-right'><InputButton label={'Continue'} onClick={onContinue}/></div>


        </Overlay>
    )
}