import React, { forwardRef } from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputLabel from '../../../../theme/component/input-label/input-label.component'
import joinStyle from '../../join.module.scss'
import FollowerCount from "../../../profile/component/profile-networks/component/follower-count/follower-count.component"
import { useTranslation } from 'react-i18next'

function PanelNetworks({ onCompletion, onReturn }: { onCompletion: () => void, onReturn: () => void }, ref: any) {
    const { t } = useTranslation()

    return (
        <div ref={ref}>
            <span className={joinStyle.back_button} onClick={onReturn}/>
            <InputLabel label={t('join.social_media')} />
            <p className={`${joinStyle.info} mb2`}>{t('join.connect_social_media')}</p>
            <div className='mb3'>
                <FollowerCount totalFollower={"0"} editingMode={true} networks={[]} />
            </div>
            <div className='mb2'>
                <InputButton label={t('join.next')} fill={true} onClick={onCompletion} />
            </div>
        </div>
    )
}

export default forwardRef(PanelNetworks)
