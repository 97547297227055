import React, { useEffect, useState } from 'react'
import ChatDetails from '../../../../service/inbox/model/ChatDetails'
import InboxService from '../../../../service/inbox/inbox-client.service'
import Conversation from './component/conversation'
import LoadingContent from '../../../../theme/layout/loading-content/loading-content.component'

import style from './index.module.scss'
import Inbox from "../../../../service/inbox/model/Inbox";

type ConversationGridProps = {
    inbox: Inbox
    selectedConversation: ChatDetails
    onSelect: (chat: ChatDetails) => void
    disabled?: boolean
}

export default function ConversationGrid({ inbox, selectedConversation, onSelect, disabled }: ConversationGridProps) {
    const [conversations, setConversations] = useState<ChatDetails[]>(inbox.active)

    useEffect(() => {
        const conversations = inbox.active.sort(InboxService.isLatestMessage)
        setConversations(conversations)
    }, [selectedConversation, inbox])



    if (conversations === null) {
        return <span className={style.content_loading}><LoadingContent /></span>
    }

    const renderedConversation = conversations.map(conversation => {
        const selected = conversation.ref === selectedConversation?.ref

        return <Conversation chat={conversation} selected={selected} onSelect={onSelect} key={conversation.ref} disabled={disabled} />
    })

    // <NavigatorButton oppositeConversation={oppositeConversation} archived={selectedConversation.archived} onSelect={onSelect} />

    return (
        <>
            <div className='text-left ml2 mb1'>
                <span>
                    <h2>{selectedConversation.archived ? 'Archived' : 'Conversations'}</h2>
                </span>
            </div>
            {renderedConversation}
        </>
    )
}

type NavigatorButtonProps = {
    oppositeConversation: ChatDetails
    archived: boolean
    onSelect: (chat: ChatDetails) => void
}

function NavigatorButton({ oppositeConversation, archived, onSelect }: NavigatorButtonProps) {
    if (oppositeConversation === null)
        return <></>

    const navigatorStyle = archived ? style.navigator_active : style.navigator_archived

    return (
        <span className={`${style.navigator}`} onClick={() => onSelect(oppositeConversation)}>
            <span className={navigatorStyle} />
        </span>
    )

}