import React, { useEffect, useState } from 'react'
import ProfileView from '../../../../service/profile/model/profile-view.model'
import Panel from '../../../../theme/component/panel/panel.component'
import ProfileDescription from './component/profile-description/profile-description.component'
import style from './profile-details.module.scss'
import ProfileLocation from './profile-location.component'
import {useTranslation} from "react-i18next";

type ProfileDetailsProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileDetails({ profile, editingMode }: ProfileDetailsProps) {
    const [description, setDescription] = useState<string>('')
    const [location, setLocation] = useState<ILocation>()
    const { t } = useTranslation()

    useEffect(() => {
        setDescription(profile?.description)
        setLocation(profile?.location)
    }, [profile])

    function isLocationEmpty() {
        return location?.country === undefined || location?.country === ''
    }

    function isDetailsVisible() {
        return editingMode === true || description?.length > 0 || isLocationEmpty() === false
    }

    return (
        <div className={isDetailsVisible() ? '' : 'none'}>
            <Panel overridePadding={style.panel}>
                <h1 className={isDetailsVisible() ? style.title : 'none'}>{t('profile_details.title')}</h1>
                <ProfileLocation profileLocation={location} editingMode={editingMode} onUpdate={setLocation} />
                <ProfileDescription details={description} editingMode={editingMode} onUpdate={setDescription} />
            </Panel>
        </div>
    )
}
