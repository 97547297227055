import React, { useState } from 'react'
import InputText from '../../../../theme/component/input-text/input-text.component'

import style from './dev-input-text.module.scss'

export default function DevInputText() {
    const [value, setValue] = useState<string>('')
    const [validateValue, setValidatedValue] = useState<string>('')
    const [validInput, setValidInput] = useState<boolean>(false)
    const [focus, setFocus] = useState<boolean>(false)

    const digitPattern: Pattern = {
        allowedCharacters: /^[0-9]*$/,
        match: /123123/,
        error: 'Not matching 123123 go fuck yourself'
    }

    const datePattern: Pattern = {
        match: /^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.]\d{4}$/,
        error: 'Not matching DD.MM.YYYY',
        allowedCharacters: /^(?!xx\.\d{3}$|xx\.\d{4}$|xx\.\d{4}\.$|\s*$)\d{0,2}(\.\d{0,4}){0,3}\.?$/ 
        // /^(\s*$)|(\d{0,2})|(\d{0,2}.\d{2})|(\d{0,2}.\d{2}.\d{0,4}?)$/
    }
    
    return (
        <section id="placeholder">
            <div className={`${style.pseudoPanel} mb4`}>
                <h1 className='protected'>Test input-text component</h1>
                <br />
                <InputText placeholder="Text" 
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onChange={value => setValue(value)}
                        onEnter={() => alert('Pressed Enter')} />
                <br />
                <br />
                <h2>Value: {value}</h2>
                <h2>Focused: {focus ? 'Yes' : 'No'}</h2>

                <h2>input disabled</h2>
                <br />
                <br />
                <InputText placeholder='Text' disabled={true} />
                <br />
                <br />
                <h2>input error with message</h2>
                <br />
                <br />
                <InputText placeholder='Text' error='Some error occured you should really look into this. Please to this and this.' label={'Some Label'} />
                <br />
                <br />
                <h2>input format validation</h2>
                <h2>Accepts only Digits 0 - 9</h2>
                <br />
                <br />
                <InputText placeholder='Text' label='Some Label' onValidInput={setValidInput} pattern={digitPattern} onChange={setValidatedValue} />
                <br />
                <br />
                <h2>{validateValue ? validateValue : '-'}</h2>
                <h2>{validInput ? 'Yes' : 'No'}</h2>
                <br />
                <br />
                <h2>info icon + text hint</h2>
                <br />
                <br />
                <InputText placeholder='Text' label='Some Label' info='Some useful input info.' />
                <br />
                <br />
                <h2>date validation: INCOMPLETE MISSING ALLOWED CHARACTERS AND PLACEHOLDER?</h2>
                <br />
                <br />
                <InputText placeholder='DD.MM.YYYY' pattern={datePattern} label='Birthday' info='Enter your birhday. You need to be at least 18+' />
            </div>
        </section>
    )
}