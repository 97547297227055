import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import RequireAuthorization from '../require-authorizion/require-authorization.component'
import SessionService from "../../../../service/session/session.service";
import Premium from '../../../../influspace/premium';
import {ProfileType} from "../../../../service/marketplace/model/offer.model";
import BrandAccess from "../../../../influspace/brand-access";

type RequireSubscriptionProps = {
    children: any
}

export default function RequireSubscription({ children }: RequireSubscriptionProps) {
    const navigate = useNavigate()

    const [active, setActive] = useState<boolean>(false)
    const [authorized, setAuthorized] = useState<boolean>(false)

    useEffect(() => {
        function requiresSubscription() {
            if (SessionService.isActive()) {
                setActive(true)
            }

            if (SessionService.isAuthorized()) {
                setAuthorized(true)
            }
        }

        requiresSubscription()
    }, [navigate])

    if (active && authorized) {
        return children
    } else {
        if (SessionService.getMeDetails().profileType === ProfileType.INFLUENCER) {
            return (
                <RequireAuthorization>
                    <Premium />
                </RequireAuthorization>
            )
        } else {
            return (
                <RequireAuthorization>
                    <BrandAccess />
                </RequireAuthorization>
            )
        }

    }
}