import React, { useEffect } from 'react';
import './app.style.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import RequiredScreenSize from '../../theme/layout/required-screen/required-screen.component';
import ScrollToTop from '../../theme/hooks/scoll-to-top.component';
import CookieBanner from '../../theme/layout/cookie-banner/cookie-banner.component';
import DevRouter from '../dev/component/dev-router/dev-router.component';
import InfluspaceRouter from '../../influspace/influspace-router/influspace-router.component';
import ChatRouter from '../../influspace/chat-router/chat-router.component';
import Notifications from "../../theme/layout/notifications";
import Landing from "../../influspace/landing";
import '../../utility/localization.utility';
import Cookies from 'js-cookie';
import i18n from "i18next";
import { HasMessagesContextProvider } from "../../state/context/has-messages";
import { AnalyticsService } from "../../service/analytics/analytics.service";
import { ITrackingPayload } from "../../service/analytics/ITrackingPayload";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    const query = useQuery();

    useEffect(() => {
        initializeLanguage();
    }, []);

    useEffect(() => {
        const utm_source = query.get('utm_source');
        const utm_medium = query.get('utm_medium');
        const utm_campaign = query.get('utm_campaign');
        const event_source_url = window.location.href
        console.log(utm_campaign)
        if (!utm_source || !utm_medium || !utm_campaign) {
            return;
        }

        const trackingPayload = {
            utm_source,
            utm_medium,
            utm_campaign,
            event_source_url
        };

        //TODO: Conversion API reparieren
        trackPageView(trackingPayload);
    }, [query]);

    async function trackPageView(trackingPayload: ITrackingPayload) {
        try {
            await AnalyticsService.track(trackingPayload);
        } catch (error) {
            console.log('Failed to track PageView!');
        }
    }

    function initializeLanguage() {
        const cookieLanguageSet = Cookies.get('language_set');
        if (!cookieLanguageSet) {
            if (navigator.language === 'de-DE') {
                i18n.changeLanguage('de');
            }
        }
    }

    return (<>
            <ScrollToTop />
            <HasMessagesContextProvider>
                <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/dev/*' element={<DevRouter />} />
                    <Route path='/chat/*' element={<ChatRouter />} />
                    <Route path='*' element={<InfluspaceRouter />} />
                </Routes>
            </HasMessagesContextProvider>
            <CookieBanner />
            <RequiredScreenSize />
            <Notifications />
    </>);
}

export default App;
