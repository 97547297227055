import React, { useEffect, useRef, useState } from 'react'
import style from './input-slider.module.scss'

type InputSliderProps = {
  min?: number
  max?: number
  initialValue?: number
  onChange?: (value?: number) => void
  onFocus?: () => void
  onBlur?: () => void
  onEnter?: () => void
  focus?: boolean
  disabled?: boolean
  error?: string
  label?: string
  info?: string
}

export default function InputSlider({
  min = 0,
  max = 100,
  onFocus,
  onChange,
  onBlur,
  onEnter,
  focus,
  disabled,
  error,
  label,
  initialValue = 0,
  info
}: InputSliderProps) {
  const [value, setValue] = useState<number>(initialValue)
  const [dynamicStep, setDynamicStep] = useState<number>(100) // Default step
  const [errorMessage, setErrorMessage] = useState<string>('')

  const errorStyle = errorMessage ? style.error : ''

  const inputRef = useRef<HTMLInputElement>(null)

  const calculateDynamicStep = (currentValue: number) => {
    if (currentValue < 10000) return 100
    if (currentValue >= 10000 && currentValue < 50000) return 500
    return 1000
  }

  function onChangeHandler(event: any) {
    const input = Number(event?.target?.value)
    setValue(input)
    setDynamicStep(calculateDynamicStep(input))

    if (onChange) onChange(input)
  }

  function onKeyDownHandler(event: any) {
    if (event?.key === 'Escape') event?.target?.blur()

    if (event?.key === 'Enter' && onEnter) {
      onEnter()
    }
  }

  function onBlurHandler() {
    if (onBlur) onBlur()
  }

  useEffect(() => {
    setErrorMessage(error)
    setValue(initialValue)
    setDynamicStep(calculateDynamicStep(initialValue))

    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [initialValue, error, focus])

  return (
    <span className={`${style.container} ${errorStyle}`}>
      <Label label={label} />
      <span className={style.inputContainer}>
        <input
          className={style.input}
          ref={inputRef}
          type="range"
          min={min}
          max={max}
          step={dynamicStep}
          value={value}
          onChange={onChangeHandler}
          onFocus={onFocus}
          onBlur={onBlurHandler}
          onKeyDown={onKeyDownHandler}
          disabled={disabled}
        />
        <Info info={info} />
      </span>
      <ErrorMessage errorMessage={errorMessage} />
    </span>
  )
}

function Label({ label }: { label: string }) {
  if (!label) return null
  return <span className={style.label}>{label}</span>
}

function ErrorMessage({ errorMessage }: { errorMessage?: string }) {
  if (!errorMessage) return null
  return <span className={style.errorMessage}>{errorMessage}</span>
}

function Info({ info }: { info?: string }) {
  const [showInfo, setShowInfo] = useState<boolean>(false)
  if (!info) return null
  return (
    <>
      <span className={style.infoIcon} onClick={() => setShowInfo(!showInfo)} />
      <InfoMessage showInfo={showInfo} info={info} />
    </>
  )
}

function InfoMessage({ showInfo, info }: { showInfo: boolean, info?: string }) {
  if (!showInfo) return null
  return <span className={style.infoMessage}>{info}</span>
}
