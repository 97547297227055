import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import InputButton from '../../theme/component/input-button'
import InputText from '../../theme/component/input-text/input-text.component'
import Panel from '../../theme/component/panel/panel.component'
import style from './index.module.scss'
import {InlineToastType} from '../../theme/component/inline-notification/model/inline-notification-type.model'
import InlineToast from '../../theme/component/inline-notification'
import {PasswordService} from "../../service/password/password.service";
import {NotificationService} from "../../service/notification/notification.service";
import {NotificationType} from "../../service/notification/model/notification-type.enum";

export default function ForgotPassword() {
    const navigate = useNavigate()

    const [mail, setMail] = useState<string>('')
    const [disableButton, setDisableButton] = useState<boolean>(false)
    const [sendSuccessful, setSendSuccessful] = useState<boolean>(false)

    const [showToast, setShowToast] = useState<boolean>(false)
    const [toastMessage, setToastMessage] = useState<string>('')
    const [toastType, setToastType] = useState<InlineToastType>(null)

    const abortController = new AbortController()
    const signal = abortController.signal

    useEffect(() => {
        return () => abortController.abort()
    }, []);

    function showErrorToast(message: string) {
        setDisableButton(false)
        setToastMessage(message)
        setToastType(InlineToastType.Failure)
        setShowToast(true)
    }

    async function sendRecoveryMailHandler() {
        setDisableButton(true)

        try {
            const passwordForgotResponse = await PasswordService.forgotPassword(mail, signal)
        } catch (error) {
            NotificationService.displayNotification({
                type: NotificationType.failure,
                message: 'No Account found!'
            })
            setSendSuccessful(false)
            setDisableButton(false)

            return
        }

        setSendSuccessful(true)
        setDisableButton(false)
    }

    if (sendSuccessful === true) {
        return <section className='text-center'>
            <h1 className='mb1'>Password Reset</h1>
            <Panel className={style.container}>
                <div className='text-center max-width'>
                    <span className={style.icon_verified}/>
                    <div className={style.title}>Sent Recovery Mail</div>
                    <span className={style.description}>
                        Check your mails to <br/>
                        reset your account password.
                    </span>
                    <Link to={'/'} className={`${style.button} ${style.buttonFilled}`}>Back to Influspace</Link>
                </div>
            </Panel>
        </section>
    }


    return <section className='text-center'>
        <h1 className='mb1'>Password Reset</h1>
        <Panel className={style.container}>
            <div className='text-center max-width'>
                <span className={style.icon}/>
                <div className='mb2 mt1'>
                    <InlineToast show={showToast} message={toastMessage} type={toastType}/>
                </div>
                <div className='mb2'>
                    <InputText placeholder='Email address' onChange={setMail}/>
                </div>
                <div className='mb2'>
                    <InputButton label='Send Reset Mail' fill={true} onClick={sendRecoveryMailHandler}
                                 disabled={disableButton}/>
                </div>
                <span className={`${style.signup} mb1`}>
                    <span className={style.label}>If you still need help, get</span>
                    <Link to='/support'>Support</Link>
                  </span>
            </div>
        </Panel>
    </section>

}
