import React, {useState} from "react";
import ChatDetails from "../../../../../../../../service/inbox/model/ChatDetails";
import Overlay from "../../../../../../../../theme/component/overlay/overlay.component";
import {NotificationService} from "../../../../../../../../service/notification/notification.service";
import {NotificationType} from "../../../../../../../../service/notification/model/notification-type.enum";
import InputText from "../../../../../../../../theme/component/input-text/input-text.component";
import InputButton from "../../../../../../../../theme/component/input-button";
import InputTextArea from "../../../../../../../../theme/component/input-text-area/input-text-area.component";
import InputDropdown from "../../../../../../../../theme/component/input-dropdown/input-dropdown.component";
import OptionModel from "../../../../../../../../theme/component/input-dropdown/models/dropdown-option.model";
import ChatService from "../../../../../../../../service/chat/chat.service";

const options: OptionModel[] = [
    { label: 'Spam', value: 'spam'},
    { label: 'Bug', value: 'bug'},
    { label: 'Abuse', value: 'abuse'},
    { label: 'Harassment', value: 'harassment'},
    { label: 'Other', value: 'other'},
]

type ReportMenuProps = {
    chat: ChatDetails
    onClose: () => void
    open: boolean
}
export function ReportMenu({chat, onClose, open}: ReportMenuProps) {

    const [topic, setTopic] = useState<string>('')
    const [type, setType] = useState<string>('spam')
    const [issue, setIssue] = useState<string>('')

    const [titleError, setTitleError] = useState<string>(undefined)
    const [issueError, setIssueError] = useState<string>(undefined)

    return <Overlay title={'Report chat'} onClose={onCloseHandler} open={open} disableTapout={true}>

        <div className={'mb1'}><InputText error={titleError} initialValue={topic} label={'Topic'} onChange={setTopic} /></div>
        <div className={'mb1'}><InputDropdown label={'Reason'} options={options} onChange={(option) => setType(option.value)}/></div>
        <div className={'mb1'}><InputTextArea error={issueError} initialValue={issue} label={'Issue'} onChange={setIssue} /></div>

        <div className={'float-right'}>
            <InputButton label={'Send'} onClick={onSend}/>
        </div>
    </Overlay>

    function onCloseHandler() {
        setTopic('')
        setIssue('')
        setTitleError('')
        setIssueError('')

        onClose()
    }

    function validateInput(): boolean {
        if (topic.length === 0) {
            setTitleError('Title must not be empty!')
            return false;
        }

        if (topic.length <= 3) {
            setTitleError('Title too short!')
            return false;
        }

        if (issue.length === 0) {
            setIssueError('Issue must not be empty!')
            return false;
        }

        if (issue.length <= 3) {
            setIssueError('Issue too short!')
            return false;
        }
        return true
    }

    async function onSend() {
        setTitleError('')
        setIssueError('')
        try {
            if (validateInput()) {
                onCloseHandler()
                await ChatService.reportChat(chat.chat, topic, issue, type)
                NotificationService.displayNotification({
                    message: 'Chat has been reported!',
                    type: NotificationType.success,
                    duration: 3
                })
            }

        } catch (error) {
            console.log(error)
            NotificationService.displayNotification({
                message: 'Error reporting chat. Try again next time',
                type: NotificationType.failure,
                duration: 3
            })
        }
    }

}