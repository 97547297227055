import React, { useEffect, useState } from 'react';
import ProfileView from '../../../../service/profile/model/profile-view.model';
import style from './index.module.scss';
import { OfferOverview } from "../../../marketplace/component/offer/component/overview";
import InputButton from "../../../../theme/component/input-button";
import { IOffer } from '../../../../service/marketplace/model/offer.model';
import OfferCreator from '../../../marketplace/component/offer-creator';
import sessionService from '../../../../service/session/session.service';
import Panel from "../../../../theme/component/panel/panel.component";
import OfferMiniatur from "../../../../theme/resource/icon/offer_miniatur.svg";
import { useTranslation } from "react-i18next";

type ProfileAudienceProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileOffers({ profile, editingMode }: ProfileAudienceProps) {
    const [openOfferCreator, setOpenOfferCreator] = useState<boolean>(false);
    const [displayedOffers, setDisplayedOffers] = useState<IOffer[]>(profile.offers?.slice(0, 3));
    const [offers, setOffers] = useState<IOffer[]>(profile.offers);
    const { t } = useTranslation();

    useEffect(() => {
        setDisplayedOffers(profile.offers?.slice(0, 3));
    }, [profile]);

    const handleNewOffer = (newOffer: IOffer) => {
        const updatedOffers = [newOffer, ...offers];
        setOffers(updatedOffers);
        setDisplayedOffers(updatedOffers.slice(0, 3));
    };

    if (sessionService.getUsername() === profile?.username) {
        return (
            <div>
                <h1 className={style.title}>{t('profile_campaigns.title')}</h1>
                <Panel overridePadding={style.panel} onClick={() => setOpenOfferCreator(true)}>
                    <h1 className={style.offerText}>{t('profile_campaigns.start_campaign_call_to_action')}</h1>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={OfferMiniatur}
                             className={style.campaignImage}
                             alt="Offer Miniatur" />
                    </div>
                    <h1 className={style.offerTextImage}>{t('profile_campaigns.start_campaign_action')}</h1>
                </Panel>
                <div className={style.box}>
                    {offers.map(offer => <OfferOverview key={offer.ref} offer={offer} />)}
                    {profile.offers?.length > 3 ? <div className={style.footer}><MoreButton /></div> : null}
                </div>
                <OfferCreator open={openOfferCreator} onClose={(offer) => {
                    setOpenOfferCreator(!openOfferCreator);
                    if (offer) handleNewOffer(offer);
                }} />
            </div>
        );
    }

    if (editingMode || profile.offers?.length < 1) {
        return null;
    }

    function onClickMore() {
        const displayOffers = profile.offers.length === displayedOffers.length
            ? profile.offers.slice(0, 3)
            : profile.offers.slice(0, displayedOffers.length + 3);
        setDisplayedOffers(displayOffers);
    }

    function MoreButton() {
        return (
            <InputButton
                onClick={onClickMore}
                className={style.more_button}
                label={profile.offers.length === displayedOffers.length ? 'show less' : 'show more'}
            />
        );
    }

    return (
        <div>
            <h1 className={style.title}>Campaigns</h1>
            {offers.map(offer => <OfferOverview key={offer.ref} offer={offer} />)}
            {profile.offers?.length > 3 ? <div className={style.footer}><MoreButton /></div> : null}
        </div>
    );
}
