import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import sessionService from '../../../../service/session/session.service'
import Loading from '../../../../theme/layout/loading/loading.component'

type RequireAuthProps = {
    children: any
}

export default function RequireAuthentication({ children }: RequireAuthProps) {
    const navigate = useNavigate()

    const [authenticated, setAuthenticated] = useState<boolean>(false)

    useEffect(() => {
        function requiresAuthentication() {
            if (sessionService.isAuthenticated()) {
                setAuthenticated(true)
                return
            }

            const uri = encodeURIComponent(window.location.pathname + window.location.search)
            navigate(`/login?return_to=${uri}`)
        }

        requiresAuthentication()
    }, [navigate])

    if (authenticated) {
        return children
    } else {
        return <Loading />
    }
}