import React, { useEffect, useState } from 'react'
import profileService from '../../../../../../service/profile/profile.service'
import InputButton from '../../../../../../theme/component/input-button'
import { ButtonColor } from '../../../../../../theme/component/input-button/models/button-color.model'
import InputText from '../../../../../../theme/component/input-text/input-text.component'
import Overlay from '../../../../../../theme/component/overlay/overlay.component'
import ProfileLink from '../../model/profile-link.model'

type LinkEditorProps = {
  open: boolean
  onClose: () => void
  link?: ProfileLink
  username: string
  onDelete: (link: ProfileLink) => void
  onAdd: (link: ProfileLink) => void

}

const patternUrl = {
  error: 'Not a valid Link',
  match : /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
} as Pattern

const patternName = {
  error: 'Name too short',
  match : /^.{1,30}$/
} as Pattern

export default function LinkEditor({ open, onClose, link, username, onDelete, onAdd }: LinkEditorProps) {
  const [disableTapout, setDisableTapout] = useState<boolean>(false)
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(false)
  const [profileLink, setProfileLink] = useState<ProfileLink>(link)

  const deleteButton = link !== undefined ? <span><InputButton label='Delete' color={ButtonColor.Failure} onClick={deleteLink} disabled={disableDeleteButton} /></span> : <></>

  const [linkName, setLinkName] = useState<string>('')
  const [linkUrl, setLinkUrl] = useState<string>('')
  
  const [urlValid, setUrlValid] = useState<boolean>(false)
  const [nameVlaid, setNameValid] = useState<boolean>(false)


  const [linkNameError, setLinkNameError] = useState<string>('')
  const [linkUrlError, setLinkUrlError] = useState<string>('')
  


  useEffect(() => {
    setProfileLink(link)
    setLinkName(link !== undefined ? link?.name : '') 
    setLinkUrl(link !== undefined ? link?.url : '')
    setDisableTapout(link?.name !== undefined)
    setLinkNameError('')
    setLinkUrlError('')
    
  }, [link])

  function closeHandler() {
    setProfileLink(undefined)
    setLinkName('')
    setLinkUrl('')
    setLinkNameError('')
    setLinkNameError('')

    onClose()
    setDisableTapout(false)
    setDisableSaveButton(false)
    setDisableDeleteButton(false)
  }

  async function deleteLink() {
    setDisableTapout(true)
    setDisableDeleteButton(true)

    await profileService.deleteLink(profileLink)

    closeHandler()
    onDelete(profileLink)
  }

  function validateInput() {
    if (linkName.length === 0) {
      setLinkNameError('Name can not be empty')
      return false
    }

    if (patternName.match.test(linkName) === false) {
      setLinkNameError(patternName.error)
      return false
    }

    if (patternUrl.match.test(linkUrl) === false) {
      setLinkUrlError(patternUrl.error)
      return false
    }
  }

  async function saveHandler() {
    if (validateInput() === false) {
      return 
    } 

    if (profileLink !== undefined) {
      profileLink.name = linkName
      profileLink.url = linkUrl
      setProfileLink(profileLink)
      await profileService.updateLink(profileLink)
    }
    else {
      const newLink = await profileService.addLink({ name: linkName, url: linkUrl })
      onAdd(newLink)
    }
    closeHandler()
    
  }

  function onChangeName(value: string) {
    setLinkName(value)
    setDisableTapout(value.length > 0 || linkUrl.length > 0)
  }

  function onChangeUrl(value: string) {
    setLinkUrl(value)
    setDisableTapout(value.length > 0 || linkName.length > 0)
  }

  return (
    <Overlay title='Edit Link' open={open} onClose={closeHandler} disableTapout={disableTapout}>
        <div className='mt3'><InputText error={linkNameError} focus={true} label='Name' placeholder='My YouTube Channel' initialValue={linkName} onChange={onChangeName} onEnter={saveHandler} /></div>
        <div className="mt2"><InputText error={linkUrlError} label='Address' placeholder={`https://youtube.com/${username}`} initialValue={linkUrl} onChange={onChangeUrl} onEnter={saveHandler} /></div>
        <div className="mt3">
          {deleteButton}
          <span className='float-right'><InputButton label='Save' onClick={saveHandler} disabled={disableSaveButton} /></span>
        </div>
    </Overlay>
  )
}

