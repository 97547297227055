export enum JoinViews {
    Join = 'join', // account creation
    Account = 'account', // are you an influencer or brand? What name should be shown on your profile?
    Collaborations = 'collaborations', // what are you looking for?
    Audience = 'audience', // whats your audience?
    Keywords = 'keywords', // what are your doing?
    Location = 'location', // this is entirely optional, only as precise as you want to share - This information is optional but it helps local companies to find influencer for their compaigns.
    Description = 'description', // tell us about you
    Networks = 'networks',
    Avatar = 'avatar',
    VerificationReminder = 'verification_reminder' // verify your mail address to start now
}