import React, { useEffect, useState } from 'react'
import KeywordTag from './component/keyword-tag.component'
import { KeywordTextArea } from './component/keyword-textarea.component'
import { IKeyword } from './model/profile-keyword.model'
import style from './profile-keywords.module.scss'
import profileService from "../../../../../../service/profile/profile.service";

type ProfileKeyWordsProps = {
  keywords: IKeyword[]
  editingMode: boolean
  onChange: (keywords: IKeyword) => void
  onDelete: (keywords: IKeyword) => void
}

export default function ProfileKeywords({editingMode, keywords, onChange, onDelete}: ProfileKeyWordsProps) {

  const [keywordTags, setKeywordTags] = useState<JSX.Element[]>()

  function onChangeHandler(keyword: IKeyword) {
    onChange(keyword)
  }

  useEffect(() => {
    const renderedKeywords = keywords?.map((keyword) => <KeywordTag onChange={onChangeHandler}
                                                                    key={keyword.ref}
                                                                    onDelete={onDelete}
                                                                    keyword={keyword}
                                                                    editingMode={editingMode}/>)

    setKeywordTags(renderedKeywords)
  }, [keywords, editingMode])

  if (editingMode === true) {
    return (
      <div className='l-12 mt1'>
        <span className="l-10 m-12">
          <span className={`l-3 m-12 mb1 ${style.label}`}>Keywords</span>
          <KeywordTextArea hintService={profileService} max={10} onChange={onChangeHandler}>{keywordTags}</KeywordTextArea>
        </span>
      </div>  
    )

  } else if (keywords?.length > 0) {
    return (
        <div>
          {/* <span className={`l-2 m-12 vertical-top mt1 ${style.keywords_label}`}>Keywords</span> */}
          <span className={'l-10 m-12'}>{keywordTags}</span>
        </div>
    )
  } else {
    return <></>
  }

}