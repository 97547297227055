import React, { forwardRef, useState } from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputCheckbox from '../../../../theme/component/input-checkbox/input-checkbox.component'
import InputLabel from '../../../../theme/component/input-label/input-label.component'

import style from './panel-collaborations.module.scss'
import joinStyle from '../../join.module.scss'
import profileService from '../../../../service/profile/profile.service'
import { useTranslation } from 'react-i18next'

function PanelCollaborations({ onCompletion }: { onCompletion: () => void }, ref: any) {
    const { t } = useTranslation()

    const [freeProductPlacement, setFreeProductPlacement] = useState<boolean>(true)
    const [productPlacement, setProductPlacement] = useState<boolean>(true)
    const [influencerCollaboration, setInfluencerCollaboration] = useState<boolean>(true)

    async function submit() {

        try {
            await profileService.saveCollaborations({ freeProductPlacement, productPlacement, influencerCollaboration })
        } catch (error) {
            console.log('error', error)
            return
        }

        onCompletion()
    }

    return (
        <div className={style.make_big} ref={ref}>
            <div className='mb2'>
                <InputLabel label={t('join.collaborations')} />
                <p className={joinStyle.info}>{t('join.types_of_collaborations')}</p>
            </div>
            <div className='mb2'>
                <InputCheckbox label={t('join.free_product_placement')} checked={freeProductPlacement} onChange={setFreeProductPlacement} />
            </div>
            <div className='mb2'>
                <InputCheckbox label={t('join.paid_promotions')} checked={productPlacement} onChange={setProductPlacement} />
            </div>
            <div className='mb2'>
                <InputCheckbox label={t('join.influencer_collaborations')} checked={influencerCollaboration} onChange={setInfluencerCollaboration} />
            </div>
            <div className='mb2 mt3'>
                <InputButton label={t('join.next')} fill={true} onClick={submit} />
            </div>
        </div>
    )
}

export default forwardRef(PanelCollaborations)
