import style from './slide-show.module.scss'

import React from 'react'
import Pagination from '../pagination/pagination.component'

type SlideShowProps = {
    slides: string[]
}


export default function SlideShow({ slides }: SlideShowProps) {
    // const slideDuration = 4000

    // animate slides from left to right https://codepen.io/bcarvalho/pen/gWPvJB

    return (
        <div className={style.container}>
            <div className={`${style.navigate} ${style.navigate_left}`} onClick={slideBackward}></div>
            <div className={`${style.navigate} ${style.navigate_right}`} onClick={slideForward}></div>

            

            <div className={style.pagination}>
                <Pagination pages={5} complete={2} />
            </div>
        </div>
    )

        function slideForward() {
            console.log('slideForward')
        }

        function slideBackward() {
            console.log('slideBackward')
        }
}
