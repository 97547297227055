import {IWebMessage} from './model/web-message.interface';
import SessionService from '../session/session.service';
import Config from '../config/config';

export class WebsocketService {

    private static socket: WebSocket = null
    private static subscriptions: Function[] = []

    private static initializeWebsocket() {
        const token = SessionService.getSessionDetails().token

        this.socket = new WebSocket(Config.wsRoot, [token])

        this.socket.onmessage = (event) => {
            try {
                const webMessage: IWebMessage = JSON.parse(event.data)

                this.subscriptions.forEach(subscription => subscription(webMessage))
            } catch (error) {
                console.log(error)
            }
        }

        this.socket.onclose = () => {
            this.socket = null
        }

        this.socket.onerror = (error) => {
            console.log('websocket error', error)
        }
    }

    static subscribe(callback: (webMessage: IWebMessage) => void) {
        this.subscriptions.push(callback)

        if (this.socket === null) {
            this.initializeWebsocket()
        }
    }

    static unsubscribe(callback: Function) {
        console.log(callback.name)
        this.subscriptions = this.subscriptions.filter(subscription => subscription !== callback)

        if (this.subscriptions.length === 0) {
            this.socket.close()
        }
    }

    static sendMessage(message: IWebMessage) {
        if (this.socket === null) {
            this.initializeWebsocket()
        }

        this.socket.send(JSON.stringify(message))
    }

}