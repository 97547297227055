import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditBlog from './component/EditBlog.component';
import { Link, useNavigate } from 'react-router-dom';
import ImageContent from "../../../../../theme/component/image-content/image-content.component";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null); // State to hold the selected blog for editing
    const navigate = useNavigate();


    useEffect(() => {
        axios.get('/blog')
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the blog posts!', error);
            });
    }, []);

    const navigateToBlog = (ref: any) => {
        navigate(`/blogs/${ref}`);
    };

    const handleDelete = (ref: any) => {
        axios.post(`/blog/delete/${ref}`)
            .then(response => {
                console.log('Blog deleted successfully!');
            })
            .catch(error => {
                console.error('There was an error deleting the blog post!', error);
            });
    };

    const handleEdit = (blog: any) => {
        setSelectedBlog(blog);
    };

    const handleCloseModal = () => {
        setSelectedBlog(null);
    };

    return (
        <section >
            <h1>Blog Management</h1>
            <div><Link to='/backoffice/createBlog'>Create Blog</Link></div>
            <table>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Abstract</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {blogs.map(blog => (
                        <tr key={blog.ref} >
                            <td>
                                <ImageContent url={`https://influspace.com/api/image?ref=${blog.image}`} preview={undefined} />
                            </td>
                            <td>{blog.title}</td>
                            <td>{blog.abstract}</td>
                            <td>{blog.status}</td>
                            <td>
                                <button onClick={() => handleEdit(blog)}>Edit</button>
                                <button onClick={() => handleDelete(blog.ref)}>Delete</button>
                                <button onClick={() => navigateToBlog(blog.ref)}>Details</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                {selectedBlog && <EditBlog blog={selectedBlog} onClose={handleCloseModal} />}
            </table>
        </section>
    );
};

export default BlogList;
