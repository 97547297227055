import React, { useEffect, useState } from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import ProfileView from '../../service/profile/model/profile-view.model'
import ProfileHeader from './component/profile-header/profile-header.component'
import ProfileService from '../../service/profile/profile.service'

import ProfileError from '../../components/app/component/error/profile/profile-error.component'
import TechnicalError from '../../components/app/component/error/technical/technical-error.component'
import LoadingContent from '../../theme/layout/loading-content/loading-content.component'

import style from './index.module.scss'
import ProfileNetwork from './component/profile-networks/profile-network.component'
import ProfileCollaboration from './component/profile-collaboration/profile-collaboration.component'
import ProfileDetails from './component/profile-details/profile-details.component'
import ProfileLinks from './component/profile-links/profile-link.component'
import ProfileAudience from './component/profile-audience/profile-audience.component'
import ProfileOffers from "./component/profile-offers";
import ProfileRecommendations from "./component/profile-recommendations";
import sessionService from '../../service/session/session.service'
import SessionService from "../../service/session/session.service";
/*
  <div class="main animate fade-up">
    {{> profileDetails this}}
    {{> profileAudience this}}
  </div>
  <div class="sidebar animate fade-up">
    {{> profileNetworks this}}
    {{> profileCollaboration this}}
    {{> profileLinks this}}
  </div>
  {{> profileLocationEditor this}}
  {{> profileLinkEditor this}}
  */

export default function Profile() {
    const { username } = useParams();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 800);

    const [profile, setProfile] = useState<ProfileView>()

    const [editingMode, setEditingMode] = useState<boolean>(false)

    const [notFound, setNotFound] = useState<boolean>(false)
    const [serversideError, setServersideError] = useState<boolean>(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (SessionService.getSessionDetails().username !== username && !SessionService.isAuthorized())
            navigate('/explore')
        const handleResize = () => setIsMobile(window.innerWidth <= 800);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal

        function resetErrors() {
            setNotFound(false)
            setServersideError(false)
        }

        async function fetchProfile() {
            try {
                resetErrors()

                const profile = await ProfileService.getProfile(username, signal)

                setProfile(profile)
            } catch (error) {
                const response = error.toJSON()

                if (response.status === 404)
                    setNotFound(true)

                if (response.status >= 500)
                    setServersideError(true)
            }
        }

        fetchProfile()


        if (profile?.username !== sessionService?.getUsername()) {
            setEditingMode(false)
        }

        return () => abortController.abort()


    }, [username])

    if (notFound)
        return <ProfileError username={username} />



    if (serversideError)
        return <TechnicalError />

    if (profile === undefined)
        return <LoadingContent />

    return (
        <section>
            {isMobile ? (
                <div className={style.profileMobile}>
                    <ProfileHeader profile={profile} editModeChanged={setEditingMode}/>
                    <div className={style.main}>
                        <ProfileDetails profile={profile} editingMode={editingMode}/>
                        <ProfileNetwork profile={profile} editingMode={editingMode}/>
                        <ProfileAudience profile={profile} editingMode={editingMode}/>
                        <ProfileCollaboration profile={profile} editingMode={editingMode}/>
                        <ProfileLinks profile={profile} editingMode={editingMode}/>
                        <ProfileRecommendations profile={profile} editingMode={editingMode}/>
                        <ProfileOffers profile={profile} editingMode={editingMode}/>
                    </div>
                    <br className='clear'/>
                </div>
            ) : (
                <div className={style.profile}>
                    <ProfileHeader profile={profile} editModeChanged={setEditingMode}/>
                    <div className={style.main}>
                        <ProfileDetails profile={profile} editingMode={editingMode}/>
                        <ProfileAudience profile={profile} editingMode={editingMode}/>
                        <ProfileOffers profile={profile} editingMode={editingMode}/>
                    </div>
                    <div className={style.sidebar}>
                        <ProfileNetwork profile={profile} editingMode={editingMode}/>
                        <ProfileCollaboration profile={profile} editingMode={editingMode}/>
                        <ProfileLinks profile={profile} editingMode={editingMode}/>
                        <ProfileRecommendations profile={profile} editingMode={editingMode}/>
                    </div>
                    <br className='clear'/>
                </div>
            )}
        </section>
    )
}
