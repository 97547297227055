import { InlineToastType } from './model/inline-notification-type.model'

import style from './index.module.scss'
import React from 'react'

type InlineNotificationProps = {
    show: boolean
    type: InlineToastType
    stay?: boolean
    duration?: number
    message: string
    centered?: boolean
    onClose?: () => void
}

export default function InlineNotification({
    show,
    type,
    stay,
    duration,
    onClose = () => {},
    message,
    centered
}: InlineNotificationProps) {
    const textAlignment = centered ? 'text-center' : 'text-left'

    if (show) {
        setTimeout(() => onClose(), duration)
        return (
            <div className={`${style.inline_toast} ${style[`type_${type}`]} ${textAlignment}`}>
                <span className={`${style[`icon_${type}`]} pre-icon ${style.pre_icon} ${style.content}`}>{message}</span>
            </div>
        )
    }

    return <></>
}
