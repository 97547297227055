import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tile from './component/tile/index.component';
import { Blog } from './component/module/blog.model';
import style from './blog.module.scss';
import {useTranslation} from "react-i18next";

type TileSize = 'big' | 'medium' | 'small';

interface TileProps {
    size: TileSize;
    blog: Blog;
}

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        axios.get('/blog/newest')
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the blog posts!', error);
            });
    }, []);

    const formatCreatedAtDate = (createdAt: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(createdAt).toLocaleDateString(undefined, options);
    };

    return (
        <section className={`${style.blogContainer}`}>
            <h2>{t('blog.title')}</h2>
            <div className={`${style.gridLayout}`}>
                {blogs.map((blog, index) => {
                    let size: TileSize;
                    if (index === 0) {
                        size = 'big';
                    } else if (index >= 1 && index <= 4) {
                        size = 'medium';
                    } else {
                        size = 'small';
                    }
                    return (
                        <Tile
                            key={blog.ref}
                            size={size}
                            blog={{
                                ...blog,
                                createdAt: formatCreatedAtDate(blog.createdAt),
                            }}
                        />
                    );
                })}
            </div>
        </section>
    );
};

export default BlogList;
