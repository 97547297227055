import { useCallback, useEffect, useState } from 'react';

// Adding a type for the callback function for clarity
type CallbackFunction = () => void;

export const useInfiniteScroll = (
    callback: CallbackFunction,
    isLoading: boolean,
    direction: 'up' | 'down' = 'down'
): [boolean, string, string] => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [scrollDirection, setScrollDirection] = useState('down');

    const handleScroll = useCallback(() => {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        const threshold = document.documentElement.offsetHeight - window.innerHeight - 250;

        // Detect scroll direction
        if (currentScrollPosition > lastScrollTop) {
            setScrollDirection('down');
        } else {
            setScrollDirection('up');
        }

        // Update last scroll position
        setLastScrollTop(currentScrollPosition);

        if (currentScrollPosition >= threshold && !isLoading && scrollDirection === 'down') {
            callback();
        }
    }, [callback, isLoading, lastScrollTop, scrollDirection]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return [isLoading, scrollDirection, direction];
};
