import React from 'react'
import config from '../../../service/config/config'
import numberFormatService from '../../../service/number-format.service'
import Panel from '../panel/panel.component'

import style from './profile-tile.module.scss'
import {
    IKeyword
} from '../../../influspace/profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model'
import {IProfileTile} from '../../../service/search/model/profile-tile.model'
import KeywordTag from './keyword-tag.component'
import {useNavigate} from 'react-router-dom'
import ImageContent from '../image-content/image-content.component';

import defaultAvatar from '../../resource/image/default/avatar_default.png'
import defaultBanner from '../../resource/image/default/banner_default.png'
import {PremiumBadge} from "../premium-badge";
import ICollaboration from "../../../influspace/profile/component/profile-collaboration/model/collaboration.model";
import {ProfileType} from "../../../service/marketplace/model/offer.model";

type ProfileTileProps = {
    profile: IProfileTile
    showReject?: boolean
    labelOverride?: string
}

export default function ProfileTile({ profile, showReject, labelOverride }: ProfileTileProps) {
    let reject = showReject ? <Reject username={profile.username} /> : undefined
    const navigate = useNavigate()
    //To-Do update with all Profiles
    // Creating an array from the mainNetwork string
    const networksArray = profile?.mainPlatform ? [profile?.mainPlatform] : [];
    const testNetworks = [
        "youtube",
        "twitter",
        "tiktok",
        "twitch",
        "instagram"
    ];

    return (
        <Panel className={style.card} overrideBorderradius={style.card} overridePadding={style.card}
            overrideMargin={style.card}>
            <TypeTag profileType={profile.type}/>
            <div onClick={() => navigate(`/profile/${profile.username}`)}>
                {/*<PlatformBadge mainPlatform={profile?.mainPlatform} />*/}
                <Banner url={profile?.bannerUrl} preview={profile?.bannerPreview} />
                <div className={style.avatar_background}></div>
                <Avatar url={profile?.avatarUrl} preview={profile?.avatarPreview} />
                <Follower follower={profile?.follower} />
                <Name displayname={profile?.displayname} premium={profile.premium}/>
                <Networks networks={profile?.networks
                    ?.map(network => network.platform)
                    .filter((value, index, self) => self.indexOf(value) === index)} />
                <Collaborations collaborations={profile?.collaborations} />
            </div>
            <Keywords keywords={profile.keywords} />
            {reject}
        </Panel>
    )
}

function PlatformBadge({ mainPlatform }: { mainPlatform?: string }) {

    if (mainPlatform)
        return (
            <span className={style.profile_badge}>
                <span className={style[mainPlatform]} />
            </span>
        )

    return <></>
}

function Banner({ url, preview }: { url?: string, preview?: string }) {
    // const banner = url ? config.host + url : defaultBanner

    return (
        <span className={style.banner}>
            {/* <img className='skeleton' src={banner} alt='User Banner'/> */}
            <ImageContent className='skeleton' url={url} preview={preview} height='110px' width='100%' defaultImage={defaultBanner} />
        </span>
    )
}

function Avatar({ url, preview }: { url?: string, preview?: string }) {
    const avatar = url ? config.host + url : defaultAvatar

    return (
        <span className={style.avatar}>
            <img className='skeleton' src={avatar} alt='' />
            {/* <ImageContent className='skeleton' url={url} preview={preview} height='13.2rem' width='13.2rem' defaultImage={defaultAvatar}/> */}
        </span>
    )
}

function Name({ displayname, premium }: { displayname: string, premium: boolean }) {

    return <div>
        <span className={style.name}>{displayname}<PremiumBadge premium={premium}/></span>
    </div>
}

function Follower({ follower }: { follower?: number }) {
    const renderedFollower = numberFormatService.renderNumber(follower)

    return <span className={style.follower}>{renderedFollower}</span>
}
function Networks({ networks = [] }: { networks?: string[] }) {
    // List of all possible networks and their corresponding CSS classes
    const availableNetworks: { [key: string]: string } = {
        youtube: 'youtube',
        twitter: 'twitter',
        tiktok: 'tiktok',
        twitch: 'twitch',
        instagram: 'instagram'
    };

    return (
        <div className={style.platform_container}>
            {networks.map(network => {
                if (availableNetworks[network]) {
                    // This checks if the network key exists in the availableNetworks
                    return <div className={style.platform_badge}>
                                <div key={network} className={style[network]} />
                            </div>;
                }
                return null; // Return nothing if the network is not included
            })}
        </div>
    );
}

function Collaborations({ collaborations = {
    freeProductPlacement: true,
    productPlacement: true,
    influencerCollaboration: true}
}: { collaborations?: ICollaboration }) {
    // Checks if specific keywords are in the collaborations array
    const showProductPlacement = collaborations.productPlacement;
    const showFreeProductPlacement = collaborations.freeProductPlacement;
    const showInfluencerCollaboration = collaborations.influencerCollaboration;

    return (
        <div className="collaboration_container">
            <div className="info_item_labels">
                {showInfluencerCollaboration && <Icon icon="influencer" name="Influencer Collaborations" show={showInfluencerCollaboration} />}
                {showProductPlacement && <Icon icon="product_placement" name="Product Placement" show={showProductPlacement} />}
                {showFreeProductPlacement && <Icon icon="free_placement" name="Free Product Placement" show={showFreeProductPlacement} />}
            </div>
        </div>
    );
}

function TypeTag({profileType}: { profileType: ProfileType }) {
    const isBrand = profileType === ProfileType.BRAND
    return <span className={`${style.type_tag} tooltip`} >
        <span className={'tooltiptext'}>{ isBrand ? 'Brand' : 'Influencer' }</span>
        <span className={isBrand ? `${style.type_tag_brand} tooltip` : `${style.type_tag_influencer} tooltip`} />
    </span>
}



function Keywords({keywords}: { keywords: IKeyword[] }) {
    // Sort keywords by the length of the word property in ascending order
    const sortedKeywords = keywords.sort((a, b) => {
        if (a.word && b.word) return a.word.length - b.word.length;
        return 0; // This handles cases where the word may be undefined
    });

    return <div className={style.keywords}>
        {sortedKeywords.slice(0, 4).map((keyword, index) =>
            <div  key={keyword.ref}><KeywordTag keyword={keyword} /></div>
        )}
    </div>
}

function Icon({ name, icon, show }: { name: string, icon: string, show: boolean }) {
    if (show) {
        return (
            <div className='tooltip'>
                <div className={`pre-icon ${style.icon} ${style[icon]}`}>
                </div>
            </div>
        );
    } else {
        return <div>
        </div>;
    }
}



function Reject({ username }: { username: string }) {

    return <span className={style.reject_button}>Reject</span>
}
