import axios from 'axios'
import {IOffer, IOffersResponse} from './model/offer.model'
import {IOfferRequest} from "./model/offer-request.model";
import {IApplication} from "./model/application.model";
import {OfferFilter} from "./model/offer-filter.model";

class MarketplaceService {

    private root = '/marketplace'
    private client = axios

    public async getOffers(filter: OfferFilter): Promise<IOffersResponse> {
        const response = await this.client.post(`${this.root}/offers`, filter)
        return response?.data
    }

    public async saveOffer(offerRequest: IOfferRequest): Promise<IOffer> {
        const response = await this.client.post(`${this.root}/offer`, offerRequest)
        return response?.data
    }

    public async bookmarkOffer(offer: IOffer): Promise<void> {
        const response = await this.client.post(`${this.root}/offer/bookmark`, {offerRef: offer.ref})
        return response?.data
    }


    async apply(offer: IOffer, text: string): Promise<IApplication> {
        const response = await this.client.post(`${this.root}/offer/apply`, {
            offerRef: offer.ref,
            text
        })
        return response?.data
    }

    async getOffer(offerRef: string, signal?: AbortSignal): Promise<IOffer> {
        const response = await this.client.get(`${this.root}/offer/${offerRef}`, { signal })
        return response?.data
    }

    async deleteOffer(offerRef: any) {
        const response = await this.client.delete(`${this.root}/offer/${offerRef}`)
        return response?.data
    }

    async deleteApplication(offerRef: string) {
        const response = await this.client.delete(`${this.root}/offer/application/${offerRef}`)
        return response?.data
    }

    async updateOffer(offerRef: string, offer: IOfferRequest): Promise<IOffer> {
        const response = await this.client.patch(`${this.root}/offer/${offerRef}`, offer)
        return response?.data
    }

    public async getKeywordHints(hint: string): Promise<string[]> {
        const response = await this.client.get(`${this.root}/keyword-hint?query=${hint}`)
        return response.data
    }
}

export default new MarketplaceService()