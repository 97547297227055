import React, { useEffect } from "react";
import style from "./index.module.scss"

type BookmarkProps = {
    enabled: boolean,
    onClick?: () => void,
    className?: string  // Diese Klasse wird benutzt, um die Standard-Klassen zu überschreiben
}

export function Bookmark({ enabled, onClick, className }: BookmarkProps) {
    useEffect(() => {

    }, [enabled]);

    return (
        <span
            id='bookmark'
            onClick={onClick}
            className={`${enabled ? style.bookmark_enabled : style.bookmark_disabled} ${className || ''} tooltip `}
        >
            <span className={`${style.bookmark_tooltip} tooltiptext`}>Bookmark</span>
        </span>
    );
}
