import React from 'react'
import { Link } from 'react-router-dom'
import sessionService from '../../../../../service/session/session.service'
import Panel from '../../../../../theme/component/panel/panel.component'

import style from '../error.module.scss'

export default function PermissionError() {
    const returnTo = sessionService.isAuthorized() ? '/explore' : '/'

    return (
        <Panel className={style.panel}>
            <span className={style.icon} />
            <div className={style.title}>Insufficent Permission</div>
            <span className={style.description}>
                Sorry, you are not authorized.<br />
                Return back to Influspace.
            </span>
            <Link to={returnTo} className={`${style.button} ${style.buttonFilled}`}>Back to Influspace</Link>
        </Panel>
    )
}
