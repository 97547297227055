import React from 'react'
import { Link } from 'react-router-dom'
import SessionService from '../../../../../service/session/session.service'
import Panel from '../../../../../theme/component/panel/panel.component'

import style from '../error.module.scss'

export default function TechnicalError() {
    const returnTo = SessionService.isAuthorized() ? '/explore' : '/'

    return (
        <Panel className={style.panel}>
            <span className={style.icon} />
            <div className={style.title}>Technical Problems</div>
            <span className={style.description}>
                Some technical error occured.<br />
                Return back to Influspace.
            </span>
            <Link to={returnTo} className={style.buttonOutlined}>Back to Influspace</Link>
            <Link to='/support' className={style.buttonFilled}>Open Ticket</Link>
        </Panel>
    )
}
