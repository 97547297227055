import React from "react";
import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Navigation() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <section className={style.section}>
            <span className={style.decoration} />
            <div className={style.nav_bar}>
                <span className={style.logo_container}>
                    <span className={style.logo_inner_container}>
                        <span className={style.icon} />
                        <span className={style.logo_influspace}>{t('hero_section_appname')}</span>
                    </span>
                </span>
                <span className={style.right}>
                    <span className={style.navbar_option_container}>
                        <span className={style.navbar_option} onClick={() => scrollToSection('about')}>{t('hero_section_about')}</span>
                        <span className={style.navbar_option} onClick={() => scrollToSection('features')}>{t('hero_section_features')}</span>
                        <span className={style.navbar_option} onClick={() => scrollToSection('pricing')}>{t('hero_section_pricing')}</span>
                        <span className={style.navbar_option} onClick={() => scrollToSection('contact')}>{t('hero_section_contact')}</span>
                    </span>
                    <span className={style.auth_container}>
                        <span className={`${style.main_button} ${style.signup_buttonHeader}`} onClick={() => navigate('/join')}>{t('hero_section_join')}</span>
                        <span className={`${style.main_button} ${style.login_button}`} onClick={() => navigate('/login')}>{t('hero_section_login')}</span>
                    </span>
                </span>
            </div>
        </section>
    );
}
