import React, {useEffect, useState} from "react";
import {DropdownPanel} from "../../../../theme/component/dropdown-panel/dropdown-panel.component";
import {IKeyword} from "../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import {KeywordTextArea} from "../../../profile/component/profile-audience/component/profile-keywords/component/keyword-textarea.component";
import style from "./index.module.scss"
import {v4 as uuid} from "uuid";
import KeywordTag from "../../../profile/component/profile-audience/component/profile-keywords/component/keyword-tag.component";
import marketplaceService from "../../../../service/marketplace/marketplace.service";

type KeywordFilterProps = {
    onChange: (keywords: IKeyword[]) => void
    open: boolean
    onOpen?: (value: boolean) => void
    initialKeywords: IKeyword[]
}
export function KeywordFilter({onChange, open, onOpen, initialKeywords}: KeywordFilterProps) {

    const [keywordTags, setKeywordTags] = useState<JSX.Element[]>()
    const [keywords, setKeywords] = useState<IKeyword[]>(initialKeywords)
    async function onUpdateKeywords(newKeyword: IKeyword) {
        if (keywords.find((keyword) => keyword.word === newKeyword.word) !== undefined)
            return

        const existingKewordIndex = keywords.findIndex((keyword) => keyword.ref === newKeyword.ref)
        if (existingKewordIndex !== -1) {
            keywords[existingKewordIndex] = newKeyword
        } else {
            newKeyword.ref = uuid()
            keywords?.push(newKeyword)
        }
        const newKeywords = [...keywords]
        setKeywords(newKeywords)
        onChange(newKeywords)
    }

    useEffect(() => {
        setKeywordTags(keywords?.map((keyword) => <KeywordTag onChange={onUpdateKeywords} key={keyword.ref}
                                                              onDelete={onDeleteKeyword} keyword={keyword}
                                                              editingMode={true}/>))
    }, [keywords])

    async function onDeleteKeyword(newKeyword: IKeyword) {
        try {
            const newKeywords = keywords.filter((keyword) => keyword.word !== newKeyword.word)

            setKeywords(newKeywords)
            onChange(newKeywords)
        } catch (error) {
            console.log(error)
        }
    }


    return <DropdownPanel label={'Keywords'} open={open} onOpen={onOpen}>
        <div className={style.filter_element}>
            <KeywordTextArea hintService={marketplaceService} onChange={onUpdateKeywords} max={10}>
                {keywordTags}
            </KeywordTextArea>
        </div>
    </DropdownPanel>
}