import React, { useState } from 'react'
import InlineToast from '../../../../theme/component/inline-notification'
import { InlineToastType } from '../../../../theme/component/inline-notification/model/inline-notification-type.model'
import InputButton from '../../../../theme/component/input-button'
import { ButtonColor } from '../../../../theme/component/input-button/models/button-color.model'
import InputTextArea from '../../../../theme/component/input-text-area/input-text-area.component'
import InputText from '../../../../theme/component/input-text/input-text.component'
import Panel from '../../../../theme/component/panel/panel.component'

export default function DevInlineToast() {
    const [message, setMessage] = useState<string>('Bad Login. Some longer login error occurred')
    const [toastType, setToastType] = useState<InlineToastType>(InlineToastType.Failure)
    const [show, setShow] = useState<boolean>(true)
    
    return (
        <section className='text-center'>
            <span className='l-3 mr4 vertical-top'>
                <Panel>
                    <div className="mb2">
                        <h4>Visibility</h4>
                        <span className="mb1 mr2"><InputButton label='Show' outline={true} onClick={() => setShow(true)}/></span>
                        <span className="mb1 mr2"><InputButton label='Hide' outline={true} onClick={() => setShow(false)}/></span>
                    </div>
                    <div className="mb2">
                        <h4>Type</h4>
                        <span className='mb1 mr2'><InputButton label='Info' onClick={() => setToastType(InlineToastType.Info)} /></span>
                        <span className='mb1 mr2'><InputButton label='Success' color={ButtonColor.Success} onClick={() => setToastType(InlineToastType.Success)} /></span>
                        <span className='mb1 mr2'><InputButton label='Warning' color={ButtonColor.Warning} onClick={() => setToastType(InlineToastType.Warning)} /></span>
                        <span className='mb1 mr2'><InputButton label='Error' color={ButtonColor.Failure} onClick={() => setToastType(InlineToastType.Failure)} /></span>
                    </div>
                    <h4>Message</h4>
                    <div><InputTextArea placeholder='Toast Message' onChange={setMessage} /></div>
                </Panel>
            </span>
            <span className='l-3'>
                <Panel>
                    <div>
                        <h1>Title</h1>
                    </div>
                    <div className='mb2'>
                        <InlineToast show={show} type={toastType} message={message} />
                    </div>
                    <div className='mb2'>
                        <InputText placeholder='Some Test' />
                    </div>
                    <div className='mb2'>
                        <InputText placeholder='Some Other' />
                    </div>
                    <div>
                        <InputButton label='Send stuff' fill={true} />
                    </div>
                </Panel>
            </span>
        </section>
    )
}
