import React, {useState} from 'react'
import ProfileView from '../../../../../../service/profile/model/profile-view.model'
import Avatar from '../avatar/avatar.component'
import FollowerCount from '../follower-count/follower-count.component'
import ConnectButton from '../profile-connect/profile-connect.component'
import Displayname from '../profile-displayname/profile-displayname.component'

import style from './meta.module.scss'
import {PremiumBadge} from "../../../../../../theme/component/premium-badge";
import {BoostButton} from "../../../../../../theme/component/boost-button";
import profileService from "../../../../../../service/profile/profile.service";
import {NotificationService} from "../../../../../../service/notification/notification.service";
import {useNavigate} from "react-router-dom";
import {NotificationType} from "../../../../../../service/notification/model/notification-type.enum";

type MetaProps = {
    profile: ProfileView,
    editModeChanged: (editMode: boolean) => void
}

export default function Meta({ profile, editModeChanged }: MetaProps) {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [boostCount, setBoostCount] = useState<number>(profile.boostCount)
    const [boostedUntil, setBoostedUntil] = useState<Date>(profile.boostedUntil)
    const navigate = useNavigate()

    function onEditModeChange(editingMode: boolean) {
        setEditMode(editingMode)
        editModeChanged(editingMode)
    }

    async function onBoost() {
        if (boostCount === 0) {
            navigate('/boost')
        }

        if (boostedUntil) {
            return
        }

        try {
            const boostedUntil = await profileService.boostProfile()
            setBoostCount(boostCount-1)
            setBoostedUntil(boostedUntil)
            NotificationService.displayNotification({
                type: NotificationType.success,
                message: "Your Profile is boosted!"
            })
        } catch (error) {

        }
    }


    return (
        <div className={style.meta}>
            <Avatar avatarUrl={profile?.avatar} editingMode={editMode} />
            <Displayname displayname={profile?.displayname} editingMode={editMode}>
                    <PremiumBadge premium={profile?.premium}/>
            </Displayname>
            <div className={style.sub}>
                <FollowerCount networkDetails={profile?.networks} />
                <ConnectButton profile={profile} editModeChanged={onEditModeChange} />
                {profile.boostCount !== undefined && <span className={style.boost_button}><BoostButton onClick={onBoost} boostCount={boostCount} boostedUntil={boostedUntil}/></span>}
            </div>
        </div >
    )
}