import React from 'react'
import ProfileTile from '../../../../theme/component/profile-tile/profile-tile.component'
import { IProfileTile } from '../../../../service/search/model/profile-tile.model'

export default function DevProfileTile() {
    const profile: IProfileTile = {
        username: 'Grifting',
        displayname: 'Max Eichen',

        avatarUrl: '/api/image?ref=d129fe16-47ef-4c5d-a197-1caddf94ec7e',
        bannerUrl: '/api/image?ref=e48450da-7cca-47d2-a3d5-0a67d24a341c',

        description: 'Ja was gehtn!',

        mainPlatform: 'youtube',
        follower: 56735,
        networks: [],
        collaborations: {
            freeProductPlacement: true,
            influencerCollaboration: true,
            productPlacement: true
        },
        keywords: [],
        premium: true
    } as IProfileTile

    return (
        <section>
            <div><ProfileTile profile={profile} /></div>
            <div><ProfileTile profile={profile} showReject={true} /></div>
        </section>
    )
}
