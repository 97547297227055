import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';

export default function MessageInfo() {
    const { t } = useTranslation();

    return (
        <div className={style.info}>
            <span className='pre-icon'>{t('message_info.text')}</span>
        </div>
    );
}
