import React from 'react';

type SvgProps = {
    color?: string;
    size?: number;
};

const DealIcon: React.FC<SvgProps> = ({ color = 'rgb(0,206,0)', size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
        <rect id="deal" x="0" y="0" width="64" height="64" style={{ fill: 'none' }}/>
        <path
            id="deal1"
            d="M45.36,34.874C43.303,32.192 41.296,29.457 38.953,27.009L35.698,30.656C32.833,33.911 27.702,33.832 24.916,30.499C22.936,28.104 22.936,24.665 24.967,22.295L29.577,16.93C27.962,16.097 26.009,16.384 24.266,16.384C22.729,16.384 21.244,17.009 20.151,18.102L16.035,22.217L11.999,22.217L11.999,36.384C13.145,36.384 14.185,36.227 15.097,37.114L22.832,44.718C24.421,46.254 26.477,47.608 28.744,47.608C29.916,47.608 31.158,47.21 31.992,46.377C33.945,47.054 36.21,45.96 36.81,43.955C38.06,44.06 39.205,43.669 40.117,42.809C40.715,42.262 41.497,41.168 41.419,40.308C41.654,40.543 42.233,40.576 42.546,40.576C45.645,40.576 47.26,37.321 45.358,34.872L45.36,34.874ZM49.5,36.384L52,36.384L52,23.05L49.578,23.05L45.49,18.364C44.396,17.114 42.754,16.384 41.088,16.384L36.739,16.384C35.281,16.384 33.874,17.035 32.938,18.129L27.494,24.457C26.531,25.604 26.531,27.217 27.467,28.364C28.952,30.134 31.686,30.161 33.223,28.442L38.249,22.765C39.448,21.437 41.634,22.687 41.088,24.38C42.078,25.527 43.144,26.646 44.11,27.792C45.411,29.407 46.661,31.099 47.938,32.741C48.745,33.782 49.345,35.032 49.501,36.387L49.5,36.384ZM62,21.384L62,38.051C62,38.963 61.245,39.718 60.333,39.718L49.031,39.718C48.095,41.983 46.036,43.494 43.641,43.833C42.521,45.473 40.803,46.723 38.876,47.139C37.444,48.963 35.126,50.056 32.808,49.9C28.511,52.321 23.667,50.211 20.464,47.061L12.99,39.718L3.667,39.718C2.755,39.718 2,38.963 2,38.051L2,20.55C2,19.639 2.755,18.884 3.667,18.884L14.63,18.884C17.652,15.862 19.734,13.05 24.266,13.05L27.312,13.05C29.006,13.05 30.646,13.572 32.026,14.509C33.406,13.572 35.048,13.05 36.739,13.05L41.088,13.05C46.036,13.05 48.093,16.279 51.088,19.717L60.333,19.717C61.245,19.717 62,20.472 62,21.384Z"
            style={{ fill: color, fillRule: 'nonzero' }}
        />
    </svg>
);

export default DealIcon;
