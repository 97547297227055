import React, { useState } from 'react';
import style from './account-details.module.scss';
import ISettings from '../../../../../../service/settings/model/settings.model';
import InputButton from '../../../../../../theme/component/input-button';
import { ButtonColor } from '../../../../../../theme/component/input-button/models/button-color.model';
import AccountCloseBox from './account-close-box.component';
import { useTranslation } from 'react-i18next';

type AccountDetailsProps = {
    settings: ISettings;
    onSave: (settings: ISettings) => void;
};

export default function AccountCloseDetails({ settings, onSave }: AccountDetailsProps) {
    const { t } = useTranslation();
    const [openAccountCloseBox, setOpenAccountCloseBox] = useState(false);

    return (
        <div className="mb2">
            <AccountCloseBox open={openAccountCloseBox} onClose={() => setOpenAccountCloseBox(false)} />
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>{t('account.title')}</div>
                    <div className={style.info}>{t('account.manage_details')}</div>
                </div>
            </div>
            <div className='mt1'>
                <div>
                    <div className={style.button_close}>
                        <InputButton color={ButtonColor.Failure} label={t('account.close_account')} onClick={() => setOpenAccountCloseBox(true)} />
                    </div>
                </div>
            </div>
        </div>
    );
}
