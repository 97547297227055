import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../index.module.scss';
import { useTranslation } from 'react-i18next';

interface SubscriptionTileProps {
    planId?: string;
    title: string;
    price: string;
    benefits: string[];
    action: string;
    onBuy?: (planId: string) => void;
    navigateTo?: string;
}

const FreeTile: React.FC<SubscriptionTileProps> = ({ title, price, benefits, action, navigateTo }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAction = () => {
        if (navigateTo) {
            navigate(navigateTo);
        }
    };

    return (
        <div className={style.subscriptionTile}>
            <div className={style.promoBanner}>
                <div className={style.triangle}></div>
                <span>{t('subscription.freeTile.promoBanner')}</span>
            </div>
            <h3 className={style.subscriptionTileTitle}>{t(title).toUpperCase()}</h3>
            <p className={style.price}>{price}</p>
            <ul className={style.benefits}>
                {benefits.map((benefit: string, index: number) => (
                    <li key={index}>{t(benefit)}</li>
                ))}
            </ul>
            <div className={style.action} onClick={handleAction}>{t(action)}</div>
        </div>
    );
};

const SubscriptionTile: React.FC<SubscriptionTileProps> = ({ planId, onBuy, title, price, benefits, action, navigateTo }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleAction = () => {
        if (navigateTo) {
            navigate(navigateTo);
        } else if (onBuy) {
            onBuy(planId);
        }
    };

    return (
        <div className={style.premiumSubscriptionTile}>
            <h3 className={style.premiumSubscriptionTileTitle}>{t(title).toUpperCase()}</h3>
            <p className={style.premiumPrice}>{price}</p>
            <ul className={style.premiumBenefits}>
                {benefits.map((benefit: string, index: number) => (
                    <li key={index}>{t(benefit)}</li>
                ))}
            </ul>
            <div className={style.premiumAction} onClick={handleAction}>{t(action)}</div>
        </div>
    );
};

export const InfluencerMonthlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-7YR91116UY077744PMWYC73I'}
        title="subscription.influencerMonthlyPlan.title"
        price="10,90€ / mo"
        benefits={[
            'subscription.influencerMonthlyPlan.benefit1',
            'subscription.influencerMonthlyPlan.benefit2',
            'subscription.influencerMonthlyPlan.benefit3',
            'subscription.influencerMonthlyPlan.benefit4',
            'subscription.influencerMonthlyPlan.benefit5',
            'subscription.influencerMonthlyPlan.benefit6'
        ]}
        action="subscription.influencerMonthlyPlan.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const InfluencerYearlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-8W568297R08956525MW2CQDI'}
        title="subscription.influencerYearlyPlan.title"
        price="109€ / year"
        benefits={[
            'subscription.influencerYearlyPlan.benefit1',
            'subscription.influencerYearlyPlan.benefit2',
            'subscription.influencerYearlyPlan.benefit3',
            'subscription.influencerYearlyPlan.benefit4',
            'subscription.influencerYearlyPlan.benefit5',
            'subscription.influencerYearlyPlan.benefit6'
        ]}
        action="subscription.influencerYearlyPlan.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const BusinessMonthlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-63340961HC971183YMWYC55A'}
        title="subscription.businessMonthlyPlan.title"
        price="12,90€ / mo"
        benefits={[
            'subscription.businessMonthlyPlan.benefit1',
            'subscription.businessMonthlyPlan.benefit2',
            'subscription.businessMonthlyPlan.benefit3',
            'subscription.businessMonthlyPlan.benefit4'
        ]}
        action="subscription.businessMonthlyPlan.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const BusinessYearlyPlan = ({ onBuy }: { onBuy?: (planId: string) => void }) => (
    <SubscriptionTile
        planId={'P-1AY65338S2323582HMWYC5AQ'}
        title="subscription.businessYearlyPlan.title"
        price="129€ / year"
        benefits={[
            'subscription.businessYearlyPlan.benefit1',
            'subscription.businessYearlyPlan.benefit2',
            'subscription.businessYearlyPlan.benefit3',
            'subscription.businessYearlyPlan.benefit4'
        ]}
        action="subscription.businessYearlyPlan.action"
        onBuy={onBuy}
        navigateTo="/login?return_to=%2Fpremium"
    />
);

export const FreePlan = () => (
    <FreeTile
        title="subscription.freePlan.title"
        price="0€"
        benefits={[
            'subscription.freePlan.benefit1',
            'subscription.freePlan.benefit2',
            'subscription.freePlan.benefit3',
            'subscription.freePlan.benefit4'
        ]}
        action="subscription.freePlan.action"
        navigateTo="/join"
    />
);
