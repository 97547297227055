import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import Overlay from "../../../../../../theme/component/overlay/overlay.component";

// Define a type for your blog object
type Blog = {
    ref: string;
    title: string;
    abstract: string;
    content: string;
    image: string;
    status: string;
};

type EditBlogProps = {
    blog: Blog;
    onClose: () => void;
};

const EditBlog = ({ blog, onClose }: EditBlogProps) => {
    const [formData, setFormData] = useState<Blog>({ ...blog });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        axios.put(`/blog/${blog.ref}`, formData)
            .then(response => {
                console.log('Blog updated successfully');
                onClose(); // Close the modal
                // Optionally, refresh the list of blogs or update the UI in some way
            })
            .catch(error => {
                console.error('Error updating blog', error);
            });
    };

    return (
        <Overlay title="Edit Blog" open={Boolean(blog)} onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                />
                <textarea
                    name="abstract"
                    placeholder="Abstract"
                    value={formData.abstract}
                    onChange={handleChange}
                ></textarea>
                <textarea
                    name="content"
                    placeholder="Content"
                    value={formData.content}
                    onChange={handleChange}
                ></textarea>
                <input
                    type="text"
                    name="image"
                    placeholder="Image URL"
                    value={formData.image}
                    onChange={handleChange}
                />
                <button type="submit">Update Blog</button>
            </form>
        </Overlay>
    );
};

export default EditBlog;
