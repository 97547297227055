import style from "../../../influspace/login/index.module.scss";
import React from "react";



export default function GoogleLegalPrint({message}: { message?: string }) {

    return <span className={`${style.google_terms_of_service_apply}`}>
        {/*This site is protected by reCAPTCHA and<br/>*/}
        <a href="https://developers.google.com/terms/api-services-user-data-policy" target='_blank'
           className={style.link}>Google API Services User Data Policy</a>,
            <br/>
        <a href='https://policies.google.com/privacy' target='_blank' className={style.link}>Privacy Policy</a>
        &nbsp;and&nbsp;
        <a href='https://policies.google.com/terms' target='_blank' className={style.link}>Terms of Service</a>
        &nbsp;apply.
    </span>
}

