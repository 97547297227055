import axios from 'axios'

class HealthService {

    private root = '/health'
    private client = axios

    async getApiVersion(): Promise<string> {
        try {
            let response = await this.client.get(this.root)

            return response.status === 200 ? response.data?.version : 'N/A'
        } catch(error) {
            console.log(error)
            return 'N/A'
        }
    }

    // 2** happy path
    // 4** client error
    // 5** server error

}

export default new HealthService()