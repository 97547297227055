import React from 'react'

import style from './loading-sinner.module.scss'

export default function LoadingContent() {
    return (
        <div className={style.loading}>
            <div className={style.loader} />
        </div>
    )
}
