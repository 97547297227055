import React, {useState} from 'react'
import chatService from '../../../../service/chat/chat.service'
import ChatMessage from '../../../../service/chat/model/ChatMessage'
import InputTextArea from '../../../../theme/component/input-text-area/input-text-area.component'

import style from './index.module.scss'
import {OfferRef} from "../message-grid/component/message/component/offer-reference";
import {IOffer} from "../../../../service/marketplace/model/offer.model";
import {Receipient} from "../message-grid/component/message/model/receipient.model";

type ChatboxProps = {
    offer: IOffer
    username: string
    onMessageSend: (message: ChatMessage) => void
}

export default function Chatbox({offer, username, onMessageSend }: ChatboxProps) {
    const [message, setMessage] = useState<string>('')
    const [sendDisabled, setSendDisabled] = useState<boolean>(false)

    return (
        <div className={style.textbox}>
            <OfferRef receipient={Receipient.Sender} offer={offer ? {name: offer.name, description: offer.description, ref: offer.ref} : undefined}/>
            <InputTextArea rows={4} placeholder='Message' onChange={setMessage} onEnter={sendMessage} maxLength={1000} initialValue={message} chat={true} />
            <span className={`pre-icon ${style.sendButton} ${sendDisabled ? style.sendDisabled : ''}`} onClick={sendMessage} />
        </div>
    )

    async function sendMessage() {
        if (sendDisabled)
            return

        setSendDisabled(true)

        setMessage('')

        const messageResponse = await chatService.sendMessage(username, message, offer?.ref)

        onMessageSend(messageResponse)

        setSendDisabled(false)
    }
}
