import React from 'react'

export default function DevPlaceholder() {
    return (
        <section id="placeholder" className="mt4">
            <h1>Title H1</h1>
            <h2>Title H2</h2>
            <h3>Title H3</h3>
            <h4>Title H4</h4>
            <h5>Title H5</h5>
            <br />
            <hr />
            <br />
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic temporibus, molestias fugit accusamus enim, inventore repellat sunt iste dolorum quis porro provident commodi sed reiciendis aut, ullam dolores itaque architecto.</p>
        </section>
    )
}