import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';
import InputDropdown from "../../../theme/component/input-dropdown/input-dropdown.component";
import InputButton from "../../../theme/component/input-button";
import DropdownOption from "../../../theme/component/input-dropdown/models/dropdown-option.model";
import Cookies from 'js-cookie';

export default function LanguageSelector() {

    const availableLanguages = [
        {
            label: '🇬🇧 English',
            value: 'en'
        },
        {
            label: '🇩🇪  Deutsch',
            value: 'de'
        }
    ]

    const { i18n } = useTranslation();
    const initalLanguage = getInitialLanguage()

    console.log(initalLanguage)

    const [selectedLanguage, setSelectedLanguage] = useState<DropdownOption>(availableLanguages[initalLanguage === 'de' ? 1 : 0]);

    function getInitialLanguage() {
        if (Cookies.get('language_set')) {
            return i18n.language
        } else {
            return navigator.language === 'de-DE' ? 'de' : 'en'
        }
    }

    const [confirmed, setConfirmed] = useState<boolean>(false);

    const handleLanguageChange = (lang: DropdownOption) => {
        setSelectedLanguage(lang);
    };

    const confirmLanguageSelection = () => {
        console.log(selectedLanguage)
        if (selectedLanguage) {
            console.log(selectedLanguage.value)
            i18n.changeLanguage(selectedLanguage.value);
            setConfirmed(true);
            Cookies.set('language_set', 'true')
        }
    };

    if (confirmed) {
        return null; // Verstecke die Komponente nach Bestätigung
    }

    if (Cookies.get('language_set')) {
         return <></>
     }

    return (
        <>
        <div className={style.selector_spacer} />
        <section className={style.container}>
            <p className={style.info}>Select a language for localized content and support.</p>
            <span className={style.selector_container}>
                <span>
                    <span className={style.selector}>
                        <InputDropdown initialIndex={initalLanguage === 'de' ? 1 : 0} options={availableLanguages} onChange={setSelectedLanguage} />
                    </span>
                    <InputButton className={style.confirm} label={'Confirm'} onClick={confirmLanguageSelection}/>
                </span>
            </span>
        </section>
        </>
    );
};