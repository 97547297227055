import React from 'react'

import style from './dev-grid.module.scss'

export default function DevGrid() {
    return (
        <section>
            <span className={`${style.a} l-6 m-8 s-12`}>L-6 M-8 S-12</span>
            <span className={`${style.b} l-6 m-4 s-12`}>L-6 M-4 S-12</span>
        </section>
    )
}
