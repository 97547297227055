import React from 'react'

import style from './index.module.scss'
import { Receipient } from './model/receipient.model'
import ChatMessage from '../../../../../../service/chat/model/ChatMessage'
import moment from 'moment'
import Datestamp from '../datestamp'
import Unreadbarrier from '../unreadbarrier'
import { v4 as uuid } from 'uuid'
import {OfferRef} from "./component/offer-reference";

type MessageProps = {
    receipient: Receipient
    message: ChatMessage
    showDatestamp?: boolean
    unreadbarrier?: boolean
}

export default function Message({ message, receipient, showDatestamp, unreadbarrier }: MessageProps) {
    const renderedContent: JSX.Element[] = message?.content?.split('\n')?.map(content => {
        return <p key={uuid()} className={style.content}>{content}<br/></p>
    })

    const renderedDatestamp = showDatestamp ? <Datestamp date={message.date} /> : <></>
    const renderedUnreadbarrier = unreadbarrier ? <Unreadbarrier /> : <></>

    return (
        <>
            {renderedDatestamp}
            {renderedUnreadbarrier}
            <div className={`${style[receipient]} ${style.message}`}>
                <span className={style.bubble}>
                    <OfferRef offer={message.offer} receipient={receipient}/>
                    {renderedContent}
                    <span className={style.timestamp}>{moment(message?.date).format('HH:mm')}</span>
                </span>
            </div>
        </>
    )
}
