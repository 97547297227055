import React from "react";
import style from "./index.module.scss"
import {LocationInputText} from "../../../../theme/component/location-input-text";

type LocationFilterProps = {
    onChange: (location: ILocation) => void
}
export function LocationFilter({onChange}: LocationFilterProps) {
    return <LocationInputText onChange={onChange} placeholder={'Anywhere'} className={style.filter}/>

}