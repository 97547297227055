import React, { FormEvent, MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './search.module.scss'
import searchService from '../../../../../service/search/search.service'
import SearchHints from './search-hints.component'
import {useTranslation} from "react-i18next";

export default function Search() {
    const navigate = useNavigate()
  
    const [query, setQuery] = useState<string>('')
    const [hints, setHints] = useState<string[]>([])
    const [openHints, setOpenHints] = useState<boolean>(false)
    const [selectedHintIndex, setSelectedHintIndex] = useState<number>(-1);
    const { t } = useTranslation()
  
    function search(event: MouseEvent<any>) {
      event?.preventDefault()

      const trimmedQuery = query?.trim()

      if (trimmedQuery.length > 0) {
          const encodedQuery = encodeURIComponent(trimmedQuery)
          setQuery(trimmedQuery)
          navigate(`/search?query=${encodedQuery}`)
      }
    }
  
    async function onChangeHandler(event: FormEvent<HTMLInputElement>) {
      const currentValue = event.currentTarget.value
      const regex = /^$|^[a-zA-Z0-9_\-. ]{1,20}$/;

      if (currentValue === '' || regex.test(currentValue)) {
          setQuery(currentValue)
          setHints(await searchService.hint(currentValue))
          setSelectedHintIndex(-1)
          return
      }
  
      event.preventDefault()
    }

    function onClickHint(hint: string) {
      navigate(`/search?query=${hint}`)
      setQuery(hint)
      setOpenHints(false)
      setSelectedHintIndex(-1)
    }

    function onKeyDownHandler(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setSelectedHintIndex((prevIndex) =>
          prevIndex < hints.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        setSelectedHintIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (event.key === 'Enter') {
        if (selectedHintIndex !== -1) {
          event.preventDefault();
          const selectedHint = hints[selectedHintIndex];
          navigate(`/search?query=${selectedHint}`);
          setQuery(selectedHint);
          setOpenHints(false);
          setSelectedHintIndex(-1)
        }
      }
    }

    function onBlurHandler() {
      setTimeout(() => {
        setOpenHints(false)
        setSelectedHintIndex(-1)
      }, 300)
    }
  
    return (
      <span className={style.overlay}>
        <form className={style.form} onSubmit={search}>
          <input  type='text'
                  name='query'
                  value={query}
                  onFocus={() => setOpenHints(true)}
                  onBlur={onBlurHandler}
                  onChange={onChangeHandler}
                  onKeyDown={onKeyDownHandler}
                  placeholder={t('nav.search_field')}
                  autoComplete='off'
                  autoCorrect='off'
                  autoCapitalize='off'
                  spellCheck='false' />
          <button onClick={search} />
          <SearchHints selectedIndex={selectedHintIndex} hints={hints} open={openHints} onClick={onClickHint}/>
        </form>
        
      </span>
    )
  }

