import React, { useEffect, useState } from 'react'
import {AccountService} from '../../../../service/account/account.service'
import SessionDetails from '../../../../service/session/model/session-details'
import sessionService from '../../../../service/session/session.service'
import InputButton from '../../../../theme/component/input-button'
import InputText from '../../../../theme/component/input-text/input-text.component'
import moment from 'moment'

import style from './dev-login.module.scss'

export default function DevLogin() {
  const [login, setLogin] = useState<string>('Grifting')
  const [pass, setPass] = useState<string>('31521Maxi!')
  const [sessionDetails, setSessionDetails] = useState<SessionDetails>({ authenticated: false, authorized: false, active: false, premium: false })
  const [loginCall, setLoginCall] = useState<string>()

  async function signIn() {
    const session = await AccountService.signIn(login, pass)

    setLoginCall(moment().format())

    updateSessionDetails()
  }
  
  async function signOut() {
    const session = await AccountService.signOut()

    setLoginCall(moment().format())
  
    updateSessionDetails()
  }

  function updateSessionDetails() {
    const session = sessionService.getSessionDetails()

    setSessionDetails({
      token: session?.token,
      username: session?.username,
      authenticated: session.authenticated,
      authorized: session.authorized,
      premium: session.premium,
      active: session.active,
    })
  }

  async function newToken() {
    await sessionService.validateSession()
    updateSessionDetails()
  }

  useEffect(() => {
    updateSessionDetails()
  }, [])

  return (
    <section id="placeholder" className="mt3">
      <h1 className='protected'>Dev Login</h1>
      <br />
      <span className={style.container}>
        <span className={`${style.pseudoPanel} mr2`}>
          <InputText placeholder='Mail or Username' onChange={setLogin} />
        </span>
        <span className={`${style.pseudoPanel} mr2`}>
          <InputText isPassword={true} placeholder='Password' onChange={setPass} />
        </span>
        <span className={`${style.item} mr2`}>
          <InputButton label="Sign In" onClick={signIn} disabled={sessionDetails.authenticated === true} />
        </span>
        <span className={`${style.item} mr2`}>
          <InputButton label="Sign Out" onClick={signOut} disabled={sessionDetails.authenticated === false} />
        </span>
        <span className={`${style.item} mr2`}>
          <InputButton label="New Token" onClick={newToken} />
        </span>
      </span>
      <br />
      <br />

      <div>
        <span className={`${style.inline} m0 mr4`}>
          <h3>Session Details</h3>
          <table>
            <tbody>
              <tr>
                <td className={style.cell}>Token</td>
                <td>{sessionDetails?.token}</td>
              </tr>
              <tr>
                <td>Authenticated</td>
                <td>{sessionDetails.authenticated ? 'YES' : 'NO'}</td>
              </tr>
              <tr>
                <td>Username</td>
                <td>{sessionDetails?.username ? sessionDetails?.username : '-'}</td>
              </tr>
              <tr>
                <td>Authorized</td>
                <td>{sessionDetails.authorized ? 'YES' : 'NO'}</td>
              </tr>
            </tbody>
          </table>
        </span>
        <span className={`${style.inline} m0`}>
          <h3>Account API Response</h3>
          <table className='ml0'>
            <tbody>
              <tr>
                <td className={style.cell}>Date</td>
                <td>{loginCall ? loginCall : '-'}</td>
              </tr>
              <tr>
                <td className={style.cell}>Logged in</td>
                <td>{sessionDetails ? 'YES' : 'NO'}</td>
              </tr>
              <tr>
                <td>Username</td>
                <td>{sessionDetails?.username ? sessionDetails?.username : '-'}</td>
              </tr>
            </tbody>
          </table>
        </span>
      </div>
    </section>
  )
}