import React from "react"
import Overlay from "../../../../../../theme/component/overlay/overlay.component"
import InputButton from "../../../../../../theme/component/input-button"
import { ButtonColor } from "../../../../../../theme/component/input-button/models/button-color.model"
import settingsService from "../../../../../../service/settings/settings.service"
import {PasswordService} from "../../../../../../service/password/password.service";

interface PasswordForgotBoxProps {
    open: boolean,
    onClose: (success?: boolean) => void,
    mail: string
}


export default function PasswordForgotBox({ open, onClose, mail }: PasswordForgotBoxProps) {

    async function onSend() {
        try {
            await settingsService.forgotPassword(mail)
            onClose(true)
        } catch (error) {
            onClose(false)
        }
    }


  return (
    <Overlay title='Reset Password' open={open} onClose={onClose}>
        <div className="mt2">
            <div className="mb2">A recovery mail will be send to <b>{mail}</b>. Click the link in the mail to reset your password.</div>

            <div>
                <span className='float-left'><InputButton  label='Cancel' color={ButtonColor.Failure} onClick={onClose}  /></span>
                <span className='float-right'><InputButton label='Send Mail' onClick={onSend} /></span>
            </div>
        </div>
    </Overlay>
  )
}

