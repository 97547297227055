import axios from 'axios'
import ChatMessage from '../chat/model/ChatMessage'
import ChatDetails from './model/ChatDetails'
import Inbox from './model/Inbox'

class InboxService {

    private root = 'inbox'
    private client = axios

    async getInbox() : Promise<Inbox> {
            let response = await this.client.get(`${this.root}/chats`)
            return response.data
    }

    async hasNewMessages() : Promise<boolean> {
        let response = await this.client.get(`${this.root}/chats/has-new-messages`)
        console.log("response: " + response.data.hasNewMessages)
        return response.data.hasNewMessages
    }

    async archive(receiverUsername: string, archive: boolean) : Promise<ChatDetails> {
        let response = await this.client.post(`${this.root}/archive?chat=${receiverUsername}&archive=${archive}`)
        return response.status === 200 ? response.data : null
    }

    async block(receiverUsername: string, block: boolean) : Promise<ChatDetails> {
        let response = await this.client.post(`${this.root}/block?chat=${receiverUsername}&block=${block}`)
        return response.status === 200 ? response.data : null
    }

    async deleteChat(ref: string) : Promise<void> {
        await this.client.post(`${this.root}/delete?ref=${ref}`)
    }

    async reject() : Promise<ChatDetails> {
        try {
            let response = await this.client.post(`${this.root}/reject`)
            return response.status === 200 ? response.data : null
        } catch(error) {
            console.log(error)
            return null
        }
    }

    isLatestMessage(chat1: ChatDetails, chat2: ChatDetails): number {
        const date1 = new Date(chat1?.latestMessage?.date).getTime()
        const date2 = new Date(chat2?.latestMessage?.date).getTime()

        return date2 - date1
    }

    isLatestChatMessage(message1: ChatMessage, message2: ChatMessage): number {
        const date1 = new Date(message1?.date).getTime()
        const date2 = new Date(message2?.date).getTime()

        return date2 - date1
    }
    
}

export default new InboxService()