import axios from "axios"
import cleanupService from "../cleanup.service"
import SearchDetails from "./model/search-details.model"
import { IProfileTile } from "./model/profile-tile.model"

class SearchService {

    private root = '/search'
    private client = axios

    public async search(searchDetails: SearchDetails): Promise<IProfileTile[]> {
        const search = cleanupService.cleanup(searchDetails)

        try {
            const response = await this.client.post(`${this.root}/profile`, search)

            return response.status === 200 ? response.data : []
        } catch(error) {
            return []
        }
    }

    public async hint(query: string): Promise<string[]> {
        try {
            const response = await this.client.get(`${this.root}/hint?query=${query}`)

            return response.data
        } catch(error) {
            return []
        }
    }

}

export default new SearchService()