import React, { useState, useEffect } from 'react';
import axios from 'axios';
import style from './index.module.scss'; // Ensure this SCSS file contains the new grid styles
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Blog {
    ref: string;
    title: string;
    abstract: string;
    image: string;
    createdAt: string;
}

export default function NewsletterSection() {
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 800);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        axios.get('/blog/newest')
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => {
                console.error(t('blog_section.fetching_error'), error);
            });
    }, []);

    const formatCreatedAtDate = (createdAt: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(createdAt).toLocaleDateString(undefined, options);
    };

    const navigateToBlog = (ref: string) => {
        navigate(`/blogs/${ref}`);
    };

    return (
        <div className={style.gridContainer}>
            {blogs.length > 0 && !isMobile && (
                <div className={style.bigTile} onClick={() => { navigateToBlog(blogs[0].ref) }}>
                    <img src={`https://influspace.com/api/image?ref=${blogs[0].image}`} alt="" />
                    <div className={style.overlay}>
                        <h2 className={style.bigTileContent}>{blogs[0].title}</h2>
                        <h3 className={style.bigTileContent}>{blogs[0].abstract}</h3>
                        <h5 className={style.bigTileDate}>{formatCreatedAtDate(blogs[0].createdAt)}</h5>
                    </div>
                </div>
            )}
            <div className={style.smallTiles}>
                <h2 className={style.title}>{t('blog_section.title')}</h2>
                {isMobile ? (
                    blogs.slice(0,3).map((blog, index) => (
                        <div key={index} className={style.smallTile} onClick={() => { navigateToBlog(blog.ref) }}>
                            <div className={style.tileImageContainer}>
                                <img src={`https://influspace.com/api/image?ref=${blog.image}`} alt="" />
                            </div>
                            <div className={style.tileContentContainer}>
                                <h3 className={style.blogTitle}>{blog.title}</h3>
                                <h6 className={style.blogDate}>{formatCreatedAtDate(blog.createdAt)}</h6>
                            </div>
                        </div>
                    ))
                ) : (
                    blogs.slice(1, 4).map((blog, index) => (
                        <div key={index} className={style.smallTile} onClick={() => { navigateToBlog(blog.ref) }}>
                            <div className={style.tileImageContainer}>
                                <img src={`https://influspace.com/api/image?ref=${blog.image}`} alt="" />
                            </div>
                            <div className={style.tileContentContainer}>
                                <h3 className={style.blogTitle}>{blog.title}</h3>
                                <h6 className={style.blogDate}>{formatCreatedAtDate(blog.createdAt)}</h6>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
