import React, { useEffect, useState } from 'react'
import ProfileView from '../../../../service/profile/model/profile-view.model'
import profileService from '../../../../service/profile/profile.service'
import Panel from '../../../../theme/component/panel/panel.component'
import CollaborationOption from './component/collaboration-option.component'
import ICollaboration from './model/collaboration.model'

import style from './profile-collaboration.module.scss'
import {useTranslation} from "react-i18next";

type ProfileCollaborationProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileCollaboration({ profile, editingMode }: ProfileCollaborationProps) {
    const [collaborations, setCollaborations] = useState<ICollaboration>()
    const [changed, setChanged] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        const providedCollabs = profile?.collaborations

        if (changed) {
            updateCollaborations()
        } else if (profile?.collaborations !== collaborations) {
            setCollaborations(providedCollabs)
        }


    }, [profile, editingMode])
    
    async function updateCollaborations() {
        setChanged(false)
        await profileService.saveCollaborations(collaborations)
    }

    function changeHandler(collaborationOption: string, enabled: boolean) {
        const newCollaboration = collaborations

        switch (collaborationOption) {
            case 'freeProductPlacement':
                newCollaboration.freeProductPlacement = enabled
                break
            case 'productPlacement':
                newCollaboration.productPlacement = enabled
                break
            case 'influencerCollaboration':
                newCollaboration.influencerCollaboration = enabled
                break
        }

        setCollaborations(newCollaboration)
        setChanged(true)
    }

    function hasCollaborations() {
        let hasCollaborations = false

        hasCollaborations = collaborations?.freeProductPlacement || hasCollaborations
        hasCollaborations = collaborations?.productPlacement || hasCollaborations
        hasCollaborations = collaborations?.influencerCollaboration || hasCollaborations
        
        return hasCollaborations
    }

    if (hasCollaborations() || editingMode) 
        return (
            <Panel overridePadding={style.panel}>
                    <h1 className={style.title}>{t('profile_collaborations.title')}</h1>
                    <CollaborationOption    collaborationOption='freeProductPlacement'
                                            label={t('profile_collaborations.free_product_placement')}
                                            editMode={editingMode} 
                                            isEnabled={collaborations?.freeProductPlacement} 
                                            onChange={(enabled) => changeHandler('freeProductPlacement', enabled)} />
                    <CollaborationOption    collaborationOption='productPlacement'
                                            label={t('profile_collaborations.product_placement')}
                                            editMode={editingMode} 
                                            isEnabled={collaborations?.productPlacement} 
                                            onChange={(enabled) => changeHandler('productPlacement', enabled)} />
                    <CollaborationOption    collaborationOption='influencerCollaboration'
                                            label={t('profile_collaborations.influencer_collaboration')}
                                            editMode={editingMode} 
                                            isEnabled={collaborations?.influencerCollaboration} 
                                            onChange={(enabled) => changeHandler('influencerCollaboration', enabled)} />
            </Panel>
        )

    return <></>
}
