class CleanupService {

    cleanup<Type>(obj: Type): Type {
        Object.keys(obj).forEach(key => obj[key as keyof typeof obj] === undefined ? delete obj[key as keyof typeof obj] : {})

        return obj
    }

}

export default new CleanupService()