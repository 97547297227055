enum Platform {
    Snapchat = 'snapchat',
    Instagram = 'instagram',
    Tiktok = 'tiktok',
    Twitter = 'twitter',
    Twitch = 'twitch',
    Youtube = 'youtube',
    Facebook = 'facebook'
}

export default Platform
