export interface ISupportTicketResponse {
    ref: string
    topic: string
    reason: SupportTicketReason
    issue: string
    mail: string
    creatorUsername: string
    reportItemType: ReportableItemType
    reportItem: string
    type: SupportTicketType
    resolved: boolean
    created: Date
}

export enum SupportTicketReason {
    help = 'help',
    bug = 'bug',
    spam = 'spam',
    abuse = 'abuse',
    harassment = 'harassment',
    other = 'other'
}

export enum SupportTicketType {
    report = 'report',
    support = 'support'
}

export enum ReportableItemType {
    profile = 'profile',
    campaign = 'campaign',
    chat = 'chat',
    message = 'message',
    image = 'image'
}