import React, { forwardRef, useState } from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputLabel from '../../../../theme/component/input-label/input-label.component'
import InputTextArea from '../../../../theme/component/input-text-area/input-text-area.component'
import style from './panel-description.module.scss'
import profileService from '../../../../service/profile/profile.service'
import joinStyle from "../../join.module.scss";
import { useTranslation } from 'react-i18next'


function PanelDescription({ onCompletion, onReturn }: { onCompletion: () => void, onReturn: () => void }, ref: any) {
    const { t } = useTranslation()
    // const joinToken = useQuery('token')
    // const navigate = useNavigate()

    const [charactersLeft, setCharactersLeft] = useState<number>(300)
    const [description, setDescription] = useState<string>('')

    function onChangeHandler(value: string) {
        setDescription(value)
        setCharactersLeft(300 - value.length)
    }

    async function submit() {

        try {
            await profileService.setDescription(description)
        } catch (error) {
            console.log('error', error)
            return
        }

        // if (joinToken !== undefined) {
        //     navigate('/explore')
        //     return
        // }

        onCompletion()
    }

    // const buttonLabel = joinToken !== undefined ? t('join.finish') : t('join.next')

    return (
        <div ref={ref}>
            <span className={joinStyle.back_button} onClick={onReturn}/>
            <InputLabel label={t('join.description')} />
            <p className={`${joinStyle.info} mb2`}>{t('join.tell_us_about_your_work')}</p>

            <div className='text-left mb2'>
                <InputTextArea maxLength={300} onChange={onChangeHandler} />
                <span className={`${style.characterCount} mt2`}>{charactersLeft} {t('join.characters_left')}</span>
            </div>

            <div className='mb2'>
                <InputButton label={t('join.next')} fill={true} onClick={submit} />
            </div>
        </div>
    )
}

export default forwardRef(PanelDescription)
