import React, {useCallback, useEffect, useState} from 'react'
import style from './index.module.scss'
import {NotificationService} from "../../../service/notification/notification.service"
import {INotification} from "../../../service/notification/model/notification.model"
import Notification from "./component/notification"
import {v4 as uuid} from 'uuid'

export default function Notifications() {

    const onNotificationCallback = useCallback(displayMessage, [])
    const [notifications, setNotifications] = useState<JSX.Element[]>([])

    useEffect(() => {
        NotificationService.subscribe(onNotificationCallback)
        
        return () => NotificationService.unsubscribe(onNotificationCallback)
    }, [])

    function displayMessage(notification: INotification) {
        const ref = uuid()
        const rendered = <Notification notification={notification}
                                                    onSelfDestruct={() => selfDestruct(ref)}
                                                    key={ref} />

        setNotifications((notifications) => [...notifications, rendered])

        let duration = 5000

        if (notification.duration !== undefined) {
            duration = notification.duration * 1000
        }

        setTimeout(() => {
            setNotifications(notifications => notifications.filter(notification => notification.key !== ref))
        }, duration)
    }

    function selfDestruct(ref: string) {
        setNotifications(notifications => notifications.filter(notification => notification.key !== ref))
    }

    return <span className={style.notification}>{notifications}</span>
}
