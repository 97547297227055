import React from 'react'
import { useNavigate } from 'react-router-dom'
import config from '../../../../service/config/config'
import Panel from '../../../../theme/component/panel/panel.component'

import style from './index.module.scss'
import {PremiumBadge} from "../../../../theme/component/premium-badge";

const defaultAvatar= require('../../../../theme/resource/image/default/avatar_default.png')

type NavbarProps = {
    username: string
    displayname: string
    avatarUrl: string
    premium: boolean
}

export default function Navbar({ username, displayname, avatarUrl, premium }: NavbarProps) {
    const navigate = useNavigate()

    return (
        <Panel className={`${style.navbar} protected`}>
            <span className={style.back_button} onClick={() => navigate('/inbox')}>
                <span></span>
            </span>
            <h2 className={style.displayname}>{displayname}<PremiumBadge className={style.premium_badge} premium={premium}/></h2>
            <Avatar username={username} avatarUrl={avatarUrl} />
        </Panel>
    )

}

function Avatar({ username, avatarUrl }: { username: string, avatarUrl: string}) {
    const navigate = useNavigate()

    const avatar = avatarUrl ? config.host + avatarUrl : defaultAvatar

    return (
        <span className={style.avatar} onClick={() => navigate(`/profile/${username}`)}>
            <img src={avatar} />
        </span>
    )
}