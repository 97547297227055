import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import MissingError from '../../components/app/component/error/missing/missing-error.component'
import About from '../../components/documentation/about/about.component'
import Guide from '../../components/documentation/guide/guide.component'
import Disclaimer from '../../components/documentation/legal/disclaimer.component'
import Imprint from '../../components/documentation/legal/imprint.component'
import Privacy from '../../components/documentation/legal/privacy.component'
import Terms from '../../components/documentation/legal/terms.component'
import Upcoming from '../../components/documentation/upcoming/upcoming.component'
import Footer from '../../theme/layout/footer/footer.component'
import Navbar from '../../theme/layout/navbar/navbar.component'
import Toast from '../../theme/layout/notifications'
import Explore from '../explore/index.component'
import Inbox from '../inbox/inbox.component'
import Join from '../join/join.component'
import Network from '../network'
import Profile from '../profile/index.component'
import Search from '../search/search.component'
import Settings from '../settings/settings.component'
import Signout from '../signout'
import Support from '../support/support.component'
import RequireAuthorization from '../../components/app/component/require-authorizion/require-authorization.component'
import RequireAuthentication from '../../components/app/component/require-authentication/require-authentication.component'
import RequireSubscription from '../../components/app/component/require-subscription/require-subscription.component'
import Marketplace from '../marketplace/index.component'
import { OfferDetail } from "../marketplace/component/offer-detail";
import BlogDetail from '../blog/component/blogdetail/index.component'
import Blog from '../blog/index.component'
import Login from "../login";
import PasswordReset from "../password-recovery";
import ForgotPassword from "../forgot-password";
import ThirdPartyLogin from "../third-party-login";
import { IWebMessage } from "../../service/websocket/model/web-message.interface";
import { IWebTextMessage } from "../../service/websocket/model/web-text-message.interface";
import { NotificationService } from "../../service/notification/notification.service";
import { NotificationType } from "../../service/notification/model/notification-type.enum";
import SessionService from "../../service/session/session.service";
import { WebsocketService } from "../../service/websocket/websocket.service";
import AccountVerification from "../account-verification";
import Premium from "../premium";
import Bookmarks from "../bookmarks";
import { DirectMessageData } from "../../service/notification/model/direct-message.model";
import BoostPicker from "../boost";
import BackofficeRouter from "../../views/backoffice/components/backoffice-router/backoffice-router.component";
import BrandAccess from "../brand-access";
import {Banner} from "../banner";

export default function InfluspaceRouter() {
    const [loggedIn, setLoggedIn] = useState<boolean | null>(null);

    const onMessageCallback = useCallback((webMessage: IWebMessage) => {
        const webTextMessage = webMessage.content as IWebTextMessage;

        NotificationService.displayNotification({
            message: `${webTextMessage.senderDisplayname}: ${webTextMessage.content}`,
            type: NotificationType.directMessage,
            duration: 10,
            data: {
                senderUsername: webTextMessage.senderUsername,
            } as DirectMessageData,
        });
    }, []);

    useEffect(() => {
        if (SessionService.isAuthorized()) {
            WebsocketService.subscribe(onMessageCallback);
        }

        return () => WebsocketService.unsubscribe(onMessageCallback);
    }, [onMessageCallback]);

    function onLogin(loggedIn: boolean) {
        setLoggedIn(loggedIn);

        if (SessionService.isAuthorized()) {
            WebsocketService.subscribe(onMessageCallback);
        } else {
            WebsocketService.unsubscribe(onMessageCallback);
        }
    }

    return (
        <>
            <Navbar loggedIn={loggedIn} />
            <Toast />
            <main>
                <Routes>
                    <Route path='/login' element={<Login onLogin={onLogin} />} />
                    <Route path='/authorize' element={<ThirdPartyLogin onLogin={onLogin} />} />
                    <Route path='/signout' element={<RequireAuthentication><Signout /></RequireAuthentication>} />
                    <Route path='/join' element={<Join onLogin={onLogin} />} />
                    <Route path='/verify/:token' element={<AccountVerification onLogin={onLogin} />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    <Route path='/recover-password' element={<PasswordReset />} />
                    <Route path='/explore' element={<RequireSubscription><Explore /></RequireSubscription>} />
                    <Route path='/search' element={<RequireSubscription><Search /></RequireSubscription>} />
                    <Route path='/profile/:username' element={<RequireAuthentication><Profile /></RequireAuthentication>} />
                    <Route path='/marketplace' element={<RequireSubscription><Marketplace /></RequireSubscription>} />
                    <Route path='/marketplace/offer/:offerRef' element={<OfferDetail />} />
                    <Route path='/settings' element={<RequireAuthorization><Settings /></RequireAuthorization>} />
                    <Route path='/bookmarks' element={<RequireAuthorization><Bookmarks /></RequireAuthorization>} />
                    <Route path='/boost' element={<RequireAuthorization><BoostPicker /></RequireAuthorization>} />
                    <Route path='/inbox' element={<RequireSubscription><Inbox /></RequireSubscription>} />
                    <Route path='/network/redirect/*' element={<RequireAuthentication><Network /></RequireAuthentication>} />
                    <Route path='/premium' element={<RequireAuthorization><Premium /></RequireAuthorization>} />
                    <Route path='/access' element={<RequireAuthorization><BrandAccess /></RequireAuthorization>} />
                    <Route path='/disclaimer' element={<Disclaimer />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/imprint' element={<Imprint />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path='/support' element={<Support />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/guide' element={<Guide />} />
                    <Route path='/upcoming' element={<Upcoming />} />
                    <Route path='/blog/' element={<Blog />} />
                    <Route path='/blogs/:ref' element={<BlogDetail />} />
                    <Route path='/backoffice/*' element={<BackofficeRouter />} />
                    <Route path={'/banner'} element={<Banner/>}/>
                    <Route path='*' element={<MissingError />} />
                </Routes>
            </main>
            <Footer />
        </>
    );
}


