import {IDevComponent} from "./dev-component.interface";
import {DevComponentType} from "./dev-component-type.enum";

export const componentList: IDevComponent[] = [
    {
        path: '/dev/login',
        name: 'Login',
        description: 'Sign in & Sign out + Session Info',
        topic: DevComponentType.service
    },
    {
        path: '/dev/network',
        name: 'Network',
        description: 'Network OAuth',
        topic: DevComponentType.service
    },
    {
        path: '/dev/image-loading',
        name: 'Image',
        description: 'Image Loading',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/overlay',
        name: 'Overlay',
        description: 'Popup Overlay',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/notifications',
        name: 'Notifications',
        description: 'Notification Service',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/placeholder',
        name: 'Placeholder',
        description: 'Font + Horizontal Ruler + Title',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/panel',
        name: 'Panel',
        description: 'Themed Panel',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/input-button',
        name: 'Input Button',
        description: 'Themed Input Button',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/input-text',
        name: 'Input Text',
        description: 'Themed Input Text',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/input-text-area',
        name: 'Input Text',
        description: 'Themed Input Textarea',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/errors',
        name: 'Errors',
        description: 'Available Error',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/profile-tile',
        name: 'Profile Tile',
        description: 'Profile Tile displaying details',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/profile-tile-grid',
        name: 'Tile Grid',
        description: 'Grid of Profile Tiles',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/loading',
        name: 'Loading',
        description: 'Fullscreen Loading Animation',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/loading-content',
        name: 'Loader',
        description: 'Inline Loading Animation',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/checkbox',
        name: 'Checkbox',
        description: 'Themed Checkbox',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/input-dropdown',
        name: 'Dropdown',
        description: 'Themed Dropdown',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/chat-box',
        name: 'ChatBox',
        description: 'ChatBox displaying details',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/input-slider',
        name: 'Slider',
        description: 'Themed Slider',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/date-picker',
        name: 'Date-Picker',
        description: 'Themed Date Picker',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/web-socket',
        name: 'Web-Socket',
        description: 'Web-Socket',
        topic: DevComponentType.service
    },
    {
        path: '/dev/grid',
        name: 'Grid',
        description: 'Grid System',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/inline-notifications',
        name: 'Inline Notification',
        description: 'Inline Notification',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/system-info',
        name: 'System',
        description: 'System Info',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/brand-label',
        name: 'Labels',
        description: 'Brand Labels',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/slide-show',
        name: 'Slides',
        description: 'Slide Show',
        topic: DevComponentType.userInterface
    },
    {
        path: '/dev/boost-button',
        name: 'Boost Button',
        description: 'Small Boost Button with Icon',
        topic: DevComponentType.userInterface
    }
]

