import React from "react"
import Overlay from "../../../../../../theme/component/overlay/overlay.component"
import InputButton from "../../../../../../theme/component/input-button"
import { ButtonColor } from "../../../../../../theme/component/input-button/models/button-color.model"
import { useNavigate } from 'react-router-dom'

import settingsService from "../../../../../../service/settings/settings.service"
import sessionService from "../../../../../../service/session/session.service"
import {useTranslation} from "react-i18next";

interface AccountCloseBoxProps {
    open: boolean,
    onClose: (success?: boolean) => void,
}


export default function PasswordForgotBox({ open, onClose }: AccountCloseBoxProps) {
    const navigate = useNavigate()
    const { t } = useTranslation()


    async function onDeleteAccount() {
        try {
            await settingsService.closeAccount()
            await sessionService.signOut()
            navigate('/')        
        } catch (error) {
        }
    }


  return (
    <Overlay title={t('account.close_confirmation.title')} open={open} onClose={onClose}>
        <div className="mt2">
            <div className="mb2">{t('account.close_confirmation.description')}</div>

            <div>
                <span className='float-right'><InputButton  label='Cancel' onClick={onClose}  /></span>
                <span className='float-left'><InputButton color={ButtonColor.Failure} label={t('account.close_confirmation.confirm_button')} onClick={onDeleteAccount} /></span>
            </div>
        </div>
    </Overlay>
  )
}

