import React from "react";
import {useTranslation} from "react-i18next";
import style from "./index.module.scss"
import {
    ConnectCTA
} from "../../../../../../../profile/component/profile-networks/component/connect_cta/index.component";
import FollowerCount
    from "../../../../../../../profile/component/profile-networks/component/follower-count/follower-count.component";
import {Link} from "react-router-dom";

export function PopupCTA({ className }: { className?: string }) {
    const {t} = useTranslation()

    return <div className={style.container}>
        <div className={`${style.inner_container} ${className}`}>
            <div className={style.left}>
                <div className={style.authenticate_cta}>
                    <div className={style.reason_container}>
                        <span className={`${style.icon} ${style.attention}`}/>
                        <h3 className={style.reason}>{t('offer_connect_cta.part_1')}</h3>
                    </div>
                    <div className={style.reason_container}>
                        <span className={`${style.icon} ${style.verification}`}/>
                        <h3 className={style.reason}>{t('offer_connect_cta.part_2')}</h3>
                    </div>
                    <div className={style.reason_container}>
                        <span className={`${style.icon} ${style.community}`}/>
                        <h3 className={style.reason}>{t('offer_connect_cta.part_3')}</h3>
                    </div>
                </div>
            </div>
            <div className={style.right}>
                <div className={style.container_networks}>
                    <div className={style.networks}>
                        <span className={`${style.network} ${style.facebook}`}></span>
                        <span className={`${style.network} ${style.tiktok}`}></span>
                        <span className={`${style.network} ${style.twitch}`}></span>
                        <span className={`${style.network} ${style.instagram}`}></span>
                        <span className={`${style.network} ${style.x}`}></span>
                        <span className={`${style.network} ${style.youtube}`}></span>
                    </div>
                    <div className={style.cta_box}>{t('profile_networks.connect_cta.title')}</div>
                    <div className={style.connect}>
                        <FollowerCount networks={[]} totalFollower={""} editingMode={true}/>
                    </div>
                    <Link to="/guide" className={style.learn_more}>{t('profile_networks.connect_cta.learn_more')}</Link>
                </div>
            </div>
        </div>
    </div>
}