import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import style from './date-picker.module.scss'

type InputDatePickerProps = {
    onChange?: (date: string) => void
    onFocus?: () => void
    onBlur?: () => void
    onEnter?: () => void
    focus?: boolean
    disabled?: boolean
    error?: string
    label?: string
    info?: string
  }

export default function DatePicker({ onChange, onFocus, onBlur, onEnter, focus, disabled, error, label, info }: InputDatePickerProps) {
    const today = new Date()
    const minDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate()).toISOString().split('T')[0]
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()).toISOString().split('T')[0]
    
    const [errorMessage, setErrorMessage] = useState<string>('')

    const errorStyle = errorMessage ? style.error : ''

    const inputRef = useRef()

    function handleDateChange(event: ChangeEvent<HTMLInputElement>) {
        const rawDate = event?.target?.value
        const splitDate = rawDate?.split('-')
        const year = splitDate?.[0]
        const month = splitDate?.[1]
        const day = splitDate?.[2]

        const formattedDate = `${day}.${month}.${year}`

        if (onChange) {
            onChange(formattedDate)
        }
    }

    function onBlurHandler() {
        if (onBlur !== undefined) 
        onBlur()
    }

    useEffect(() => {
        setErrorMessage(error)
        
        if (focus) {
            const input = inputRef.current as HTMLInputElement
            input.focus()
        }

    }, [error])

    return (
        <span className={`${style.container} ${errorStyle}`}>
        <Label label={label} />
        <span className={style.inputContainer}>
            <input type='date' ref={inputRef} className={style.input} min={minDate} max={maxDate} placeholder='Birthday' onChange={handleDateChange} />
            <Info info={info} /> 
        </span>
        <ErrorMessage errorMessage={errorMessage} />
        </span>
    )
}

function Label({ label} : { label: string }) {
    if (label === undefined || label === '')
        return <></>
  
    return <span className={style.label}>{label}</span>
  }
  
  function ErrorMessage({ errorMessage }: { errorMessage?: string }) {
  
    if (errorMessage === undefined || errorMessage === '')
      return <></>
    
    return <span className={style.errorMessage}>{errorMessage}</span>
  }
  
  function Info({ info }: { info?: string }) {
    const [showInfo, setShowInfo] = useState<boolean>(false)
  
    if (info)
      return (
        <>
          <span className={style.infoIcon} onClick={() => setShowInfo(!showInfo)} /> 
          <InfoMessage showInfo={showInfo} info={info} />
        </>
      )
  
      return <></>
  }
  
  function InfoMessage({ showInfo, info }: { showInfo: boolean, info?: string }) {
    if (!showInfo)
      return <></>
  
    return <span className={style.infoMessage}>{showInfo ? info : ''}</span>
  }