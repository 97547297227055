import axios from 'axios'
import SupportTicketRequest from './model/SupportTicketRequest'
import {ISupportTicketResponse} from "./model/SupportTicketResponse";

class SupportService {

    private root = '/support'
    private client = axios

    async sendTicket(supportRequest: SupportTicketRequest) : Promise<SupportTicketRequest> {
        try {
            let response = await this.client.post(this.root, supportRequest)

            return response.status === 200 ? response.data : null
        } catch(error) {
            console.log(error.response.data)
            return null
        }
    }

    async getTickets(filter: string) : Promise<ISupportTicketResponse[]> {

        const response = await this.client.get(`${this.root}/backoffice?filter=${filter}`)
        return response.data
    }

    async resolveTicket(ref: string, resolved: boolean) : Promise<ISupportTicketResponse> {
        const response = await this.client.post(`${this.root}/backoffice/resolve`, {ref, resolved})
        return response.data
    }
}

export default new SupportService()