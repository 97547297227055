import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import healthService from '../../../../service/health/health.service'
import Panel from '../../../../theme/component/panel/panel.component'

import style from './dev-system-info.module.scss'

export default function DevSystemInfo() {
    const [apiVersion, setApiVersion] = useState<string>('Loading..')

    useEffect(() => {
        async function apiVersion() {
            setApiVersion(await healthService.getApiVersion())
        }

        apiVersion()
    }, [])

    return (
        <section className='mt4 text-center'>
            <Panel title='Influspace'>
                <span className={style.label}>
                    <div>Frontend Version<span>{process.env.REACT_APP_VERSION}</span></div>
                    <div>Mode<span>{process.env.REACT_APP_MODE}</span></div>
                    <div>&nbsp;</div>
                    <div>API Host<span>{process.env.REACT_APP_HOST}</span></div>
                    <div>API Root<span>{process.env.REACT_APP_API_ROOT}</span></div>
                    <div>API Version<span>{apiVersion}</span></div>
                    <div>&nbsp;</div>
                    <div>Checkout<span><Link to='/explore'>Explore</Link></span></div>
                </span>
            </Panel>
        </section>
    )
}
