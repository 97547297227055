import React from 'react';
import style from './index.module.scss';
import { HeroSection } from './component/hero';
import { OverviewSection } from './component/overview';
import { BenefitsSection } from './component/benefits';
import Subscriptions from './component/subscription';
import BlogSection from './component/blog';
import Support from './component/support';
import FooterSection from './component/footer';
import NewsletterSection from './component/newsletter';
import JoinSection from './component/join';
import { ShowBusinessProvider } from './component/benefits/SubscriptionContext';
import LanguageSelector from "../../components/common/language-selection/index.component";
import {Navigation} from "./component/nvigation";

export default function Landing() {
    return <div className={style.landing}>
        <LanguageSelector />
        <section className={style.container}>
            <Navigation />
            <HeroSection />
            <OverviewSection />
            <ShowBusinessProvider>
                <BenefitsSection />
                <Subscriptions />
            </ShowBusinessProvider >
            <JoinSection />
            <Support />
            <BlogSection />
            <NewsletterSection />
            <FooterSection />
        </section>
    </div>

}