import React, {useEffect, useState} from "react";
import {IProfileTile} from "../../service/search/model/profile-tile.model";
import ProfileService from "../../service/profile/profile.service";
import style from "../explore/index.module.scss";
import ProfileGrid from "../../theme/component/profile-grid/profile-grid.component";
import {useTranslation} from "react-i18next";
import {IOffer} from "../../service/marketplace/model/offer.model";
import {OfferOverview} from "../marketplace/component/offer/component/overview";
import styleInbox from "../inbox/index.module.scss";
import InputButton from "../../theme/component/input-button";
import {ButtonColor} from "../../theme/component/input-button/models/button-color.model";
import Panel from "../../theme/component/panel/panel.component";
import {useNavigate} from "react-router-dom";

export default function Bookmarks() {

    const [profiles, setProfiles] = useState<IProfileTile[]>([]);
    const [offers, setOffers] = useState<IOffer[]>([]);

    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [profiles, offers] = await ProfileService.getBookmarks();
                setProfiles(profiles)
                setOffers(offers)
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (offers?.length < 1 && profiles?.length < 1)
        return <Panel className={styleInbox.container}>
            <h2>{t('bookmarks.bookmarks_empty_title')}</h2>
            <div style={{color: 'var(--g-label-color)'}}>{t('chats.inbox_empty_call_to_action')}</div>
            <div className={styleInbox.orange_cat} />
            <InputButton label={t('chats.inbox_empty_explore_action')} onClick={() => navigate('/explore')} fill={true} color={ButtonColor.Primary}/>
        </Panel>


    return <section className="mb3">



        <div className={profiles?.length > 0 ? 'mb4' : 'none' }>
            <h2 className={style.title}>{t('bookmarks.title_profiles')}</h2>
            <ProfileGrid profiles={profiles} />
        </div>



        <div className={offers?.length > 0 ? '' : 'none'}>
            <h2 className={style.title}>{t('bookmarks.title_offers')}</h2>
            {offers?.map(offer => <OfferOverview offer={offer}/>)}
        </div>
    </section>
}