import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
    targetDate: Date;
    className?: string;
    format?: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate, className, format }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {
            hours: '00',
            minutes: '00',
            seconds: '00',
        };

        if (difference > 0) {
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            timeLeft = {
                hours: hours < 10 ? `0${hours}` : `${hours}`,
                minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
                seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const formatTime = () => {
        if (format) {
            return format
                .replace('HH', timeLeft.hours)
                .replace('MM', timeLeft.minutes)
                .replace('SS', timeLeft.seconds);
        } else {
            return `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`;
        }
    };

    return (
        <div className={className}>
            {formatTime()}
        </div>
    );
};

export default CountdownTimer;
