import React, { forwardRef, useEffect, useState } from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputLabel from '../../../../theme/component/input-label/input-label.component'
import { KeywordTextArea } from '../../../profile/component/profile-audience/component/profile-keywords/component/keyword-textarea.component'
import { IKeyword } from '../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model'
import KeywordTag from '../../../profile/component/profile-audience/component/profile-keywords/component/keyword-tag.component'
import profileService from '../../../../service/profile/profile.service'
import style from './index.module.scss'
import ProfileService from "../../../../service/profile/profile.service";
import joinStyle from '../../join.module.scss'
import { useTranslation } from 'react-i18next'

function PanelKeywords({ onCompletion, onReturn }: { onCompletion: () => void, onReturn: () => void}, ref: any) {
    const { t } = useTranslation()

    const [keywordTags, setKeywordTags] = useState<JSX.Element[]>()
    const [keywords, setKeywords] = useState<IKeyword[]>([])

    const [suggestions, setSuggestions] = useState<string[]>([])

    async function onUpdateKeywords(newKeyword: IKeyword) {
        if (keywords.find((keyword) => keyword.word === newKeyword.word) !== undefined)
            return

        try {
            const existingKewordIndex = keywords.findIndex((keyword) => keyword.ref === newKeyword.ref)
            if (existingKewordIndex !== -1) {
                const response = await profileService.editKeyword(newKeyword)
                keywords[existingKewordIndex] = response
                setKeywords([...keywords])
            } else {
                const response = await profileService.addKeyword(newKeyword)
                keywords.push(response)
                setKeywords([...keywords])
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function onDeleteKeyword(keyword: IKeyword) {
        try {
            const response = await profileService.deleteKeyword(keyword)
            setKeywords(keywords.filter((keyword) => keyword.ref !== response.ref))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setKeywordTags(keywords?.map((keyword) => <KeywordTag onChange={onUpdateKeywords} key={keyword.ref} onDelete={onDeleteKeyword} keyword={keyword} editingMode={true}/>))
    }, [keywords])

    useEffect(() => {
        fetchSuggestions()
    }, []);

    async function fetchSuggestions() {
        try {
            setSuggestions(await ProfileService.getKeywordSuggestions())
        } catch(error) {
            console.log(error)
        }
    }

    function KeywordSuggestion({word, onClick}: {word: string, onClick: (word: string) => void}) {
        return <span className={style.tag_view} onClick={() => onClick(word)}>{word}</span>
    }

    function addSuggestion(word: string) {
        onUpdateKeywords({word})
    }

    return (
        <div ref={ref}>
            <span className={joinStyle.back_button} onClick={onReturn}/>
            <InputLabel label='Keywords' />
            <p className={`${joinStyle.info} mb2`}>{t('join.keywords_description')}</p>

            <div className='text-left mb2'>
                <KeywordTextArea hintService={profileService} max={10} onChange={onUpdateKeywords}>{keywordTags}</KeywordTextArea>
            </div>

            <div className={style.keyword_suggestions}>
                {suggestions.map((suggestion) => <KeywordSuggestion key={suggestion} word={suggestion} onClick={addSuggestion}/>)}
            </div>
            <div className='mb2'>
                <InputButton label={t('join.next')} fill={true} onClick={onCompletion} />
            </div>
        </div>
    )
}

export default forwardRef(PanelKeywords)
