import React from 'react'
import { Link } from 'react-router-dom'
import sessionService from '../../../../../service/session/session.service'
import Panel from '../../../../../theme/component/panel/panel.component'

import style from '../error.module.scss'

type CustomErrorProps = {
    title: string
    reason: string
}

export default function CustomError({ title, reason }: CustomErrorProps) {
    const returnTo = sessionService.isAuthorized() ? `/profile/${sessionService.getUsername()}` : '/explore'

    return (
        <Panel className={style.panel}>
            <span className={style.icon} />
            <div className={style.title}>{title}</div>
            <span className={style.description}>{reason}</span>
            <Link to={returnTo} className={style.buttonOutlined}>Back to Influspace</Link>
        </Panel>
    )
}