import React, { useEffect, useState } from 'react'
import useClickOutside from '../../hooks/use-click-outside.component'
import Panel from '../panel/panel.component'

import style from './overlay.module.scss'
import ScrollToTop from "../../hooks/scoll-to-top.component";

type OverlayProps = {
    children: any
    title: string
    open: boolean
    onClose: () => void
    disableTapout?: boolean
    className?: string
    titleClass?: string
}

export default function Overlay({ children, title, open, onClose, className, titleClass, disableTapout = false }: OverlayProps) {
    const [popupOpen, setPopupOpen] = useState<boolean>(false)

    const ref = useClickOutside(() => closeBackdrop())

    const popupState = popupOpen ? style.show : ''

    useEffect(() => {
        setPopupOpen(open)
    }, [open])

    function closePopup() {
        setPopupOpen(false)
        onClose()
    }

    function closeBackdrop() {
        if (popupOpen === false)
            return

        if (disableTapout)
            return

        closePopup()
    }

    return (
        <div className={`${style.overlay} ${popupState}`}>
            <ScrollToTop/>
            <Panel className={`${style.panel} ${className}`} ref={ref}>
                <div className={`${style.title} ${titleClass}`}>{title}</div>
                <span className={style.close} onClick={closePopup} />
                <div>{children}</div>
            </Panel>
        </div>
    )
}
