import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import config from "./config/config"
import sessionService from "./session/session.service"

class ApiClientService {

    async configureClient() {
        const session = await sessionService.validateSession()

        axios.defaults.baseURL = config.apiRoot
        axios.defaults.timeout = config.timeout
        axios.defaults.headers.common = { Authorization: `token ${session.token}` }

        axios.interceptors.request.use(this.onRequest)
        axios.interceptors.response.use(this.onResponse, this.onResponseError)
    }

    onRequest(request: AxiosRequestConfig) {
        return request
    }

    onResponse(response: AxiosResponse) {
        return response
    }

    onResponseError(error: AxiosError) {

        if (error?.response?.status === 429)
            sessionService.validateSession()

        return Promise.reject(error)
    }

}

export default new ApiClientService()