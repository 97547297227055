import React, { useState } from "react";
import { AccountChoice } from "../../../../service/network/model/AccountChoice";
import Panel from "../../../../theme/component/panel/panel.component";
import { InstagramAccountComponent } from "./component/instagram-account";
import { useNavigate } from "react-router-dom";
import sessionService from "../../../../service/session/session.service";
import SessionService from "../../../../service/session/session.service";
import NetworkService from "../../../../service/network/network.service";
import TechnicalError from "../../../../components/app/component/error/technical/technical-error.component";
import Platform from "../../../../service/network/model/Platform";
import { useTranslation } from "react-i18next";

type InstagramAccountChoiceProps = {
    instagramAccounts: AccountChoice[],
    state: string
}

export function InstagramAccountChoice({ instagramAccounts, state }: InstagramAccountChoiceProps) {
    const { t } = useTranslation();
    const [error, setError] = useState<boolean>(false);

    const navigate = useNavigate();

    async function onConnect(account: AccountChoice) {
        try {
            await SessionService.validateSession();

            if (account.platform === Platform.Instagram) {
                await NetworkService.connectInstagram(account, state);
            } else {
                await NetworkService.connectFacebook(account, state);
            }
        } catch (error) {
            setError(true);
        }

        const username = sessionService.getUsername();
        navigate(`/profile/${username}`);
    }

    if (error) return <TechnicalError />;

    return (
        <Panel>
            <div className='text-center'>{t('instagram_account_choice.prompt')}</div>
            {instagramAccounts.map(account => (
                <InstagramAccountComponent key={account.id} instagramAccount={account} onClick={onConnect} />
            ))}
        </Panel>
    );
}
