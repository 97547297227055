import React, { useState } from 'react'
import Panel from '../../../../theme/component/panel/panel.component'
import InputCheckbox from '../../../../theme/component/input-checkbox/input-checkbox.component'

export default function DevCheckbox() {
    const [checked, setChecked] = useState<boolean>(false)

    return (
        <section>
            <Panel title='Checkbox Demo'>
                <br />
                <div className='mb2'><InputCheckbox label='Some Option Nr. 1' onChange={setChecked} /></div>
                <div className='mb2'><InputCheckbox label='Nos Entendemos' onChange={setChecked} /></div>
                <div className='mb2'><InputCheckbox label='Una abla éspanol' onChange={setChecked} /></div>
                <h2>Checked {checked ? 'Yes' : 'No'}</h2>
                <br />
                <br />
                <InputCheckbox label='Checbox number two' checked={true} />
            </Panel>
        </section>
    )
}
