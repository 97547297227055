import React, { forwardRef } from 'react'

import style from './panel.module.scss'

type InputButtonProps = {
  id?: string
  className?: string
  overrideMargin?: string
  overridePadding?: string
  overrideBorderradius?: string
  title?: string
  children: any
  overrideStyle?: any
  onClick?: () => void
}

function Panel({ id, overrideMargin, overridePadding, overrideBorderradius, className, title, children, overrideStyle, onClick }: InputButtonProps, ref: any) {
  const margin = overrideMargin ? overrideMargin : style.defaultMargin
  const padding = overridePadding ? overridePadding : style.defaultPadding
  const borderradius = overrideBorderradius ? overrideBorderradius : style.defaultBorderradius
  const additionalClasses = className ? className : ''

  return (
    <div onClick={onClick} className={`${style.panel} ${margin} ${padding} ${borderradius} ${additionalClasses}`} style={overrideStyle} id={id} ref={ref}>
      {title &&  <h1>{title}</h1>}
      {children}
    </div>
  )
}

export default forwardRef(Panel)