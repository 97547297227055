import React from "react"
import Overlay from "../../../../../../theme/component/overlay/overlay.component"
import InputButton from "../../../../../../theme/component/input-button"
import { ButtonColor } from "../../../../../../theme/component/input-button/models/button-color.model"
import { useNavigate } from 'react-router-dom'
import {BillingService} from "../../../../../../service/billing/billing.service"

interface SubscriptionCancelBoxProps {
    open: boolean,
    onClose: (success?: boolean) => void,
}


export default function SubscriptionCancelBox({ open, onClose}: SubscriptionCancelBoxProps) {
    const navigate = useNavigate()


    async function cancelSubscription() {
        try {
            await BillingService.cancelSubscription()
            navigate('/')        
        } catch (error) {
            console.log(error)
        }
    }


  return (
    <Overlay title='Close Account' open={open} onClose={onClose}>
        <div className="mt2">
            <div className="mb2">Do You really want to cancel your Subscription? You will not be able to use the services of Influspace anymore. Your Profile and chats will be saved until you reactivate your subscription!</div>

            <div>
                <span className='float-right'><InputButton  label='Cancel' onClick={onClose}  /></span>
                <span className='float-left'><InputButton color={ButtonColor.Failure} label='Cancel Subscription' onClick={cancelSubscription} /></span>
            </div>
        </div>
    </Overlay>
  )
}

