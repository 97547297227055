import React, { useEffect, useState } from 'react';
import InputText from '../../../../../../theme/component/input-text/input-text.component';
import InputButton from '../../../../../../theme/component/input-button';
import '../settings-details.scss';
import style from './password-details.module.scss';
import settingsService from '../../../../../../service/settings/settings.service';
import PasswordForgotBox from './password-forgot-box.component';
import ISettings from '../../../../../../service/settings/model/settings.model';
import { Criteria } from '../../../../../../theme/component/criteria';
import { NotificationService } from '../../../../../../service/notification/notification.service';
import { NotificationType } from '../../../../../../service/notification/model/notification-type.enum';
import { useTranslation } from 'react-i18next';

type PasswordDetailsProps = {
    settings: ISettings;
};

export default function PasswordDetails({ settings }: PasswordDetailsProps) {
    const { t } = useTranslation();
    const [passwordDetails, setPasswordDetails] = useState({
        password: '',
        currentPassword: '',
        repeatPassword: '',
    });

    const [changeButtonDisabled, setChangeButtonDisabled] = useState(true);
    const [feedback, setFeedback] = useState({ text: '', color: '' });
    const [openPasswordForgotBox, setOpenPasswordForgotBox] = useState(false);
    const [mail, setMail] = useState<string>();

    const [passwordContainsNumber, setPasswordContainsNumber] = useState<boolean>(false);
    const [passwordContainsSpecialCharacter, setPasswordContainsSpecialCharacter] = useState<boolean>(false);
    const [passwordContainsTenCharacters, setPasswordContainsTenCharacters] = useState<boolean>(false);
    const [passwordRepeatEqual, setPasswordRepeatEqual] = useState<boolean>(false);
    const [passwordValid, setPasswordValid] = useState(false);

    const { password, currentPassword, repeatPassword } = passwordDetails;

    useEffect(() => {
        setMail(settings?.mail);
    }, [settings]);

    function validateInput(passwordDetails: { password: string; repeatPassword: string; currentPassword: string }) {
        const password = passwordDetails.password;

        const passwordContainsNumber = /[0-9]/.test(password);
        const passwordContainsSpecialCharacter = /[^a-zA-Z0-9]/.test(password);
        const passwordContainsTenCharacters = password.length >= 10;
        const repeatedPasswordEqual = password === passwordDetails.repeatPassword;

        const passwordValid = repeatedPasswordEqual && passwordContainsNumber && passwordContainsSpecialCharacter && passwordContainsTenCharacters;

        setPasswordContainsNumber(passwordContainsNumber);
        setPasswordContainsSpecialCharacter(passwordContainsSpecialCharacter);
        setPasswordContainsTenCharacters(passwordContainsTenCharacters);
        setPasswordRepeatEqual(repeatedPasswordEqual);
        setPasswordValid(passwordValid);
    }

    function handleRepeatPasswordChange(repeatPassword: string) {
        const newPasswordDetails = { password: passwordDetails.password, currentPassword: passwordDetails.currentPassword, repeatPassword };
        setPasswordDetails(newPasswordDetails);
        validateInput(newPasswordDetails);
    }

    function handlePasswordChange(password: string) {
        const newPasswordDetails = { repeatPassword: passwordDetails.repeatPassword, currentPassword: passwordDetails.currentPassword, password };
        setPasswordDetails(newPasswordDetails);
        validateInput(newPasswordDetails);
    }

    async function submit() {
        setPasswordDetails({ password: '', currentPassword: '', repeatPassword: '' });
        const { passwordChanged, error } = await settingsService.changePassword(password, currentPassword);
        NotificationService.displayNotification({
            message: passwordChanged ? t('password.password_changed') : error,
            type: passwordChanged ? NotificationType.success : NotificationType.failure,
        });
    }

    const onForgotPasswordClicked = () => setOpenPasswordForgotBox(true);

    useEffect(() => {
        const isFormValid = currentPassword && passwordValid && passwordRepeatEqual;
        setChangeButtonDisabled(!isFormValid);
    }, [passwordDetails]);

    const onCloseForgotBox = (success?: boolean) => {
        setOpenPasswordForgotBox(false);
        setFeedback({
            text: success ? t('password.recovery_mail_sent') : t('password.recovery_mail_not_sent'),
            color: success ? 'green' : 'red',
        });
    };

    const passwordCriteria = passwordDetails.password.length > 0 ? (
        <div className={style.criteria_container}>
            <Criteria fulfilled={passwordContainsNumber}>{t('password.criteria_contains_numbers')}</Criteria>
            <Criteria fulfilled={passwordContainsSpecialCharacter}>{t('password.criteria_contains_special_characters')}</Criteria>
            <Criteria fulfilled={passwordContainsTenCharacters}>{t('password.criteria_ten_characters')}</Criteria>
            <Criteria fulfilled={passwordRepeatEqual}>{t('password.criteria_confirm_password')}</Criteria>
        </div>
    ) : undefined;

    const handleInputChange = (key: keyof typeof passwordDetails) => (value: string) => setPasswordDetails(prev => ({ ...prev, [key]: value }));

    return (
        <div className="mb2">
            <PasswordForgotBox open={openPasswordForgotBox} onClose={onCloseForgotBox} mail={mail} />
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>{t('password.title')}</div>
                    <div className={style.info}>{t('password.manage_password')}</div>
                </div>
            </div>
            <div className='mb1'>
                <InputText placeholder={t('password.current_password')} isPassword={true} initialValue={passwordDetails.currentPassword} onChange={handleInputChange('currentPassword')} />
            </div>
            <div className='mb1'>
                <InputText placeholder={t('password.new_password')} initialValue={passwordDetails.password} isPassword={true} onChange={handlePasswordChange} />
            </div>
            <div className='mb1'>
                <InputText placeholder={t('password.confirm_new_password')} initialValue={passwordDetails.repeatPassword} isPassword={true} onChange={handleRepeatPasswordChange} />
            </div>
            {passwordCriteria}
            <div className='mb1'>
                <InputButton label={t('password.change_password')} onClick={submit} outline={false} disabled={changeButtonDisabled} />
                <span className={style.forgot_password} onClick={onForgotPasswordClicked}>{t('password.forgot_password')}</span>
            </div>
            <div className='mb1' style={{ color: feedback.color }} onClick={() => setFeedback({ text: '', color: '' })}>
                {feedback.text}
            </div>
        </div>
    );
}
