import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Panel from '../../theme/component/panel/panel.component';
import sessionService from '../../service/session/session.service';
import useQuery from '../../theme/hooks/use-query.component';
import Pagination from '../../theme/component/pagination/pagination.component';
import { InlineToastType } from '../../theme/component/inline-notification/model/inline-notification-type.model';
import { CSSTransition } from 'react-transition-group';
import { JoinViews } from './model/join-views.model';
import PanelAccount from './component/panel-account/panel-account.component';
import PanelJoin from './component/panel-join';
import PanelVerificationReminder from './component/panel-verification-reminder/panel-verification-reminder.component';
import PanelDescription from './component/panel-description/panel-description.component';
import PanelLocation from './component/panel-location/panel-location.component';
import PanelAudience from './component/panel-audience/panel-audience.component';
import PanelKeywords from "./component/panel-keywords";
import GoogleLegalPrint from "../../components/common/google-legal-print";
import PanelNetworks from "./component/panel-networks/index.component";
import { useTranslation } from "react-i18next";
import style from './join.module.scss';
import animation from './join-animation.module.scss';
import PanelCollaborations from "./component/panel-collaborations/panel-collarboations.component";
import PanelAvatar from "./component/panel-avatar";

type JoinProps = {
    onLogin: (loggedIn: boolean) => void
}

export default function Join({ onLogin }: JoinProps) {
    const { t } = useTranslation();
    const joinToken = useQuery('token');
    const urlExtractedQuery = useQuery('return_to', '/explore');
    const navigate = useNavigate();

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastType, setToastType] = useState<InlineToastType>(null);

    const [panelHeight, setPanelHeight] = useState(null);
    const panelRef = useRef(null);

    const [activeView, setActiveView] = useState<JoinViews>(JoinViews.Join);
    const [isReturning, setIsReturning] = useState<boolean>(false);

    const [mail, setMail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const joinPanel = useRef(null);
    const accountPanel = useRef(null);
    const collaborationPanel = useRef(null);
    const audiencePanel = useRef(null);
    const keywordsPanel = useRef(null);
    const locationPanel = useRef(null);
    const descriptionPanel = useRef(null);
    const networksPanel = useRef(null);
    const avatarPanel = useRef(null);
    const verificationReminderPanel = useRef(null);
    const animationDuration = 2000;

    const animationContainer = useRef(null);

    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        if (sessionService.getSessionDetails().authenticated) {
            navigate(urlExtractedQuery);
        }

        if (joinToken !== undefined) {
            updateView(JoinViews.Account);
        }

        setPanelHeight(accountPanel?.current?.offsetHeight);
    }, []);

    function updateView(view: JoinViews) {
        setIsReturning(false);

        setCurrentPage(currentPage + 1);
        setActiveView(view);
    }

    function returnView(view: JoinViews) {
        setIsReturning(true);
        setCurrentPage(currentPage - 1);
        setActiveView(view);
    }

    let footer = (
        <>
            <span className={style.label}>{t('join.already_member')}</span>
            <Link to='/login'>{t('join.login')}</Link>
        </>
    );

    if (activeView === JoinViews.Account || activeView === JoinViews.VerificationReminder) {
        footer = <>&nbsp;</>;
    } else if (activeView !== JoinViews.Join) {
        footer = (
            <>
                <span className={style.label}>{t('join.setup_profile_later')}</span>
                <Link to='/explore'>{t('join.explore')}</Link>
            </>
        );
    }

    return (
        <section className='text-center'>
            <h1 className='mb1'>{t('join.register')}</h1>
            <Panel className={style.join}>
                <div className='text-center'>
                    <div className={style.join_icon} />
                    <div className={style.animation_container} style={{ height: panelHeight }} ref={animationContainer}>
                        <CSSTransition
                            in={activeView === JoinViews.Join}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={joinPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelJoin
                                onCompletion={() => updateView(JoinViews.Account)}
                                onMail={setMail}
                                onPassword={setPassword}
                                ref={joinPanel}
                            />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Account}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={accountPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelAccount
                                onCompletion={() => updateView(JoinViews.Collaborations)}
                                onLogin={onLogin}
                                mail={mail}
                                password={password}
                                ref={accountPanel}
                            />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Collaborations}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={collaborationPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelCollaborations onCompletion={() => updateView(JoinViews.Audience)} ref={collaborationPanel} />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Audience}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={audiencePanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelAudience
                                onReturn={() => returnView(JoinViews.Collaborations)}
                                onCompletion={() => updateView(JoinViews.Keywords)}
                                ref={audiencePanel}
                            />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Keywords}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={keywordsPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelKeywords onReturn={() => returnView(JoinViews.Audience)} onCompletion={() => updateView(JoinViews.Location)} ref={keywordsPanel} />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Location}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={locationPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelLocation onReturn={() => returnView(JoinViews.Keywords)} onCompletion={() => updateView(JoinViews.Description)} ref={locationPanel} />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Description}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={descriptionPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelDescription onReturn={() => returnView(JoinViews.Location)} onCompletion={() => updateView(JoinViews.Networks)} ref={descriptionPanel} />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Networks}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={networksPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelNetworks onReturn={() => returnView(JoinViews.Description)} onCompletion={() => updateView(JoinViews.Avatar)} ref={networksPanel} />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.Avatar}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={avatarPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelAvatar onReturn={() => returnView(JoinViews.Networks)} onCompletion={() => updateView(JoinViews.VerificationReminder)} ref={avatarPanel} />
                        </CSSTransition>

                        <CSSTransition
                            in={activeView === JoinViews.VerificationReminder}
                            timeout={animationDuration}
                            classNames={isReturning ? {
                                enter: animation.enterReverse,
                                enterActive: animation.enterActiveReverse,
                                exit: animation.exitReverse,
                                exitActive: animation.exitActiveReverse
                            } : {
                                enter: animation.enter,
                                enterActive: animation.enterActive,
                                exit: animation.exit,
                                exitActive: animation.exitActive
                            }}
                            nodeRef={verificationReminderPanel}
                            onEnter={() => {}}
                            unmountOnExit
                        >
                            <PanelVerificationReminder onCompletion={() => updateView(JoinViews.Join)} ref={verificationReminderPanel} />
                        </CSSTransition>
                    </div>

                    <div className={`${style.login} mb1`}>
                        {footer}
                    </div>
                    <div>
                        <Pagination pages={9} complete={currentPage} />
                    </div>
                </div>
            </Panel>
            <GoogleLegalPrint />
        </section>
    );
}
