import React, { forwardRef } from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputLabel from '../../../../theme/component/input-label/input-label.component'
import useQuery from "../../../../theme/hooks/use-query.component";
import joinStyle from "../../join.module.scss";
import { useTranslation } from 'react-i18next'
import Avatar from "../../../profile/component/profile-header/component/avatar/avatar.component";
import style from "./index.module.scss"


function PanelAvatar({ onCompletion, onReturn }: { onCompletion: () => void, onReturn: () => void }, ref: any) {
    const { t } = useTranslation()
    const joinToken = useQuery('token')

    async function submit() {
        onCompletion()
    }

    const buttonLabel = joinToken !== undefined ? t('join.finish') : t('join.next')

    return (
        <div ref={ref}>
            <span className={joinStyle.back_button} onClick={onReturn}/>
            <InputLabel label={t('join.avatar_header')} />
            <p className={`${joinStyle.info} mb2`}>{t('join.avatar_cta')}</p>

            <div className='text-center mb2'>
                <Avatar className={style.avatar} editingMode={true} avatarUrl={''}/>
            </div>

            <div className='mb2'>
                <InputButton label={buttonLabel} fill={true} onClick={submit} />
            </div>
        </div>
    )
}

export default forwardRef(PanelAvatar)
